// Table of Content
// ===============================================
// # Welcome
// # Variables
// # Base
// # Critical
// # Fonts
// # Layouts
// # Plugins
// # Vendors
//
// # Welcome
//   This file is used to centralize the imports
// =============================================== */
//
// Most of the following guidelines used to write css:
// http://cssguidelin.es
//
// # Variables
//   Globally-available variables and configs
// =============================================== */

@import "variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/bootstrap";

//
// # Base
// Resets, grids, wrappers, body, typography and forms
// =============================================== */

@import "base/reset";
@import "base/base";
@import "base/button";

//
// # Critical
//   The critical components to load inline
// =============================================== */

@import "~components/header/header";
@import "~components/content/content";
@import "~components/eyecatcher/eyecatcher";
@import "~components/footer/footer";
@import "~components/main/main";
@import "~components/card/card";
@import "~components/gallery/gallery";
@import "~components/assortiment-mini-filter/assortiment-mini-filter";

//
// # Fonts
//   Load custom fonts
// =============================================== */

@import "~assets/fonts/**/_font.scss";
@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@100;300;400;500;700;800;900&display=swap");
@import "~assets/fonts/font-awesome/scss/fontawesome.scss";
@import "~assets/fonts/font-awesome/scss/solid.scss";
@import "~assets/fonts/font-awesome/scss/regular.scss";
@import "~assets/fonts/font-awesome/scss/light.scss";
@import "~assets/fonts/font-awesome/scss/brands.scss";

//
// # Layouts
//   The critical layouts to load inline
// =============================================== */
@import "~layouts/assortiment-details";

//
// # Plugins
//   Apply theme styling to plugins
// =============================================== */
@import "plugins/**/*.scss";

//
// # Vendors
//   Vendor includes
// =============================================== */

@import "~assets/styles/vendor/**/*.scss";

.navbar-nav .dropdown-menu {
	position: absolute !important;
	float: none;
}

.dropdown-menu {
	border: 0px solid rgba(0, 0, 0, 0.15) !important;
	background-color: unset !important;
	padding: 0px !important;
}

.card-columns .contentblock .masonry .page_block {
	margin-top: 50px !important;
}

@media only screen and (max-width: 1200px) {
	.header .menu {
		z-index: 999;
	}

	.navbar-nav .dropdown-menu {
		position: inherit !important;
	}

	.dropdown-menu {
		margin: 0px !important;
	}

	.dropdown-menu .nav-item {
		margin: 0px !important;
	}

	ul.dropdown-menu.show {
		margin-left: 20px !important;
		background: $primary !important;
	}
}

.header {
	margin-bottom: 0;
	box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
	&.sticky {
		// background-color: $primary;
		// transition: 0.45s ease-in;
		// box-shadow: -2px 8px 10px rgba(0, 0, 0, 0.45) !important;
		// ul.dropdown-menu.show {
		// 	background: $primary !important;
		// }
		.logo {
			transform: scale(0.8);
			transition: 0.35s ease-in;
		}
	}
}
.header .navbar {
	padding: 0 1rem;
}

.header .logo {
	// transform: translate(15%, 15%);
	display: block;
	width: 75px;
	transition: 0.35s ease-in;
	z-index: 1000;
}
@media (min-width: 992px) {
	.header .logo {
		margin-left: 9px;
		width: auto;
	}
}
.header .menu_toggle {
	position: relative;
	z-index: 1000;
	display: block;
	cursor: pointer;
	transform: translateY(-25%);
	color: $white !important;
}
@media (min-width: 1200px) {
	.header .menu_toggle {
		display: none;
	}
}

.header .menu {
	padding: 0 30px 35px;
	position: absolute;
	top: 65px;
	left: -15px;
	width: calc(100% + 30px);
	background-color: #fff;
	transition: all 0.3s ease-in-out;
	box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
}
@media (min-width: 1200px) {
	.header .menu {
		padding: 0;
		position: relative;
		top: unset;
		left: unset;
		display: flex;
		flex-basis: unset;
		flex-wrap: wrap;
		justify-content: flex-end;
		width: auto;
		background-color: transparent;
		box-shadow: none;
	}
}
.header .navbar-nav {
	padding-top: 19px;
	margin-left: 0;
}
@media (min-width: 1200px) {
	.header .navbar-nav {
		padding-top: 0;
		display: flex;
		flex-direction: unset;
		flex-wrap: wrap;
	}
}
.header .navbar-nav li {
	width: 100%;
	font-size: 1.125rem;
	font-weight: 700;
	list-style: none;
	a {
		border-bottom: 1px solid rgba(#fff, 0);
		transition: 0.35s ease-in;
		color: #fff !important;
		&.highlight {
			background-color: $orange;
			padding: 0.55rem;
			border-radius: 8px;
			text-transform: uppercase;
			font-weight: 600;
			border-bottom: none !important;
			&:hover {
				border-bottom: none !important;
				background-color: rgba($orange, 0.85);
			}
		}
		&:hover {
			border-bottom: 1px solid white;
			transition: 0.35s ease-in;
		}
		@include media-breakpoint-down(lg) {
			border-bottom: none !important;
			max-width: fit-content;
		}
	}
	&.first a {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 900;
		border-bottom: 0;
		color: rgba(#fff, 0) !important;
		&::before {
			position: absolute;
			content: "\f015";
			color: $white;
			font-family: $font-family-icon;
			font-weight: 400;
			z-index: 1000;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: 0.35s ease-in;
		}
		@include media-breakpoint-down(lg) {
			color: $white !important;
			&::before {
				display: none;
			}
		}
		&:hover {
			border-bottom: 0;
			&::before {
				font-weight: 900;
				transition: 0.35s ease-in;
			}
		}
	}
}
.header .navbar-nav li a:hover {
	color: #ff8f00;
}
.header .navbar-nav li.active a {
	color: #ff8f00;
}
.header .container-fluid {
	max-width: none;
}

.header .menu {
	padding: 0 30px 35px;
	position: absolute;
	top: 65px;
	left: -15px;
	width: calc(100% + 30px);
	background-color: #fff;
	transition: all 0.3s ease-in-out;
	box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
}
@media (min-width: 1200px) {
	.header .menu {
		padding: 0;
		position: relative;
		top: unset;
		left: unset;
		display: flex;
		flex-basis: unset;
		flex-wrap: wrap;
		justify-content: flex-end;
		width: auto;
		background-color: transparent;
		box-shadow: none;
	}
}
.header .navbar-nav {
	padding-top: 19px;
	margin-left: 0;
}
@media (min-width: 1200px) {
	.header .navbar-nav {
		padding-top: 0;
		display: flex;
		flex-direction: unset;
		flex-wrap: wrap;
	}
}
.header .navbar-nav li {
	width: 100%;
	font-size: 1.125rem;
	font-weight: 700;
	list-style: none;
}
@media (min-width: 768px) {
	.header .navbar-nav li {
		&:not(:last-child) {
			margin-right: 16px;
		}
		width: auto;
	}
}
@media (min-width: 1366px) {
	.header .navbar-nav li {
		&:not(:last-child) {
			margin-right: 26px;
		}
		width: auto;
	}
}
.header .navbar-nav li.active a,
.header .navbar-nav li a:hover {
	color: #ff8f00;
}

.lead-section {
	padding: 3vh 0 6vh 0;
	.container-holder {
		display: flex;
		justify-content: center;
	}
	.info {
		max-width: 800px;
		text-align: center !important;
		h6,
		h5,
		h4,
		h3,
		h2,
		h1 {
			color: $primary;
			font-weight: 600;
			font-size: 50px;
		}
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-family-secondary;
}

.btn {
	border-radius: 8px !important;
	// &.btn-primary {}
	// &.btn-secondary {}
	&.btn-primary-outline {
		background: none !important;
		border: 1px solid $white;
		&:hover {
			background: $primary !important;
			color: $white !important;
		}
	}
	&.btn-outline-secondary {
		background: none !important;
		color: $orange !important;
		border: 1px solid $orange;

		&:hover {
			background: $orange !important;
			color: $white !important;
			border: 1px solid $orange !important;
		}
	}
}
section {
	.container {
		@include media-breakpoint-down(lg) {
			padding-left: 0.75rem;
			padding-right: 0.75rem;
		}
	}
}
.content-section {
	background-color: rgba($primary, 1);
	margin: 0 !important;
	padding: 6vh 0;
	position: relative;
	&::after {
		content: "";
		width: 100%;
		height: 100%;
		background-image: url("/images/bannerBg.jpg");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: -25%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		opacity: 0.1;
	}
	.grid-items {
		display: flex;
		flex-flow: row wrap;
		gap: 1rem;
		justify-content: space-between;
		position: relative;
		z-index: 900;
		.item {
			// max-width: 30%;
			.card {
				height: 100%;

				.card-caption {
					color: $white !important;
				}
				.card-body {
					height: 100%;
					display: flex;
					justify-content: space-between;
					flex-flow: column;
					padding: 1rem;
				}
				.card-btn {
					@extend .btn, .btn-primary-outline;
				}
			}
			@include media-breakpoint-down(lg) {
				max-width: 100%;
			}
		}
	}
}

ul.dropdown-menu.show {
	padding: 1rem !important;
	@include media-breakpoint-up(xl) {
		background: $white !important;
	}
	.nav-item {
		&.first {
			a {
				// color: $white !important;
				justify-content: flex-start !important;
				&::before {
					display: none;
				}
			}
		}

		.nav-link {
			color: $white;
			// font-size: $font-size-base_sm;

			@include media-breakpoint-up(xl) {
				color: $primary !important;
			}

			&:hover {
				color: $orange !important;
				
				@include media-breakpoint-up(xl) {
					color: $orange !important;
				}
			}
		}
	}
	a.nav-link {
		border-bottom: 0;
	}
}
.navbar-toggler[aria-expanded="false"] {
transform: none !important;
	&::before {
		content: "\f0c9";
		font-family: $font-family-icon;
		font-size: 26px;

		color: #fff !important;
	}
}
.navbar-toggler[aria-expanded="true"] {

transform: rotate(180deg);
transition: .35s;
	&::before {
		content: "\f00d";
			font-family: $font-family-icon;
			font-size: 26px;
			color: #fff !important;
	}
}
.navbar-toggler-icon {
	display: none !important;
	&::before {
		display: none !important;
	}
	&::after {
		display: none !important;
	}
}

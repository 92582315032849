// mixins
// --------------------------

// base rendering for an icon
@mixin fa-icon {
	display: inline-block;
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// sets relative font-sizing and alignment (in _sizing)
@mixin fa-size ($font-size) {
	font-size: ($font-size / $fa-size-scale-base) * 1em; // converts step in sizing scale into an em-based value that's relative to the scale's base
	line-height: (1 / $font-size) * 1em; // sets the line-height of the icon back to that of it's parent
	vertical-align: ((6 / $font-size) - (3 / 8)) * 1em; // vertically centers the icon taking into account the surrounding text's descender
}

// only display content to screen readers
// see: https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/
// see: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/
@mixin fa-sr-only() {
	margin: -1px;
	padding: 0;
	position: absolute;
	width: 1px;
	height: 1px;
	white-space: nowrap;
	border-width: 0;
	clip: rect(0, 0, 0, 0);
	overflow: hidden;
}

// use in conjunction with .sr-only to only display content when it's focused
@mixin fa-sr-only-focusable() {
	&:not(:focus) {
		@include fa-sr-only();
	}
}

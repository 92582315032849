// stacking icons
// -------------------------

.#{$fa-css-prefix}-stack {
	position: relative;
	display: inline-block;
	width: $fa-stack-width;
	height: 2em;
	line-height: 2em;
	vertical-align: $fa-stack-vertical-align;
}

.#{$fa-css-prefix}-stack-1x,
.#{$fa-css-prefix}-stack-2x {
	position: absolute;
	left: 0;
	z-index: var(--#{$fa-css-prefix}-stack-z-index, #{$fa-stack-z-index});
	width: 100%;
	text-align: center;
}

.#{$fa-css-prefix}-stack-1x {
	line-height: inherit;
}

.#{$fa-css-prefix}-stack-2x {
	font-size: 2em;
}

.#{$fa-css-prefix}-inverse {
	color: var(--#{$fa-css-prefix}-inverse, #{$fa-inverse});
}

// variables
// --------------------------

$fa-css-prefix          : fa !default;
$fa-style               : 900 !default;
$fa-style-family        : 'Font Awesome 6 Pro' !default;

$fa-display             : inline-block !default;

$fa-fw-width            : (20em / 16);
$fa-inverse             : #fff !default;

$fa-border-color        : #eee !default;
$fa-border-padding      : 0.2em 0.25em 0.15em !default;
$fa-border-radius       : 0.1em !default;
$fa-border-style        : solid !default;
$fa-border-width        : 0.08em !default;

$fa-size-scale-2xs      : 10 !default;
$fa-size-scale-xs       : 12 !default;
$fa-size-scale-sm       : 14 !default;
$fa-size-scale-base     : 16 !default;
$fa-size-scale-lg       : 20 !default;
$fa-size-scale-xl       : 24 !default;
$fa-size-scale-2xl      : 32 !default;

$fa-sizes: (
	'2xs'                 : $fa-size-scale-2xs,
	'xs'                  : $fa-size-scale-xs,
	'sm'                  : $fa-size-scale-sm,
	'lg'                  : $fa-size-scale-lg,
	'xl'                  : $fa-size-scale-xl,
	'2xl'                 : $fa-size-scale-2xl
) !default;

$fa-li-width            : 2em !default;
$fa-li-margin           : ($fa-li-width * 5/4) !default;

$fa-pull-margin         : 0.3em !default;

$fa-primary-opacity     : 1 !default;
$fa-secondary-opacity   : 0.4 !default;

$fa-stack-vertical-align: middle !default;
$fa-stack-width         : ($fa-fw-width * 2) !default;
$fa-stack-z-index       : auto !default;

$fa-font-display        : block !default;
$fa-font-path           : '#{$fonts-path}/font-awesome/webfonts' !default;

// convenience function used to set content property
@function fa-content($fa-var) {
	@return unquote('\"#{ $fa-var }\"');
}

$fa-var-0: \e089;
$fa-var-1: \e08a;
$fa-var-2: \e08b;
$fa-var-3: \e08c;
$fa-var-4: \e08d;
$fa-var-5: \e08e;
$fa-var-6: \e08f;
$fa-var-7: \e090;
$fa-var-8: \e091;
$fa-var-9: \e092;
$fa-var-a: \e093;
$fa-var-abacus: \f640;
$fa-var-acorn: \f6ae;
$fa-var-address-book: \f2b9;
$fa-var-contact-book: \f2b9;
$fa-var-address-card: \f2bb;
$fa-var-contact-card: \f2bb;
$fa-var-vcard: \f2bb;
$fa-var-air-conditioner: \f8f4;
$fa-var-air-freshener: \f5d0;
$fa-var-airplay: \e094;
$fa-var-alarm-clock: \f34e;
$fa-var-alarm-exclamation: \f843;
$fa-var-alarm-plus: \f844;
$fa-var-alarm-snooze: \f845;
$fa-var-album: \f89f;
$fa-var-album-collection: \f8a0;
$fa-var-alicorn: \f6b0;
$fa-var-alien: \f8f5;
$fa-var-alien-8bit: \f8f6;
$fa-var-alien-monster: \f8f6;
$fa-var-align-center: \f037;
$fa-var-align-justify: \f039;
$fa-var-align-left: \f036;
$fa-var-align-right: \f038;
$fa-var-align-slash: \f846;
$fa-var-alt: \e095;
$fa-var-amp-guitar: \f8a1;
$fa-var-ampersand: \e096;
$fa-var-anchor: \f13d;
$fa-var-angel: \f779;
$fa-var-angle: \e097;
$fa-var-angle-90: \e098;
$fa-var-angle-down: \f107;
$fa-var-angle-left: \f104;
$fa-var-angle-right: \f105;
$fa-var-angle-up: \f106;
$fa-var-angles-down: \f103;
$fa-var-angle-double-down: \f103;
$fa-var-angles-left: \f100;
$fa-var-angle-double-left: \f100;
$fa-var-angles-right: \f101;
$fa-var-angle-double-right: \f101;
$fa-var-angles-up: \f102;
$fa-var-angle-double-up: \f102;
$fa-var-ankh: \f644;
$fa-var-aperture: \e099;
$fa-var-apple-core: \e09a;
$fa-var-apple-crate: \f6b1;
$fa-var-apple-whole: \f5d1;
$fa-var-apple-alt: \f5d1;
$fa-var-archway: \f557;
$fa-var-arrow-down: \f063;
$fa-var-arrow-down-1-9: \f162;
$fa-var-sort-numeric-down: \f162;
$fa-var-sort-numeric-asc: \f162;
$fa-var-arrow-down-9-1: \f886;
$fa-var-sort-numeric-down-alt: \f886;
$fa-var-sort-numeric-desc: \f886;
$fa-var-arrow-down-a-z: \f15d;
$fa-var-sort-alpha-down: \f15d;
$fa-var-sort-alpha-asc: \f15d;
$fa-var-arrow-down-arrow-up: \f883;
$fa-var-sort-alt: \f883;
$fa-var-arrow-down-big-small: \f88c;
$fa-var-sort-size-down: \f88c;
$fa-var-arrow-down-from-dotted-line: \e09b;
$fa-var-arrow-down-from-line: \f345;
$fa-var-arrow-from-top: \f345;
$fa-var-arrow-down-left: \e09c;
$fa-var-arrow-down-left-and-arrow-up-right-to-center: \e09d;
$fa-var-arrow-down-long: \f175;
$fa-var-long-arrow-down: \f175;
$fa-var-arrow-down-right: \e09e;
$fa-var-arrow-down-short-wide: \f884;
$fa-var-sort-amount-down-alt: \f884;
$fa-var-sort-amount-desc: \f884;
$fa-var-arrow-down-small-big: \f88d;
$fa-var-sort-size-down-alt: \f88d;
$fa-var-arrow-down-square-triangle: \f889;
$fa-var-sort-shapes-down-alt: \f889;
$fa-var-arrow-down-to-bracket: \e09f;
$fa-var-arrow-down-to-dotted-line: \e0a0;
$fa-var-arrow-down-to-line: \f33d;
$fa-var-arrow-to-bottom: \f33d;
$fa-var-arrow-down-to-square: \e0a1;
$fa-var-arrow-down-triangle-square: \f888;
$fa-var-sort-shapes-down: \f888;
$fa-var-arrow-down-wide-short: \f160;
$fa-var-sort-amount-down: \f160;
$fa-var-sort-amount-asc: \f160;
$fa-var-arrow-down-z-a: \f881;
$fa-var-sort-alpha-down-alt: \f881;
$fa-var-sort-alpha-desc: \f881;
$fa-var-arrow-left: \f060;
$fa-var-arrow-left-from-line: \f344;
$fa-var-arrow-from-right: \f344;
$fa-var-arrow-left-long: \f177;
$fa-var-long-arrow-left: \f177;
$fa-var-arrow-left-rotate: \f0e2;
$fa-var-undo: \f0e2;
$fa-var-arrow-rotate-left: \f0e2;
$fa-var-arrow-left-to-line: \f33e;
$fa-var-arrow-to-left: \f33e;
$fa-var-arrow-pointer: \f245;
$fa-var-mouse-pointer: \f245;
$fa-var-arrow-right: \f061;
$fa-var-arrow-right-arrow-left: \f0ec;
$fa-var-exchange: \f0ec;
$fa-var-arrow-right-from-bracket: \f08b;
$fa-var-sign-out: \f08b;
$fa-var-arrow-right-from-line: \f343;
$fa-var-arrow-from-left: \f343;
$fa-var-arrow-right-long: \f178;
$fa-var-long-arrow-right: \f178;
$fa-var-arrow-right-to-bracket: \f090;
$fa-var-sign-in: \f090;
$fa-var-arrow-right-to-line: \f340;
$fa-var-arrow-to-right: \f340;
$fa-var-arrow-rotate-forward: \f01e;
$fa-var-redo: \f01e;
$fa-var-rotate-right: \f01e;
$fa-var-arrow-trend-down: \e0a2;
$fa-var-arrow-trend-up: \e0a3;
$fa-var-arrow-turn-down: \f149;
$fa-var-level-down: \f149;
$fa-var-arrow-turn-up: \f148;
$fa-var-level-up: \f148;
$fa-var-arrow-up: \f062;
$fa-var-arrow-up-1-9: \f163;
$fa-var-sort-numeric-up: \f163;
$fa-var-arrow-up-9-1: \f887;
$fa-var-sort-numeric-up-alt: \f887;
$fa-var-arrow-up-a-z: \f15e;
$fa-var-sort-alpha-up: \f15e;
$fa-var-arrow-up-arrow-down: \e0a4;
$fa-var-sort-up-down: \e0a4;
$fa-var-arrow-up-big-small: \f88e;
$fa-var-sort-size-up: \f88e;
$fa-var-arrow-up-from-bracket: \e0a5;
$fa-var-arrow-up-from-dotted-line: \e0a6;
$fa-var-arrow-up-from-line: \f342;
$fa-var-arrow-from-bottom: \f342;
$fa-var-arrow-up-from-square: \e0a7;
$fa-var-arrow-up-left: \e0a8;
$fa-var-arrow-up-left-from-circle: \e0a9;
$fa-var-arrow-up-long: \f176;
$fa-var-long-arrow-up: \f176;
$fa-var-arrow-up-right: \e0aa;
$fa-var-arrow-up-right-and-arrow-down-left-from-center: \e0ab;
$fa-var-arrow-up-right-from-square: \f08e;
$fa-var-external-link: \f08e;
$fa-var-arrow-up-short-wide: \f885;
$fa-var-sort-amount-up-alt: \f885;
$fa-var-arrow-up-small-big: \f88f;
$fa-var-sort-size-up-alt: \f88f;
$fa-var-arrow-up-square-triangle: \f88b;
$fa-var-sort-shapes-up-alt: \f88b;
$fa-var-arrow-up-to-dotted-line: \e0ac;
$fa-var-arrow-up-to-line: \f341;
$fa-var-arrow-to-top: \f341;
$fa-var-arrow-up-triangle-square: \f88a;
$fa-var-sort-shapes-up: \f88a;
$fa-var-arrow-up-wide-short: \f161;
$fa-var-sort-amount-up: \f161;
$fa-var-arrow-up-z-a: \f882;
$fa-var-sort-alpha-up-alt: \f882;
$fa-var-arrows-cross: \e0ad;
$fa-var-arrows-from-dotted-line: \e0ae;
$fa-var-arrows-from-line: \e0af;
$fa-var-arrows-left-right: \f07e;
$fa-var-arrows-h: \f07e;
$fa-var-arrows-maximize: \f31d;
$fa-var-expand-arrows: \f31d;
$fa-var-arrows-minimize: \e0b0;
$fa-var-compress-arrows: \e0b0;
$fa-var-arrows-repeat: \f364;
$fa-var-repeat-alt: \f364;
$fa-var-arrows-repeat-1: \f366;
$fa-var-repeat-1-alt: \f366;
$fa-var-arrows-retweet: \f361;
$fa-var-retweet-alt: \f361;
$fa-var-arrows-rotate: \f021;
$fa-var-sync: \f021;
$fa-var-refresh: \f021;
$fa-var-arrows-to-dotted-line: \e0b1;
$fa-var-arrows-to-line: \e0b2;
$fa-var-arrows-up-down: \f07d;
$fa-var-arrows-v: \f07d;
$fa-var-arrows-up-down-left-right: \f047;
$fa-var-arrows: \f047;
$fa-var-asterisk: \f069;
$fa-var-at: \f1fa;
$fa-var-atom: \f5d2;
$fa-var-atom-simple: \f5d3;
$fa-var-atom-alt: \f5d3;
$fa-var-audio-description: \f29e;
$fa-var-audio-description-slash: \e0b3;
$fa-var-austral-sign: \e0b4;
$fa-var-avocado: \e0b5;
$fa-var-award: \f559;
$fa-var-award-simple: \e0b6;
$fa-var-axe: \f6b2;
$fa-var-axe-battle: \f6b3;
$fa-var-b: \e0b7;
$fa-var-baby: \f77c;
$fa-var-baby-carriage: \f77d;
$fa-var-carriage-baby: \f77d;
$fa-var-backpack: \f5d4;
$fa-var-backward: \f04a;
$fa-var-backward-fast: \f049;
$fa-var-fast-backward: \f049;
$fa-var-backward-step: \f048;
$fa-var-step-backward: \f048;
$fa-var-bacon: \f7e5;
$fa-var-bacteria: \e059;
$fa-var-bacterium: \e05a;
$fa-var-badge: \f335;
$fa-var-badge-check: \f336;
$fa-var-badge-dollar: \f645;
$fa-var-badge-percent: \f646;
$fa-var-badge-sheriff: \f8a2;
$fa-var-badger-honey: \f6b4;
$fa-var-bag-shopping: \f290;
$fa-var-shopping-bag: \f290;
$fa-var-bags-shopping: \f847;
$fa-var-bahai: \f666;
$fa-var-baht-sign: \e0b8;
$fa-var-ball-pile: \f77e;
$fa-var-ballot: \f732;
$fa-var-ballot-check: \f733;
$fa-var-ban: \f05e;
$fa-var-cancel: \f05e;
$fa-var-ban-bug: \f7f9;
$fa-var-debug: \f7f9;
$fa-var-ban-parking: \f616;
$fa-var-parking-circle-slash: \f616;
$fa-var-ban-smoking: \f54d;
$fa-var-smoking-ban: \f54d;
$fa-var-bandage: \f462;
$fa-var-band-aid: \f462;
$fa-var-banjo: \f8a3;
$fa-var-bank: \f19c;
$fa-var-university: \f19c;
$fa-var-institution: \f19c;
$fa-var-barcode: \f02a;
$fa-var-barcode-read: \f464;
$fa-var-barcode-scan: \f465;
$fa-var-bars: \f0c9;
$fa-var-navicon: \f0c9;
$fa-var-bars-filter: \e0b9;
$fa-var-bars-progress: \f828;
$fa-var-tasks-alt: \f828;
$fa-var-bars-sort: \e0ba;
$fa-var-bars-staggered: \f550;
$fa-var-stream: \f550;
$fa-var-reorder: \f550;
$fa-var-baseball-ball: \f433;
$fa-var-baseball-bat-ball: \f432;
$fa-var-baseball: \f432;
$fa-var-basket-shopping: \f291;
$fa-var-shopping-basket: \f291;
$fa-var-basket-shopping-simple: \e0bb;
$fa-var-shopping-basket-alt: \e0bb;
$fa-var-basketball-ball: \f434;
$fa-var-basketball-hoop: \f435;
$fa-var-bat: \f6b5;
$fa-var-bath: \f2cd;
$fa-var-bathtub: \f2cd;
$fa-var-battery-bolt: \f376;
$fa-var-battery-empty: \f244;
$fa-var-battery-0: \f244;
$fa-var-battery-exclamation: \e0bc;
$fa-var-battery-full: \f240;
$fa-var-battery: \f240;
$fa-var-battery-5: \f240;
$fa-var-battery-half: \f242;
$fa-var-battery-3: \f242;
$fa-var-battery-low: \e0bd;
$fa-var-battery-1: \e0bd;
$fa-var-battery-quarter: \f243;
$fa-var-battery-2: \f243;
$fa-var-battery-slash: \f377;
$fa-var-battery-three-quarters: \f241;
$fa-var-battery-4: \f241;
$fa-var-bed: \f236;
$fa-var-bed-bunk: \f8f8;
$fa-var-bed-empty: \f8f9;
$fa-var-bed-front: \f8f7;
$fa-var-bed-alt: \f8f7;
$fa-var-bed-pulse: \f487;
$fa-var-procedures: \f487;
$fa-var-bee: \e0be;
$fa-var-beer-mug: \e0bf;
$fa-var-beer-foam: \e0bf;
$fa-var-beer-mug-empty: \f0fc;
$fa-var-beer: \f0fc;
$fa-var-bell: \f0f3;
$fa-var-bell-concierge: \f562;
$fa-var-concierge-bell: \f562;
$fa-var-bell-exclamation: \f848;
$fa-var-bell-on: \f8fa;
$fa-var-bell-plus: \f849;
$fa-var-bell-school: \f5d5;
$fa-var-bell-school-slash: \f5d6;
$fa-var-bell-slash: \f1f6;
$fa-var-bells: \f77f;
$fa-var-bezier-curve: \f55b;
$fa-var-bicycle: \f206;
$fa-var-binoculars: \f1e5;
$fa-var-biohazard: \f780;
$fa-var-bitcoin-sign: \e0c0;
$fa-var-blanket: \f498;
$fa-var-blender: \f517;
$fa-var-blender-phone: \f6b6;
$fa-var-blinds: \f8fb;
$fa-var-blinds-open: \f8fc;
$fa-var-blinds-raised: \f8fd;
$fa-var-block-quote: \e0c1;
$fa-var-blog: \f781;
$fa-var-bold: \f032;
$fa-var-bolt: \f0e7;
$fa-var-flash: \f0e7;
$fa-var-bolt-auto: \e0c2;
$fa-var-bolt-lightning: \e0c3;
$fa-var-bolt-slash: \e0c4;
$fa-var-bomb: \f1e2;
$fa-var-bone: \f5d7;
$fa-var-bone-break: \f5d8;
$fa-var-bong: \f55c;
$fa-var-book: \f02d;
$fa-var-book-arrow-right: \e0c5;
$fa-var-book-arrow-up: \e0c6;
$fa-var-book-atlas: \f558;
$fa-var-atlas: \f558;
$fa-var-book-bible: \f647;
$fa-var-bible: \f647;
$fa-var-book-blank: \f5d9;
$fa-var-book-alt: \f5d9;
$fa-var-book-bookmark: \e0c7;
$fa-var-book-circle-arrow-right: \e0c8;
$fa-var-book-circle-arrow-up: \e0c9;
$fa-var-book-copy: \e0ca;
$fa-var-book-font: \e0cb;
$fa-var-book-heart: \f499;
$fa-var-book-journal-whills: \f66a;
$fa-var-journal-whills: \f66a;
$fa-var-book-law: \e0cc;
$fa-var-book-medical: \f7e6;
$fa-var-book-open: \f518;
$fa-var-book-open-cover: \e0cd;
$fa-var-book-open-alt: \e0cd;
$fa-var-book-open-reader: \f5da;
$fa-var-book-reader: \f5da;
$fa-var-book-quran: \f687;
$fa-var-quran: \f687;
$fa-var-book-section: \e0ce;
$fa-var-book-skull: \f6b7;
$fa-var-book-dead: \f6b7;
$fa-var-book-sparkles: \f6b8;
$fa-var-book-spells: \f6b8;
$fa-var-book-tanakh: \f827;
$fa-var-tanakh: \f827;
$fa-var-book-user: \f7e7;
$fa-var-bookmark: \f02e;
$fa-var-bookmark-slash: \e0cf;
$fa-var-books: \f5db;
$fa-var-books-medical: \f7e8;
$fa-var-boombox: \f8a5;
$fa-var-boot: \f782;
$fa-var-booth-curtain: \f734;
$fa-var-border-all: \f84c;
$fa-var-border-bottom: \f84d;
$fa-var-border-bottom-right: \f854;
$fa-var-border-style-alt: \f854;
$fa-var-border-center-h: \f89c;
$fa-var-border-center-v: \f89d;
$fa-var-border-inner: \f84e;
$fa-var-border-left: \f84f;
$fa-var-border-none: \f850;
$fa-var-border-outer: \f851;
$fa-var-border-right: \f852;
$fa-var-border-top: \f855;
$fa-var-border-top-left: \f853;
$fa-var-border-style: \f853;
$fa-var-bow-arrow: \f6b9;
$fa-var-bowl-hot: \f823;
$fa-var-soup: \f823;
$fa-var-bowling-ball: \f436;
$fa-var-bowling-ball-pin: \e0d0;
$fa-var-bowling-pins: \f437;
$fa-var-box: \f466;
$fa-var-box-archive: \f187;
$fa-var-archive: \f187;
$fa-var-box-ballot: \f735;
$fa-var-box-check: \f467;
$fa-var-box-circle-check: \e0d1;
$fa-var-box-dollar: \f4a0;
$fa-var-box-usd: \f4a0;
$fa-var-box-heart: \f49d;
$fa-var-box-open: \f49e;
$fa-var-box-open-full: \f49c;
$fa-var-box-full: \f49c;
$fa-var-box-taped: \f49a;
$fa-var-box-alt: \f49a;
$fa-var-box-tissue: \e05b;
$fa-var-boxes-stacked: \f468;
$fa-var-boxes: \f468;
$fa-var-boxes-alt: \f468;
$fa-var-boxing-glove: \f438;
$fa-var-glove-boxing: \f438;
$fa-var-bracket-curly: \e0d2;
$fa-var-bracket-curly-left: \e0d2;
$fa-var-bracket-curly-right: \e0d3;
$fa-var-bracket-round: \e0d4;
$fa-var-parenthesis: \e0d4;
$fa-var-bracket-round-right: \e0d5;
$fa-var-bracket-square: \e0d6;
$fa-var-bracket: \e0d6;
$fa-var-bracket-left: \e0d6;
$fa-var-bracket-square-right: \e0d7;
$fa-var-brackets-curly: \f7ea;
$fa-var-brackets-round: \e0d8;
$fa-var-parentheses: \e0d8;
$fa-var-brackets-square: \f7e9;
$fa-var-brackets: \f7e9;
$fa-var-braille: \f2a1;
$fa-var-brain: \f5dc;
$fa-var-brain-arrow-curved-right: \f677;
$fa-var-mind-share: \f677;
$fa-var-brain-circuit: \e0d9;
$fa-var-brake-warning: \e0da;
$fa-var-bread-loaf: \f7eb;
$fa-var-bread-slice: \f7ec;
$fa-var-briefcase: \f0b1;
$fa-var-briefcase-arrow-right: \e0db;
$fa-var-briefcase-clock: \f64a;
$fa-var-business-time: \f64a;
$fa-var-briefcase-medical: \f469;
$fa-var-bring-forward: \f856;
$fa-var-bring-front: \f857;
$fa-var-broom: \f51a;
$fa-var-browser: \f37e;
$fa-var-browsers: \e0dc;
$fa-var-brush: \f55d;
$fa-var-bug: \f188;
$fa-var-building: \f1ad;
$fa-var-buildings: \e0dd;
$fa-var-bullhorn: \f0a1;
$fa-var-bullseye: \f140;
$fa-var-bullseye-arrow: \f648;
$fa-var-bullseye-pointer: \f649;
$fa-var-burger: \f805;
$fa-var-hamburger: \f805;
$fa-var-burger-cheese: \f7f1;
$fa-var-cheeseburger: \f7f1;
$fa-var-burger-fries: \e0de;
$fa-var-burger-glass: \e0df;
$fa-var-burger-soda: \f858;
$fa-var-burrito: \f7ed;
$fa-var-bus: \f207;
$fa-var-bus-school: \f5dd;
$fa-var-bus-simple: \f55e;
$fa-var-bus-alt: \f55e;
$fa-var-c: \e0e0;
$fa-var-cabinet-filing: \f64b;
$fa-var-cable-car: \e0e1;
$fa-var-cactus: \f8a7;
$fa-var-cake-candles: \f1fd;
$fa-var-birthday-cake: \f1fd;
$fa-var-calculator: \f1ec;
$fa-var-calculator-simple: \f64c;
$fa-var-calculator-alt: \f64c;
$fa-var-calendar: \f133;
$fa-var-calendar-arrow-down: \e0e2;
$fa-var-calendar-download: \e0e2;
$fa-var-calendar-arrow-up: \e0e3;
$fa-var-calendar-upload: \e0e3;
$fa-var-calendar-check: \f274;
$fa-var-calendar-clock: \e0e4;
$fa-var-calendar-time: \e0e4;
$fa-var-calendar-day: \f783;
$fa-var-calendar-days: \f073;
$fa-var-calendar-alt: \f073;
$fa-var-calendar-exclamation: \f334;
$fa-var-calendar-heart: \e0e5;
$fa-var-calendar-image: \e0e6;
$fa-var-calendar-lines: \e0e7;
$fa-var-calendar-note: \e0e7;
$fa-var-calendar-minus: \f272;
$fa-var-calendar-pen: \f333;
$fa-var-calendar-edit: \f333;
$fa-var-calendar-plus: \f271;
$fa-var-calendar-range: \e0e8;
$fa-var-calendar-star: \f736;
$fa-var-calendar-week: \f784;
$fa-var-calendar-xmark: \f273;
$fa-var-calendar-times: \f273;
$fa-var-calendars: \e0e9;
$fa-var-camcorder: \f8a8;
$fa-var-video-handheld: \f8a8;
$fa-var-camera: \f030;
$fa-var-camera-alt: \f030;
$fa-var-camera-cctv: \f8ac;
$fa-var-cctv: \f8ac;
$fa-var-camera-movie: \f8a9;
$fa-var-camera-polaroid: \f8aa;
$fa-var-camera-retro: \f083;
$fa-var-camera-rotate: \e0ea;
$fa-var-camera-security: \f8fe;
$fa-var-camera-home: \f8fe;
$fa-var-camera-slash: \e0eb;
$fa-var-camera-viewfinder: \e0ec;
$fa-var-camera-web: \f832;
$fa-var-webcam: \f832;
$fa-var-camera-web-slash: \f833;
$fa-var-webcam-slash: \f833;
$fa-var-campfire: \f6ba;
$fa-var-campground: \f6bb;
$fa-var-candle-holder: \f6bc;
$fa-var-candy-cane: \f786;
$fa-var-candy-corn: \f6bd;
$fa-var-cannabis: \f55f;
$fa-var-capsules: \f46b;
$fa-var-car: \f1b9;
$fa-var-automobile: \f1b9;
$fa-var-car-battery: \f5df;
$fa-var-battery-car: \f5df;
$fa-var-car-building: \f859;
$fa-var-car-bump: \f5e0;
$fa-var-car-bus: \f85a;
$fa-var-car-crash: \f5e1;
$fa-var-car-garage: \f5e2;
$fa-var-car-rear: \f5de;
$fa-var-car-alt: \f5de;
$fa-var-car-side: \f5e4;
$fa-var-car-tilt: \f5e5;
$fa-var-car-wash: \f5e6;
$fa-var-car-wrench: \f5e3;
$fa-var-car-mechanic: \f5e3;
$fa-var-caravan: \f8ff;
$fa-var-caravan-simple: \e000;
$fa-var-caravan-alt: \e000;
$fa-var-caret-down: \f0d7;
$fa-var-caret-left: \f0d9;
$fa-var-caret-right: \f0da;
$fa-var-caret-up: \f0d8;
$fa-var-carrot: \f787;
$fa-var-cars: \f85b;
$fa-var-cart-arrow-down: \f218;
$fa-var-cart-flatbed: \f474;
$fa-var-dolly-flatbed: \f474;
$fa-var-cart-flatbed-boxes: \f475;
$fa-var-dolly-flatbed-alt: \f475;
$fa-var-cart-flatbed-empty: \f476;
$fa-var-dolly-flatbed-empty: \f476;
$fa-var-cart-flatbed-suitcase: \f59d;
$fa-var-luggage-cart: \f59d;
$fa-var-cart-plus: \f217;
$fa-var-cart-shopping: \f07a;
$fa-var-shopping-cart: \f07a;
$fa-var-cart-shopping-fast: \e0ed;
$fa-var-casette-betamax: \f8a4;
$fa-var-betamax: \f8a4;
$fa-var-casette-vhs: \f8ec;
$fa-var-vhs: \f8ec;
$fa-var-cash-register: \f788;
$fa-var-cassette-tape: \f8ab;
$fa-var-castle: \e0ee;
$fa-var-cat: \f6be;
$fa-var-cat-space: \e001;
$fa-var-cauldron: \f6bf;
$fa-var-cedi-sign: \e0ef;
$fa-var-cent-sign: \e0f0;
$fa-var-certificate: \f0a3;
$fa-var-chair: \f6c0;
$fa-var-chair-office: \f6c1;
$fa-var-chalkboard: \f51b;
$fa-var-blackboard: \f51b;
$fa-var-chalkboard-user: \f51c;
$fa-var-chalkboard-teacher: \f51c;
$fa-var-champagne-glass: \f79e;
$fa-var-glass-champagne: \f79e;
$fa-var-champagne-glasses: \f79f;
$fa-var-glass-cheers: \f79f;
$fa-var-charging-station: \f5e7;
$fa-var-chart-area: \f1fe;
$fa-var-area-chart: \f1fe;
$fa-var-chart-bar: \f080;
$fa-var-bar-chart: \f080;
$fa-var-chart-bullet: \e0f1;
$fa-var-chart-candlestick: \e0f2;
$fa-var-chart-column: \e0f3;
$fa-var-chart-gantt: \e0f4;
$fa-var-chart-line: \f201;
$fa-var-line-chart: \f201;
$fa-var-chart-line-down: \f64d;
$fa-var-chart-line-up: \e0f5;
$fa-var-chart-mixed: \f643;
$fa-var-analytics: \f643;
$fa-var-chart-network: \f78a;
$fa-var-chart-pie: \f200;
$fa-var-pie-chart: \f200;
$fa-var-chart-pie-simple: \f64e;
$fa-var-chart-pie-alt: \f64e;
$fa-var-chart-pyramid: \e0f6;
$fa-var-chart-radar: \e0f7;
$fa-var-chart-scatter: \f7ee;
$fa-var-chart-scatter-3d: \e0f8;
$fa-var-chart-scatter-bubble: \e0f9;
$fa-var-chart-tree-map: \e0fa;
$fa-var-chart-user: \f6a3;
$fa-var-user-chart: \f6a3;
$fa-var-chart-waterfall: \e0fb;
$fa-var-check: \f00c;
$fa-var-check-double: \f560;
$fa-var-check-to-slot: \f772;
$fa-var-vote-yea: \f772;
$fa-var-cheese: \f7ef;
$fa-var-cheese-swiss: \f7f0;
$fa-var-cherries: \e0fc;
$fa-var-chess: \f439;
$fa-var-chess-bishop: \f43a;
$fa-var-chess-bishop-piece: \f43b;
$fa-var-chess-bishop-alt: \f43b;
$fa-var-chess-board: \f43c;
$fa-var-chess-clock: \f43d;
$fa-var-chess-clock-flip: \f43e;
$fa-var-chess-clock-alt: \f43e;
$fa-var-chess-king: \f43f;
$fa-var-chess-king-piece: \f440;
$fa-var-chess-king-alt: \f440;
$fa-var-chess-knight: \f441;
$fa-var-chess-knight-piece: \f442;
$fa-var-chess-knight-alt: \f442;
$fa-var-chess-pawn: \f443;
$fa-var-chess-pawn-piece: \f444;
$fa-var-chess-pawn-alt: \f444;
$fa-var-chess-queen: \f445;
$fa-var-chess-queen-piece: \f446;
$fa-var-chess-queen-alt: \f446;
$fa-var-chess-rook: \f447;
$fa-var-chess-rook-piece: \f448;
$fa-var-chess-rook-alt: \f448;
$fa-var-chevron-down: \f078;
$fa-var-chevron-left: \f053;
$fa-var-chevron-right: \f054;
$fa-var-chevron-up: \f077;
$fa-var-chevrons-down: \f322;
$fa-var-chevron-double-down: \f322;
$fa-var-chevrons-left: \f323;
$fa-var-chevron-double-left: \f323;
$fa-var-chevrons-right: \f324;
$fa-var-chevron-double-right: \f324;
$fa-var-chevrons-up: \f325;
$fa-var-chevron-double-up: \f325;
$fa-var-child: \f1ae;
$fa-var-chimney: \f78b;
$fa-var-church: \f51d;
$fa-var-circle: \f111;
$fa-var-circle-0: \e0fd;
$fa-var-circle-1: \e0fe;
$fa-var-circle-2: \e0ff;
$fa-var-circle-3: \e100;
$fa-var-circle-4: \e101;
$fa-var-circle-5: \e102;
$fa-var-circle-6: \e103;
$fa-var-circle-7: \e104;
$fa-var-circle-8: \e105;
$fa-var-circle-9: \e106;
$fa-var-circle-a: \e107;
$fa-var-circle-ampersand: \e108;
$fa-var-circle-arrow-down: \f0ab;
$fa-var-arrow-circle-down: \f0ab;
$fa-var-circle-arrow-down-left: \e109;
$fa-var-circle-arrow-down-right: \e10a;
$fa-var-circle-arrow-left: \f0a8;
$fa-var-arrow-circle-left: \f0a8;
$fa-var-circle-arrow-right: \f0a9;
$fa-var-arrow-circle-right: \f0a9;
$fa-var-circle-arrow-up: \f0aa;
$fa-var-arrow-circle-up: \f0aa;
$fa-var-circle-arrow-up-left: \e10b;
$fa-var-circle-arrow-up-right: \e10c;
$fa-var-circle-b: \e10d;
$fa-var-circle-bolt: \e10e;
$fa-var-circle-book-open: \e10f;
$fa-var-book-circle: \e10f;
$fa-var-circle-bookmark: \e110;
$fa-var-bookmark-circle: \e110;
$fa-var-circle-c: \e111;
$fa-var-circle-calendar: \e112;
$fa-var-calendar-circle: \e112;
$fa-var-circle-camera: \e113;
$fa-var-camera-circle: \e113;
$fa-var-circle-caret-down: \f32d;
$fa-var-caret-circle-down: \f32d;
$fa-var-circle-caret-left: \f32e;
$fa-var-caret-circle-left: \f32e;
$fa-var-circle-caret-right: \f330;
$fa-var-caret-circle-right: \f330;
$fa-var-circle-caret-up: \f331;
$fa-var-caret-circle-up: \f331;
$fa-var-circle-check: \f058;
$fa-var-check-circle: \f058;
$fa-var-circle-chevron-down: \f13a;
$fa-var-chevron-circle-down: \f13a;
$fa-var-circle-chevron-left: \f137;
$fa-var-chevron-circle-left: \f137;
$fa-var-circle-chevron-right: \f138;
$fa-var-chevron-circle-right: \f138;
$fa-var-circle-chevron-up: \f139;
$fa-var-chevron-circle-up: \f139;
$fa-var-circle-d: \e114;
$fa-var-circle-dashed: \e115;
$fa-var-circle-divide: \e116;
$fa-var-circle-dollar: \f2e8;
$fa-var-usd-circle: \f2e8;
$fa-var-dollar-circle: \f2e8;
$fa-var-circle-dollar-to-slot: \f4b9;
$fa-var-donate: \f4b9;
$fa-var-circle-dot: \f192;
$fa-var-dot-circle: \f192;
$fa-var-circle-down: \f358;
$fa-var-arrow-alt-circle-down: \f358;
$fa-var-circle-down-left: \e117;
$fa-var-circle-down-right: \e118;
$fa-var-circle-e: \e119;
$fa-var-circle-envelope: \e11a;
$fa-var-envelope-circle: \e11a;
$fa-var-circle-exclamation: \f06a;
$fa-var-exclamation-circle: \f06a;
$fa-var-circle-exclamation-check: \e11b;
$fa-var-circle-f: \e11c;
$fa-var-circle-g: \e11d;
$fa-var-circle-h: \f47e;
$fa-var-hospital-symbol: \f47e;
$fa-var-circle-half: \e11e;
$fa-var-circle-half-stroke: \f042;
$fa-var-adjust: \f042;
$fa-var-circle-heart: \f4c7;
$fa-var-heart-circle: \f4c7;
$fa-var-circle-i: \e11f;
$fa-var-circle-info: \f05a;
$fa-var-info-circle: \f05a;
$fa-var-circle-j: \e120;
$fa-var-circle-k: \e121;
$fa-var-circle-l: \e122;
$fa-var-circle-left: \f359;
$fa-var-arrow-alt-circle-left: \f359;
$fa-var-circle-location-arrow: \f602;
$fa-var-location-circle: \f602;
$fa-var-circle-m: \e123;
$fa-var-circle-microphone: \e124;
$fa-var-microphone-circle: \e124;
$fa-var-circle-microphone-lines: \e125;
$fa-var-microphone-circle-alt: \e125;
$fa-var-circle-minus: \f056;
$fa-var-minus-circle: \f056;
$fa-var-circle-n: \e126;
$fa-var-circle-notch: \f1ce;
$fa-var-circle-o: \e127;
$fa-var-circle-p: \e128;
$fa-var-circle-parking: \f615;
$fa-var-parking-circle: \f615;
$fa-var-circle-pause: \f28b;
$fa-var-pause-circle: \f28b;
$fa-var-circle-phone: \e129;
$fa-var-phone-circle: \e129;
$fa-var-circle-phone-flip: \e12a;
$fa-var-phone-circle-alt: \e12a;
$fa-var-circle-phone-hangup: \e12b;
$fa-var-phone-circle-down: \e12b;
$fa-var-circle-play: \f144;
$fa-var-play-circle: \f144;
$fa-var-circle-plus: \f055;
$fa-var-plus-circle: \f055;
$fa-var-circle-q: \e12c;
$fa-var-circle-quarter: \e12d;
$fa-var-circle-question: \f059;
$fa-var-question-circle: \f059;
$fa-var-circle-r: \e12e;
$fa-var-circle-radiation: \f7ba;
$fa-var-radiation-alt: \f7ba;
$fa-var-circle-right: \f35a;
$fa-var-arrow-alt-circle-right: \f35a;
$fa-var-circle-s: \e12f;
$fa-var-circle-small: \e130;
$fa-var-circle-sort: \e030;
$fa-var-sort-circle: \e030;
$fa-var-circle-sort-down: \e031;
$fa-var-sort-circle-down: \e031;
$fa-var-circle-sort-up: \e032;
$fa-var-sort-circle-up: \e032;
$fa-var-circle-star: \e131;
$fa-var-star-circle: \e131;
$fa-var-circle-stop: \f28d;
$fa-var-stop-circle: \f28d;
$fa-var-circle-t: \e132;
$fa-var-circle-three-quarters: \e133;
$fa-var-circle-trash: \e134;
$fa-var-trash-circle: \e134;
$fa-var-circle-u: \e135;
$fa-var-circle-up: \f35b;
$fa-var-arrow-alt-circle-up: \f35b;
$fa-var-circle-up-left: \e136;
$fa-var-circle-up-right: \e137;
$fa-var-circle-user: \f2bd;
$fa-var-user-circle: \f2bd;
$fa-var-circle-v: \e138;
$fa-var-circle-video: \e139;
$fa-var-video-circle: \e139;
$fa-var-circle-w: \e13a;
$fa-var-circle-waveform-lines: \e13b;
$fa-var-waveform-circle: \e13b;
$fa-var-circle-x: \e13c;
$fa-var-circle-xmark: \f057;
$fa-var-times-circle: \f057;
$fa-var-xmark-circle: \f057;
$fa-var-circle-y: \e13d;
$fa-var-circle-z: \e13e;
$fa-var-city: \f64f;
$fa-var-clapperboard: \e13f;
$fa-var-clapperboard-play: \e140;
$fa-var-clarinet: \f8ad;
$fa-var-claw-marks: \f6c2;
$fa-var-clipboard: \f328;
$fa-var-clipboard-check: \f46c;
$fa-var-clipboard-list: \f46d;
$fa-var-clipboard-list-check: \f737;
$fa-var-clipboard-medical: \e141;
$fa-var-clipboard-prescription: \f5e8;
$fa-var-clipboard-user: \f7f3;
$fa-var-clock: \f017;
$fa-var-clock-desk: \e142;
$fa-var-clock-rotate-left: \f1da;
$fa-var-history: \f1da;
$fa-var-clone: \f24d;
$fa-var-closed-captioning: \f20a;
$fa-var-closed-captioning-slash: \e143;
$fa-var-clothes-hanger: \e144;
$fa-var-cloud: \f0c2;
$fa-var-cloud-arrow-down: \f0ed;
$fa-var-cloud-download: \f0ed;
$fa-var-cloud-download-alt: \f0ed;
$fa-var-cloud-arrow-up: \f0ee;
$fa-var-cloud-upload: \f0ee;
$fa-var-cloud-upload-alt: \f0ee;
$fa-var-cloud-bolt: \f76c;
$fa-var-thunderstorm: \f76c;
$fa-var-cloud-bolt-moon: \f76d;
$fa-var-thunderstorm-moon: \f76d;
$fa-var-cloud-bolt-sun: \f76e;
$fa-var-thunderstorm-sun: \f76e;
$fa-var-cloud-drizzle: \f738;
$fa-var-cloud-fog: \f74e;
$fa-var-fog: \f74e;
$fa-var-cloud-hail: \f739;
$fa-var-cloud-hail-mixed: \f73a;
$fa-var-cloud-meatball: \f73b;
$fa-var-cloud-moon: \f6c3;
$fa-var-cloud-moon-rain: \f73c;
$fa-var-cloud-music: \f8ae;
$fa-var-cloud-rain: \f73d;
$fa-var-cloud-rainbow: \f73e;
$fa-var-cloud-showers: \f73f;
$fa-var-cloud-showers-heavy: \f740;
$fa-var-cloud-slash: \e145;
$fa-var-cloud-sleet: \f741;
$fa-var-cloud-snow: \f742;
$fa-var-cloud-sun: \f6c4;
$fa-var-cloud-sun-rain: \f743;
$fa-var-cloud-word: \e146;
$fa-var-clouds: \f744;
$fa-var-clouds-moon: \f745;
$fa-var-clouds-sun: \f746;
$fa-var-clover: \e147;
$fa-var-club: \f327;
$fa-var-code: \f121;
$fa-var-code-branch: \f126;
$fa-var-code-commit: \f386;
$fa-var-code-compare: \e148;
$fa-var-code-fork: \e149;
$fa-var-code-merge: \f387;
$fa-var-code-pull-request: \e14a;
$fa-var-code-simple: \e14b;
$fa-var-coffee-bean: \e14c;
$fa-var-coffee-beans: \e14d;
$fa-var-coffee-pot: \e002;
$fa-var-coffin: \f6c6;
$fa-var-coffin-cross: \e051;
$fa-var-coin: \f85c;
$fa-var-coins: \f51e;
$fa-var-colon-sign: \e14e;
$fa-var-comet: \e003;
$fa-var-command: \e14f;
$fa-var-comment: \f075;
$fa-var-comment-arrow-down: \e150;
$fa-var-comment-arrow-up: \e151;
$fa-var-comment-arrow-up-right: \e152;
$fa-var-comment-captions: \e153;
$fa-var-comment-check: \f4ac;
$fa-var-comment-code: \e154;
$fa-var-comment-dollar: \f651;
$fa-var-comment-dots: \f4ad;
$fa-var-commenting: \f4ad;
$fa-var-comment-exclamation: \f4af;
$fa-var-comment-image: \e155;
$fa-var-comment-lines: \f4b0;
$fa-var-comment-medical: \f7f5;
$fa-var-comment-middle: \e156;
$fa-var-comment-middle-top: \e157;
$fa-var-comment-minus: \f4b1;
$fa-var-comment-music: \f8b0;
$fa-var-comment-pen: \f4ae;
$fa-var-comment-edit: \f4ae;
$fa-var-comment-plus: \f4b2;
$fa-var-comment-question: \e158;
$fa-var-comment-quote: \e159;
$fa-var-comment-slash: \f4b3;
$fa-var-comment-smile: \f4b4;
$fa-var-comment-sms: \f7cd;
$fa-var-sms: \f7cd;
$fa-var-comment-text: \e15a;
$fa-var-comment-xmark: \f4b5;
$fa-var-comment-times: \f4b5;
$fa-var-comments: \f086;
$fa-var-comments-dollar: \f653;
$fa-var-comments-question: \e15b;
$fa-var-comments-question-check: \e15c;
$fa-var-compact-disc: \f51f;
$fa-var-compass: \f14e;
$fa-var-compass-drafting: \f568;
$fa-var-drafting-compass: \f568;
$fa-var-compass-slash: \f5e9;
$fa-var-compress: \f066;
$fa-var-compress-wide: \f326;
$fa-var-computer-classic: \f8b1;
$fa-var-computer-mouse: \f8cc;
$fa-var-mouse: \f8cc;
$fa-var-computer-mouse-scrollwheel: \f8cd;
$fa-var-mouse-alt: \f8cd;
$fa-var-computer-speaker: \f8b2;
$fa-var-container-storage: \f4b7;
$fa-var-conveyor-belt: \f46e;
$fa-var-conveyor-belt-boxes: \f46f;
$fa-var-conveyor-belt-alt: \f46f;
$fa-var-conveyor-belt-empty: \e15d;
$fa-var-cookie: \f563;
$fa-var-cookie-bite: \f564;
$fa-var-copy: \f0c5;
$fa-var-copyright: \f1f9;
$fa-var-corn: \f6c7;
$fa-var-couch: \f4b8;
$fa-var-cow: \f6c8;
$fa-var-cowbell: \f8b3;
$fa-var-cowbell-circle-plus: \f8b4;
$fa-var-cowbell-more: \f8b4;
$fa-var-credit-card: \f09d;
$fa-var-credit-card-alt: \f09d;
$fa-var-credit-card-blank: \f389;
$fa-var-credit-card-front: \f38a;
$fa-var-cricket-bat-ball: \f449;
$fa-var-cricket: \f449;
$fa-var-croissant: \f7f6;
$fa-var-crop: \f125;
$fa-var-crop-simple: \f565;
$fa-var-crop-alt: \f565;
$fa-var-cross: \f654;
$fa-var-crosshairs: \f05b;
$fa-var-crow: \f520;
$fa-var-crown: \f521;
$fa-var-crutch: \f7f7;
$fa-var-crutches: \f7f8;
$fa-var-cruzeiro-sign: \e15e;
$fa-var-cube: \f1b2;
$fa-var-cubes: \f1b3;
$fa-var-cup-togo: \f6c5;
$fa-var-coffee-togo: \f6c5;
$fa-var-curling-stone: \f44a;
$fa-var-curling: \f44a;
$fa-var-d: \e15f;
$fa-var-dagger: \f6cb;
$fa-var-database: \f1c0;
$fa-var-deer: \f78e;
$fa-var-deer-rudolph: \f78f;
$fa-var-delete-left: \f55a;
$fa-var-backspace: \f55a;
$fa-var-delete-right: \e160;
$fa-var-democrat: \f747;
$fa-var-desktop: \f108;
$fa-var-desktop-alt: \f108;
$fa-var-desktop-arrow-down: \e161;
$fa-var-dharmachakra: \f655;
$fa-var-diagram-lean-canvas: \e162;
$fa-var-diagram-nested: \e163;
$fa-var-diagram-project: \f542;
$fa-var-project-diagram: \f542;
$fa-var-diagram-sankey: \e164;
$fa-var-diagram-stankey: \e165;
$fa-var-diagram-venn: \e166;
$fa-var-dial: \e167;
$fa-var-dial-med-high: \e167;
$fa-var-dial-high: \e168;
$fa-var-dial-low: \e169;
$fa-var-dial-max: \e16a;
$fa-var-dial-med: \e16b;
$fa-var-dial-med-low: \e16c;
$fa-var-dial-min: \e16d;
$fa-var-diamond: \f219;
$fa-var-diamond-turn-right: \f5eb;
$fa-var-directions: \f5eb;
$fa-var-dice: \f522;
$fa-var-dice-d10: \f6cd;
$fa-var-dice-d12: \f6ce;
$fa-var-dice-d20: \f6cf;
$fa-var-dice-d4: \f6d0;
$fa-var-dice-d6: \f6d1;
$fa-var-dice-d8: \f6d2;
$fa-var-dice-five: \f523;
$fa-var-dice-four: \f524;
$fa-var-dice-one: \f525;
$fa-var-dice-six: \f526;
$fa-var-dice-three: \f527;
$fa-var-dice-two: \f528;
$fa-var-diploma: \f5ea;
$fa-var-scroll-ribbon: \f5ea;
$fa-var-disc-drive: \f8b5;
$fa-var-disease: \f7fa;
$fa-var-display: \e16e;
$fa-var-display-arrow-down: \e16f;
$fa-var-display-code: \e170;
$fa-var-desktop-code: \e170;
$fa-var-display-medical: \e171;
$fa-var-desktop-medical: \e171;
$fa-var-display-slash: \e172;
$fa-var-desktop-slash: \e172;
$fa-var-divide: \f529;
$fa-var-dna: \f471;
$fa-var-do-not-enter: \f5ec;
$fa-var-dog: \f6d3;
$fa-var-dog-leashed: \f6d4;
$fa-var-dollar-sign: \f155;
$fa-var-dollar: \f155;
$fa-var-usd: \f155;
$fa-var-dolly: \f472;
$fa-var-dolly-box: \f472;
$fa-var-dolly-empty: \f473;
$fa-var-dolphin: \e173;
$fa-var-dong-sign: \e174;
$fa-var-door-closed: \f52a;
$fa-var-door-open: \f52b;
$fa-var-dove: \f4ba;
$fa-var-down: \f354;
$fa-var-arrow-alt-down: \f354;
$fa-var-down-from-line: \f349;
$fa-var-arrow-alt-from-top: \f349;
$fa-var-down-left: \e175;
$fa-var-down-left-and-up-right-to-center: \f422;
$fa-var-compress-alt: \f422;
$fa-var-down-long: \f309;
$fa-var-long-arrow-alt-down: \f309;
$fa-var-down-right: \e176;
$fa-var-down-to-line: \f34a;
$fa-var-arrow-alt-to-bottom: \f34a;
$fa-var-download: \f019;
$fa-var-dragon: \f6d5;
$fa-var-draw-circle: \f5ed;
$fa-var-draw-polygon: \f5ee;
$fa-var-draw-square: \f5ef;
$fa-var-dreidel: \f792;
$fa-var-drone: \f85f;
$fa-var-drone-front: \f860;
$fa-var-drone-alt: \f860;
$fa-var-droplet: \f043;
$fa-var-tint: \f043;
$fa-var-droplet-degree: \f748;
$fa-var-dewpoint: \f748;
$fa-var-droplet-percent: \f750;
$fa-var-humidity: \f750;
$fa-var-droplet-slash: \f5c7;
$fa-var-tint-slash: \f5c7;
$fa-var-drum: \f569;
$fa-var-drum-steelpan: \f56a;
$fa-var-drumstick: \f6d6;
$fa-var-drumstick-bite: \f6d7;
$fa-var-dryer: \f861;
$fa-var-dryer-heat: \f862;
$fa-var-dryer-alt: \f862;
$fa-var-duck: \f6d8;
$fa-var-dumbbell: \f44b;
$fa-var-dumpster: \f793;
$fa-var-dumpster-fire: \f794;
$fa-var-dungeon: \f6d9;
$fa-var-e: \e177;
$fa-var-ear: \f5f0;
$fa-var-ear-deaf: \f2a4;
$fa-var-deaf: \f2a4;
$fa-var-deafness: \f2a4;
$fa-var-hard-of-hearing: \f2a4;
$fa-var-ear-listen: \f2a2;
$fa-var-assistive-listening-systems: \f2a2;
$fa-var-ear-muffs: \f795;
$fa-var-earth-africa: \f57c;
$fa-var-globe-africa: \f57c;
$fa-var-earth-americas: \f57d;
$fa-var-earth: \f57d;
$fa-var-globe-americas: \f57d;
$fa-var-earth-asia: \f57e;
$fa-var-globe-asia: \f57e;
$fa-var-earth-europa: \f7a2;
$fa-var-globe-europe: \f7a2;
$fa-var-eclipse: \f749;
$fa-var-egg: \f7fb;
$fa-var-egg-fried: \f7fc;
$fa-var-eggplant: \e178;
$fa-var-eject: \f052;
$fa-var-elephant: \f6da;
$fa-var-elevator: \e179;
$fa-var-ellipsis: \f141;
$fa-var-ellipsis-h: \f141;
$fa-var-ellipsis-stroke: \f39b;
$fa-var-ellipsis-h-alt: \f39b;
$fa-var-ellipsis-stroke-vertical: \f39c;
$fa-var-ellipsis-v-alt: \f39c;
$fa-var-ellipsis-vertical: \f142;
$fa-var-ellipsis-v: \f142;
$fa-var-empty-set: \f656;
$fa-var-engine: \e17a;
$fa-var-engine-warning: \f5f2;
$fa-var-engine-exclamation: \f5f2;
$fa-var-envelope: \f0e0;
$fa-var-envelope-dot: \e17b;
$fa-var-envelope-badge: \e17b;
$fa-var-envelope-open: \f2b6;
$fa-var-envelope-open-dollar: \f657;
$fa-var-envelope-open-text: \f658;
$fa-var-envelopes: \e17c;
$fa-var-envelopes-bulk: \f674;
$fa-var-mail-bulk: \f674;
$fa-var-equals: \f52c;
$fa-var-eraser: \f12d;
$fa-var-escalator: \e17d;
$fa-var-ethernet: \f796;
$fa-var-euro-sign: \f153;
$fa-var-euro: \f153;
$fa-var-eur: \f153;
$fa-var-exclamation: \f12a;
$fa-var-expand: \f065;
$fa-var-expand-wide: \f320;
$fa-var-eye: \f06e;
$fa-var-eye-dropper: \f1fb;
$fa-var-eye-dropper-empty: \f1fb;
$fa-var-eyedropper: \f1fb;
$fa-var-eye-dropper-full: \e17e;
$fa-var-eye-dropper-half: \e17f;
$fa-var-eye-evil: \f6db;
$fa-var-eye-low-vision: \f2a8;
$fa-var-low-vision: \f2a8;
$fa-var-eye-slash: \f070;
$fa-var-f: \e180;
$fa-var-face-angry: \f556;
$fa-var-angry: \f556;
$fa-var-face-dizzy: \f567;
$fa-var-dizzy: \f567;
$fa-var-face-flushed: \f579;
$fa-var-flushed: \f579;
$fa-var-face-frown: \f119;
$fa-var-frown: \f119;
$fa-var-face-frown-open: \f57a;
$fa-var-frown-open: \f57a;
$fa-var-face-grimace: \f57f;
$fa-var-grimace: \f57f;
$fa-var-face-grin: \f580;
$fa-var-grin: \f580;
$fa-var-face-grin-beam: \f582;
$fa-var-grin-beam: \f582;
$fa-var-face-grin-beam-sweat: \f583;
$fa-var-grin-beam-sweat: \f583;
$fa-var-face-grin-hearts: \f584;
$fa-var-grin-hearts: \f584;
$fa-var-face-grin-squint: \f585;
$fa-var-grin-squint: \f585;
$fa-var-face-grin-squint-tears: \f586;
$fa-var-grin-squint-tears: \f586;
$fa-var-face-grin-stars: \f587;
$fa-var-grin-stars: \f587;
$fa-var-face-grin-tears: \f588;
$fa-var-grin-tears: \f588;
$fa-var-face-grin-tongue: \f589;
$fa-var-grin-tongue: \f589;
$fa-var-face-grin-tongue-squint: \f58a;
$fa-var-grin-tongue-squint: \f58a;
$fa-var-face-grin-tongue-wink: \f58b;
$fa-var-grin-tongue-wink: \f58b;
$fa-var-face-grin-wide: \f581;
$fa-var-grin-alt: \f581;
$fa-var-face-grin-wink: \f58c;
$fa-var-grin-wink: \f58c;
$fa-var-face-kiss: \f596;
$fa-var-kiss: \f596;
$fa-var-face-kiss-beam: \f597;
$fa-var-kiss-beam: \f597;
$fa-var-face-kiss-wink-heart: \f598;
$fa-var-kiss-wink-heart: \f598;
$fa-var-face-laugh: \f599;
$fa-var-laugh: \f599;
$fa-var-face-laugh-beam: \f59a;
$fa-var-laugh-beam: \f59a;
$fa-var-face-laugh-squint: \f59b;
$fa-var-laugh-squint: \f59b;
$fa-var-face-laugh-wink: \f59c;
$fa-var-laugh-wink: \f59c;
$fa-var-face-meh: \f11a;
$fa-var-meh: \f11a;
$fa-var-face-meh-blank: \f5a4;
$fa-var-meh-blank: \f5a4;
$fa-var-face-rolling-eyes: \f5a5;
$fa-var-meh-rolling-eyes: \f5a5;
$fa-var-face-sad-cry: \f5b3;
$fa-var-sad-cry: \f5b3;
$fa-var-face-sad-tear: \f5b4;
$fa-var-sad-tear: \f5b4;
$fa-var-face-smile: \f118;
$fa-var-smile: \f118;
$fa-var-face-smile-beam: \f5b8;
$fa-var-smile-beam: \f5b8;
$fa-var-face-smile-plus: \f5b9;
$fa-var-smile-plus: \f5b9;
$fa-var-face-smile-wink: \f4da;
$fa-var-smile-wink: \f4da;
$fa-var-face-surprise: \f5c2;
$fa-var-surprise: \f5c2;
$fa-var-face-tired: \f5c8;
$fa-var-tired: \f5c8;
$fa-var-fan: \f863;
$fa-var-fan-table: \e004;
$fa-var-farm: \f864;
$fa-var-barn-silo: \f864;
$fa-var-faucet: \e005;
$fa-var-faucet-drip: \e006;
$fa-var-fax: \f1ac;
$fa-var-feather: \f52d;
$fa-var-feather-pointed: \f56b;
$fa-var-feather-alt: \f56b;
$fa-var-fence: \e181;
$fa-var-field-hockey-stick-ball: \f44c;
$fa-var-field-hockey: \f44c;
$fa-var-file: \f15b;
$fa-var-file-arrow-down: \f56d;
$fa-var-file-download: \f56d;
$fa-var-file-arrow-up: \f574;
$fa-var-file-upload: \f574;
$fa-var-file-audio: \f1c7;
$fa-var-file-binary: \e182;
$fa-var-file-certificate: \f5f3;
$fa-var-file-award: \f5f3;
$fa-var-file-chart-column: \f659;
$fa-var-file-chart-line: \f659;
$fa-var-file-chart-pie: \f65a;
$fa-var-file-check: \f316;
$fa-var-file-code: \f1c9;
$fa-var-file-contract: \f56c;
$fa-var-file-csv: \f6dd;
$fa-var-file-dashed-line: \f877;
$fa-var-page-break: \f877;
$fa-var-file-excel: \f1c3;
$fa-var-file-exclamation: \f31a;
$fa-var-file-export: \f56e;
$fa-var-arrow-right-from-file: \f56e;
$fa-var-file-heart: \e183;
$fa-var-file-image: \f1c5;
$fa-var-file-import: \f56f;
$fa-var-arrow-right-to-file: \f56f;
$fa-var-file-invoice: \f570;
$fa-var-file-invoice-dollar: \f571;
$fa-var-file-lines: \f15c;
$fa-var-file-alt: \f15c;
$fa-var-file-text: \f15c;
$fa-var-file-magnifying-glass: \f865;
$fa-var-file-search: \f865;
$fa-var-file-medical: \f477;
$fa-var-file-minus: \f318;
$fa-var-file-music: \f8b6;
$fa-var-file-pdf: \f1c1;
$fa-var-file-pen: \f31c;
$fa-var-file-edit: \f31c;
$fa-var-file-plus: \f319;
$fa-var-file-plus-minus: \e184;
$fa-var-file-powerpoint: \f1c4;
$fa-var-file-prescription: \f572;
$fa-var-file-signature: \f573;
$fa-var-file-spreadsheet: \f65b;
$fa-var-file-user: \f65c;
$fa-var-file-video: \f1c8;
$fa-var-file-waveform: \f478;
$fa-var-file-medical-alt: \f478;
$fa-var-file-word: \f1c2;
$fa-var-file-xmark: \f317;
$fa-var-file-times: \f317;
$fa-var-file-zipper: \f1c6;
$fa-var-file-archive: \f1c6;
$fa-var-files: \e185;
$fa-var-files-medical: \f7fd;
$fa-var-fill: \f575;
$fa-var-fill-drip: \f576;
$fa-var-film: \f008;
$fa-var-film-canister: \f8b7;
$fa-var-film-simple: \f3a0;
$fa-var-film-alt: \f3a0;
$fa-var-film-slash: \e186;
$fa-var-films: \e187;
$fa-var-filter: \f0b0;
$fa-var-filter-circle-dollar: \f662;
$fa-var-funnel-dollar: \f662;
$fa-var-filter-circle-xmark: \e188;
$fa-var-filter-list: \e189;
$fa-var-filter-slash: \e18a;
$fa-var-filters: \e18b;
$fa-var-fingerprint: \f577;
$fa-var-fire: \f06d;
$fa-var-fire-extinguisher: \f134;
$fa-var-fire-flame: \f6df;
$fa-var-flame: \f6df;
$fa-var-fire-flame-curved: \f7e4;
$fa-var-fire-alt: \f7e4;
$fa-var-fire-flame-simple: \f46a;
$fa-var-burn: \f46a;
$fa-var-fire-hydrant: \e18c;
$fa-var-fire-smoke: \f74b;
$fa-var-fireplace: \f79a;
$fa-var-fish: \f578;
$fa-var-fish-cooked: \f7fe;
$fa-var-flag: \f024;
$fa-var-flag-checkered: \f11e;
$fa-var-flag-pennant: \f456;
$fa-var-pennant: \f456;
$fa-var-flag-swallowtail: \f74c;
$fa-var-flag-alt: \f74c;
$fa-var-flag-usa: \f74d;
$fa-var-flashlight: \f8b8;
$fa-var-flask: \f0c3;
$fa-var-flask-round-poison: \f6e0;
$fa-var-flask-poison: \f6e0;
$fa-var-flask-round-potion: \f6e1;
$fa-var-flask-potion: \f6e1;
$fa-var-floppy-disk: \f0c7;
$fa-var-save: \f0c7;
$fa-var-floppy-disk-circle-arrow-right: \e18d;
$fa-var-save-circle-arrow-right: \e18d;
$fa-var-floppy-disk-circle-xmark: \e18e;
$fa-var-floppy-disk-times: \e18e;
$fa-var-save-times: \e18e;
$fa-var-save-circle-xmark: \e18e;
$fa-var-floppy-disk-pen: \e18f;
$fa-var-floppy-disks: \e190;
$fa-var-florin-sign: \e191;
$fa-var-flower: \f7ff;
$fa-var-flower-daffodil: \f800;
$fa-var-flower-tulip: \f801;
$fa-var-flute: \f8b9;
$fa-var-flux-capacitor: \f8ba;
$fa-var-folder: \f07b;
$fa-var-folder-arrow-down: \e053;
$fa-var-folder-download: \e053;
$fa-var-folder-arrow-up: \e054;
$fa-var-folder-upload: \e054;
$fa-var-folder-blank: \e192;
$fa-var-folder-bookmark: \e193;
$fa-var-folder-gear: \e194;
$fa-var-folder-cog: \e194;
$fa-var-folder-grid: \e195;
$fa-var-folder-heart: \e196;
$fa-var-folder-image: \e197;
$fa-var-folder-magnifying-glass: \e198;
$fa-var-folder-search: \e198;
$fa-var-folder-medical: \e199;
$fa-var-folder-minus: \f65d;
$fa-var-folder-music: \e19a;
$fa-var-folder-open: \f07c;
$fa-var-folder-plus: \f65e;
$fa-var-folder-tree: \f802;
$fa-var-folder-user: \e19b;
$fa-var-folder-xmark: \f65f;
$fa-var-folder-times: \f65f;
$fa-var-folders: \f660;
$fa-var-font: \f031;
$fa-var-font-case: \f866;
$fa-var-football-ball: \f44e;
$fa-var-football-helmet: \f44f;
$fa-var-fork: \f2e3;
$fa-var-utensil-fork: \f2e3;
$fa-var-fork-knife: \f2e6;
$fa-var-utensils-alt: \f2e6;
$fa-var-forklift: \f47a;
$fa-var-forward: \f04e;
$fa-var-forward-fast: \f050;
$fa-var-fast-forward: \f050;
$fa-var-forward-step: \f051;
$fa-var-step-forward: \f051;
$fa-var-franc-sign: \e19c;
$fa-var-french-fries: \f803;
$fa-var-frog: \f52e;
$fa-var-function: \f661;
$fa-var-futbol-ball: \f1e3;
$fa-var-soccer-ball: \f1e3;
$fa-var-futbol: \f1e3;
$fa-var-g: \e19d;
$fa-var-galaxy: \e008;
$fa-var-game-board: \f867;
$fa-var-game-board-simple: \f868;
$fa-var-game-board-alt: \f868;
$fa-var-game-console-handheld: \f8bb;
$fa-var-gamepad: \f11b;
$fa-var-gamepad-modern: \f8bc;
$fa-var-gamepad-alt: \f8bc;
$fa-var-garage: \e009;
$fa-var-garage-car: \e00a;
$fa-var-garage-open: \e00b;
$fa-var-gas-pump: \f52f;
$fa-var-gas-pump-slash: \f5f4;
$fa-var-gauge: \f625;
$fa-var-gauge-high: \f625;
$fa-var-tachometer-alt: \f625;
$fa-var-tachometer-alt-fast: \f625;
$fa-var-dashboard: \f625;
$fa-var-gauge-low: \f627;
$fa-var-tachometer-alt-slow: \f627;
$fa-var-gauge-max: \f626;
$fa-var-tachometer-alt-fastest: \f626;
$fa-var-gauge-med: \f624;
$fa-var-tachometer-alt-average: \f624;
$fa-var-gauge-min: \f628;
$fa-var-tachometer-alt-slowest: \f628;
$fa-var-gauge-simple: \f62a;
$fa-var-gauge-simple-high: \f62a;
$fa-var-tachometer: \f62a;
$fa-var-gauge-simple-low: \f62c;
$fa-var-tachometer-slow: \f62c;
$fa-var-gauge-simple-max: \f62b;
$fa-var-tachometer-fastest: \f62b;
$fa-var-gauge-simple-med: \f629;
$fa-var-tachometer-average: \f629;
$fa-var-gauge-simple-min: \f62d;
$fa-var-tachometer-slowest: \f62d;
$fa-var-gavel: \f0e3;
$fa-var-legal: \f0e3;
$fa-var-gear: \f013;
$fa-var-cog: \f013;
$fa-var-gears: \f085;
$fa-var-cogs: \f085;
$fa-var-gem: \f3a5;
$fa-var-genderless: \f22d;
$fa-var-ghost: \f6e2;
$fa-var-gif: \e19e;
$fa-var-gift: \f06b;
$fa-var-gift-card: \f663;
$fa-var-gifts: \f79c;
$fa-var-gingerbread-man: \f79d;
$fa-var-glass: \f804;
$fa-var-glass-citrus: \f869;
$fa-var-glass-empty: \e19f;
$fa-var-glass-half: \e1a0;
$fa-var-glass-half-full: \e1a0;
$fa-var-glass-half-empty: \e1a0;
$fa-var-glasses: \f530;
$fa-var-glasses-round: \f5f5;
$fa-var-glasses-alt: \f5f5;
$fa-var-globe: \f0ac;
$fa-var-globe-snow: \f7a3;
$fa-var-globe-stand: \f5f6;
$fa-var-golf-ball-tee: \f450;
$fa-var-golf-ball: \f450;
$fa-var-golf-club: \f451;
$fa-var-gopuram: \f664;
$fa-var-graduation-cap: \f19d;
$fa-var-mortar-board: \f19d;
$fa-var-gramophone: \f8bd;
$fa-var-grate: \e1a1;
$fa-var-grate-droplet: \e1a2;
$fa-var-greater-than: \f531;
$fa-var-greater-than-equal: \f532;
$fa-var-grid: \e1a3;
$fa-var-grid-3: \e1a3;
$fa-var-grid-2: \e1a4;
$fa-var-grid-2-plus: \e1a5;
$fa-var-grid-4: \e1a6;
$fa-var-grid-5: \e1a7;
$fa-var-grip: \f58d;
$fa-var-grip-horizontal: \f58d;
$fa-var-grip-lines: \f7a4;
$fa-var-grip-lines-vertical: \f7a5;
$fa-var-grip-vertical: \f58e;
$fa-var-guarani-sign: \e1a8;
$fa-var-guitar: \f7a6;
$fa-var-guitar-electric: \f8be;
$fa-var-guitars: \f8bf;
$fa-var-gun: \e1a9;
$fa-var-gun-slash: \e1aa;
$fa-var-gun-squirt: \e1ab;
$fa-var-h: \e1ac;
$fa-var-h1: \f313;
$fa-var-h2: \f314;
$fa-var-h3: \f315;
$fa-var-h4: \f86a;
$fa-var-hammer: \f6e3;
$fa-var-hammer-war: \f6e4;
$fa-var-hamsa: \f665;
$fa-var-hand-back-point-down: \e1ad;
$fa-var-hand-back-point-left: \e1ae;
$fa-var-hand-back-point-right: \e1af;
$fa-var-hand-back-point-up: \e1b0;
$fa-var-hand-dots: \f461;
$fa-var-allergies: \f461;
$fa-var-hand-fingers-crossed: \e1b1;
$fa-var-hand-fist: \f6de;
$fa-var-fist-raised: \f6de;
$fa-var-hand-heart: \f4bc;
$fa-var-hand-holding: \f4bd;
$fa-var-hand-holding-box: \f47b;
$fa-var-hand-holding-dollar: \f4c0;
$fa-var-hand-holding-usd: \f4c0;
$fa-var-hand-holding-droplet: \f4c1;
$fa-var-hand-holding-water: \f4c1;
$fa-var-hand-holding-heart: \f4be;
$fa-var-hand-holding-magic: \f6e5;
$fa-var-hand-holding-medical: \e05c;
$fa-var-hand-holding-seedling: \f4bf;
$fa-var-hand-holding-skull: \e1b2;
$fa-var-hand-lizard: \f258;
$fa-var-hand-love: \e1b3;
$fa-var-hand-middle-finger: \f806;
$fa-var-hand-paper: \f256;
$fa-var-hand-peace: \f25b;
$fa-var-hand-point-down: \f0a7;
$fa-var-hand-point-left: \f0a5;
$fa-var-hand-point-right: \f0a4;
$fa-var-hand-point-up: \f0a6;
$fa-var-hand-pointer: \f25a;
$fa-var-hand-rock: \f255;
$fa-var-hand-scissors: \f257;
$fa-var-hand-sparkles: \e05d;
$fa-var-hand-spock: \f259;
$fa-var-hand-wave: \e1b4;
$fa-var-hands: \f2a7;
$fa-var-sign-language: \f2a7;
$fa-var-signing: \f2a7;
$fa-var-hands-asl-interpreting: \f2a3;
$fa-var-american-sign-language-interpreting: \f2a3;
$fa-var-hands-american-sign-language-interpreting: \f2a3;
$fa-var-asl-interpreting: \f2a3;
$fa-var-hands-bubbles: \e05e;
$fa-var-hands-wash: \e05e;
$fa-var-hands-clapping: \e1b5;
$fa-var-hands-holding: \f4c2;
$fa-var-hands-holding-diamond: \f47c;
$fa-var-hand-receiving: \f47c;
$fa-var-hands-holding-dollar: \f4c5;
$fa-var-hands-usd: \f4c5;
$fa-var-hands-holding-heart: \f4c3;
$fa-var-hands-heart: \f4c3;
$fa-var-hands-horns: \e1b6;
$fa-var-hands-praying: \f684;
$fa-var-praying-hands: \f684;
$fa-var-handshake: \f2b5;
$fa-var-handshake-angle: \f4c4;
$fa-var-hands-helping: \f4c4;
$fa-var-handshake-simple: \f4c6;
$fa-var-handshake-alt: \f4c6;
$fa-var-handshake-simple-slash: \e05f;
$fa-var-handshake-alt-slash: \e05f;
$fa-var-handshake-slash: \e060;
$fa-var-hanukiah: \f6e6;
$fa-var-hard-drive: \f0a0;
$fa-var-hdd: \f0a0;
$fa-var-hashtag: \f292;
$fa-var-hat-chef: \f86b;
$fa-var-hat-cowboy: \f8c0;
$fa-var-hat-cowboy-side: \f8c1;
$fa-var-hat-santa: \f7a7;
$fa-var-hat-winter: \f7a8;
$fa-var-hat-witch: \f6e7;
$fa-var-hat-wizard: \f6e8;
$fa-var-head-side: \f6e9;
$fa-var-head-side-brain: \f808;
$fa-var-head-side-cough: \e061;
$fa-var-head-side-cough-slash: \e062;
$fa-var-head-side-goggles: \f6ea;
$fa-var-head-vr: \f6ea;
$fa-var-head-side-headphones: \f8c2;
$fa-var-head-side-heart: \e1b7;
$fa-var-head-side-mask: \e063;
$fa-var-head-side-medical: \f809;
$fa-var-head-side-virus: \e064;
$fa-var-heading: \f1dc;
$fa-var-header: \f1dc;
$fa-var-headphones: \f025;
$fa-var-headphones-simple: \f58f;
$fa-var-headphones-alt: \f58f;
$fa-var-headset: \f590;
$fa-var-heart: \f004;
$fa-var-heart-crack: \f7a9;
$fa-var-heart-broken: \f7a9;
$fa-var-heart-half: \e1b8;
$fa-var-heart-half-stroke: \e1b9;
$fa-var-heart-half-alt: \e1b9;
$fa-var-heart-pulse: \f21e;
$fa-var-heartbeat: \f21e;
$fa-var-heat: \e00c;
$fa-var-helicopter: \f533;
$fa-var-helmet-battle: \f6eb;
$fa-var-helmet-safety: \f807;
$fa-var-hat-hard: \f807;
$fa-var-hard-hat: \f807;
$fa-var-hexagon: \f312;
$fa-var-hexagon-divide: \e1ba;
$fa-var-hexagon-minus: \f307;
$fa-var-minus-hexagon: \f307;
$fa-var-hexagon-plus: \f300;
$fa-var-plus-hexagon: \f300;
$fa-var-hexagon-xmark: \f2ee;
$fa-var-times-hexagon: \f2ee;
$fa-var-xmark-hexagon: \f2ee;
$fa-var-high-definition: \e1bb;
$fa-var-highlighter: \f591;
$fa-var-highlighter-line: \e1bc;
$fa-var-hippo: \f6ed;
$fa-var-hockey-mask: \f6ee;
$fa-var-hockey-puck: \f453;
$fa-var-hockey-sticks: \f454;
$fa-var-holly-berry: \f7aa;
$fa-var-home: \f015;
$fa-var-home-lg: \f015;
$fa-var-home-heart: \f4c9;
$fa-var-home-simple: \f80a;
$fa-var-home-lg-alt: \f80a;
$fa-var-home-user: \e1bd;
$fa-var-hood-cloak: \f6ef;
$fa-var-horizontal-rule: \f86c;
$fa-var-horse: \f6f0;
$fa-var-horse-head: \f7ab;
$fa-var-horse-saddle: \f8c3;
$fa-var-hospital: \f0f8;
$fa-var-hospital-user: \f80d;
$fa-var-hospital-wide: \f47d;
$fa-var-hospital-alt: \f47d;
$fa-var-hospitals: \f80e;
$fa-var-hot-tub-person: \f593;
$fa-var-hot-tub: \f593;
$fa-var-hotdog: \f80f;
$fa-var-hotel: \f594;
$fa-var-hourglass: \f254;
$fa-var-hourglass-2: \f254;
$fa-var-hourglass-half: \f254;
$fa-var-hourglass-empty: \f252;
$fa-var-hourglass-end: \f253;
$fa-var-hourglass-3: \f253;
$fa-var-hourglass-start: \f251;
$fa-var-hourglass-1: \f251;
$fa-var-house: \e00d;
$fa-var-house-building: \e1be;
$fa-var-house-crack: \f6f1;
$fa-var-house-damage: \f6f1;
$fa-var-house-day: \e00e;
$fa-var-house-flood: \f74f;
$fa-var-house-heart: \e1bf;
$fa-var-house-laptop: \e066;
$fa-var-laptop-house: \e066;
$fa-var-house-medical: \f7f2;
$fa-var-clinic-medical: \f7f2;
$fa-var-house-night: \e010;
$fa-var-house-person-leave: \e00f;
$fa-var-house-person-depart: \e00f;
$fa-var-house-person-return: \e011;
$fa-var-house-person-arrive: \e011;
$fa-var-house-signal: \e012;
$fa-var-house-tree: \e1c0;
$fa-var-house-turret: \e1c1;
$fa-var-house-user: \e065;
$fa-var-hryvnia-sign: \f6f2;
$fa-var-hryvnia: \f6f2;
$fa-var-hurricane: \f751;
$fa-var-i: \e1c2;
$fa-var-i-cursor: \f246;
$fa-var-ice-cream: \f810;
$fa-var-ice-skate: \f7ac;
$fa-var-icicles: \f7ad;
$fa-var-icons: \f86d;
$fa-var-heart-music-camera-bolt: \f86d;
$fa-var-id-badge: \f2c1;
$fa-var-id-card: \f2c2;
$fa-var-drivers-license: \f2c2;
$fa-var-id-card-clip: \f47f;
$fa-var-id-card-alt: \f47f;
$fa-var-igloo: \f7ae;
$fa-var-image: \f03e;
$fa-var-image-landscape: \e1c3;
$fa-var-landscape: \e1c3;
$fa-var-image-polaroid: \f8c4;
$fa-var-image-polaroid-user: \e1c4;
$fa-var-image-portrait: \f3e0;
$fa-var-portrait: \f3e0;
$fa-var-image-slash: \e1c5;
$fa-var-image-user: \e1c6;
$fa-var-images: \f302;
$fa-var-images-user: \e1c7;
$fa-var-inbox: \f01c;
$fa-var-inbox-full: \e1c8;
$fa-var-inbox-in: \f310;
$fa-var-inbox-arrow-down: \f310;
$fa-var-inbox-out: \f311;
$fa-var-inbox-arrow-up: \f311;
$fa-var-inboxes: \e1c9;
$fa-var-indent: \f03c;
$fa-var-indian-rupee-sign: \e1ca;
$fa-var-indian-rupee: \e1ca;
$fa-var-inr: \e1ca;
$fa-var-industry: \f275;
$fa-var-industry-windows: \f3b3;
$fa-var-industry-alt: \f3b3;
$fa-var-infinity: \f534;
$fa-var-info: \f129;
$fa-var-inhaler: \f5f9;
$fa-var-input-numeric: \e1cb;
$fa-var-input-pipe: \e1cc;
$fa-var-input-text: \e1cd;
$fa-var-integral: \f667;
$fa-var-intersection: \f668;
$fa-var-island-tropical: \f811;
$fa-var-island-tree-palm: \f811;
$fa-var-italic: \f033;
$fa-var-j: \e1ce;
$fa-var-jack-o-lantern: \f30e;
$fa-var-jedi: \f669;
$fa-var-jet-fighter: \f0fb;
$fa-var-fighter-jet: \f0fb;
$fa-var-joint: \f595;
$fa-var-joystick: \f8c5;
$fa-var-jug: \f8c6;
$fa-var-k: \e1cf;
$fa-var-kaaba: \f66b;
$fa-var-kazoo: \f8c7;
$fa-var-kerning: \f86f;
$fa-var-key: \f084;
$fa-var-key-skeleton: \f6f3;
$fa-var-keyboard: \f11c;
$fa-var-keyboard-down: \e1d0;
$fa-var-keyboard-left: \e1d1;
$fa-var-keynote: \f66c;
$fa-var-khanda: \f66d;
$fa-var-kidneys: \f5fb;
$fa-var-kip-sign: \e1d2;
$fa-var-kit-medical: \f479;
$fa-var-first-aid: \f479;
$fa-var-kite: \f6f4;
$fa-var-kiwi-bird: \f535;
$fa-var-knife: \f2e4;
$fa-var-utensil-knife: \f2e4;
$fa-var-knife-kitchen: \f6f5;
$fa-var-l: \e1d3;
$fa-var-lambda: \f66e;
$fa-var-lamp: \f4ca;
$fa-var-lamp-desk: \e014;
$fa-var-lamp-floor: \e015;
$fa-var-lamp-street: \e1d4;
$fa-var-landmark: \f66f;
$fa-var-landmark-dome: \f752;
$fa-var-landmark-alt: \f752;
$fa-var-language: \f1ab;
$fa-var-laptop: \f109;
$fa-var-laptop-arrow-down: \e1d5;
$fa-var-laptop-code: \f5fc;
$fa-var-laptop-medical: \f812;
$fa-var-laptop-mobile: \f87a;
$fa-var-phone-laptop: \f87a;
$fa-var-laptop-slash: \e1d6;
$fa-var-lari-sign: \e1d7;
$fa-var-lasso: \f8c8;
$fa-var-lasso-sparkles: \e1d8;
$fa-var-layers: \f5fd;
$fa-var-layer-group: \f5fd;
$fa-var-layers-minus: \f5fe;
$fa-var-layer-minus: \f5fe;
$fa-var-layers-plus: \f5ff;
$fa-var-layer-plus: \f5ff;
$fa-var-leaf: \f06c;
$fa-var-leaf-heart: \f4cb;
$fa-var-leaf-maple: \f6f6;
$fa-var-leaf-oak: \f6f7;
$fa-var-left: \f355;
$fa-var-arrow-alt-left: \f355;
$fa-var-left-from-line: \f348;
$fa-var-arrow-alt-from-right: \f348;
$fa-var-left-long: \f30a;
$fa-var-long-arrow-alt-left: \f30a;
$fa-var-left-right: \f337;
$fa-var-arrows-alt-h: \f337;
$fa-var-left-to-line: \f34b;
$fa-var-arrow-alt-to-left: \f34b;
$fa-var-lemon: \f094;
$fa-var-less-than: \f536;
$fa-var-less-than-equal: \f537;
$fa-var-life-ring: \f1cd;
$fa-var-light-ceiling: \e016;
$fa-var-light-switch: \e017;
$fa-var-light-switch-off: \e018;
$fa-var-light-switch-on: \e019;
$fa-var-lightbulb: \f0eb;
$fa-var-lightbulb-dollar: \f670;
$fa-var-lightbulb-exclamation: \f671;
$fa-var-lightbulb-on: \f672;
$fa-var-lightbulb-slash: \f673;
$fa-var-lights-holiday: \f7b2;
$fa-var-line-columns: \f870;
$fa-var-line-height: \f871;
$fa-var-link: \f0c1;
$fa-var-chain: \f0c1;
$fa-var-link-horizontal: \e1d9;
$fa-var-chain-horizontal: \e1d9;
$fa-var-link-horizontal-slash: \e1da;
$fa-var-chain-horizontal-slash: \e1da;
$fa-var-link-simple: \e1db;
$fa-var-link-simple-slash: \e1dc;
$fa-var-link-slash: \f127;
$fa-var-chain-slash: \f127;
$fa-var-chain-broken: \f127;
$fa-var-unlink: \f127;
$fa-var-lips: \f600;
$fa-var-lira-sign: \f195;
$fa-var-list: \f03a;
$fa-var-list-squares: \f03a;
$fa-var-list-check: \f0ae;
$fa-var-tasks: \f0ae;
$fa-var-list-dropdown: \e1dd;
$fa-var-list-music: \f8c9;
$fa-var-list-ol: \f0cb;
$fa-var-list-numeric: \f0cb;
$fa-var-list-1-2: \f0cb;
$fa-var-list-radio: \e1de;
$fa-var-list-timeline: \e1df;
$fa-var-list-tree: \e1e0;
$fa-var-list-ul: \f0ca;
$fa-var-list-dots: \f0ca;
$fa-var-litecoin-sign: \e1e1;
$fa-var-loader: \e1e2;
$fa-var-location: \f041;
$fa-var-map-marker: \f041;
$fa-var-location-arrow: \f124;
$fa-var-location-check: \f606;
$fa-var-map-marker-check: \f606;
$fa-var-location-crosshairs: \f601;
$fa-var-location-crosshairs-slash: \f603;
$fa-var-location-dot: \f3c5;
$fa-var-map-marker-alt: \f3c5;
$fa-var-location-dot-slash: \f605;
$fa-var-map-marker-alt-slash: \f605;
$fa-var-location-exclamation: \f608;
$fa-var-map-marker-exclamation: \f608;
$fa-var-location-minus: \f609;
$fa-var-map-marker-minus: \f609;
$fa-var-location-pen: \f607;
$fa-var-map-marker-edit: \f607;
$fa-var-location-plus: \f60a;
$fa-var-map-marker-plus: \f60a;
$fa-var-location-question: \f60b;
$fa-var-map-marker-question: \f60b;
$fa-var-location-slash: \f60c;
$fa-var-map-marker-slash: \f60c;
$fa-var-location-smile: \f60d;
$fa-var-map-marker-smile: \f60d;
$fa-var-location-xmark: \f60e;
$fa-var-map-marker-times: \f60e;
$fa-var-map-marker-xmark: \f60e;
$fa-var-lock: \f023;
$fa-var-lock-keyhole: \f30d;
$fa-var-lock-alt: \f30d;
$fa-var-lock-keyhole-open: \f3c2;
$fa-var-lock-open-alt: \f3c2;
$fa-var-lock-open: \f3c1;
$fa-var-loveseat: \f4cc;
$fa-var-couch-small: \f4cc;
$fa-var-luchador-mask: \f455;
$fa-var-luchador: \f455;
$fa-var-mask-luchador: \f455;
$fa-var-lungs: \f604;
$fa-var-lungs-virus: \e067;
$fa-var-m: \e1e3;
$fa-var-mace: \f6f8;
$fa-var-magnet: \f076;
$fa-var-magnifying-glass: \f002;
$fa-var-search: \f002;
$fa-var-magnifying-glass-dollar: \f688;
$fa-var-search-dollar: \f688;
$fa-var-magnifying-glass-location: \f689;
$fa-var-search-location: \f689;
$fa-var-magnifying-glass-minus: \f010;
$fa-var-search-minus: \f010;
$fa-var-magnifying-glass-plus: \f00e;
$fa-var-search-plus: \f00e;
$fa-var-mailbox: \f813;
$fa-var-manat-sign: \e1e4;
$fa-var-mandolin: \f6f9;
$fa-var-manhole: \e1e5;
$fa-var-map: \f279;
$fa-var-map-location: \f59f;
$fa-var-map-marked: \f59f;
$fa-var-map-location-dot: \f5a0;
$fa-var-map-marked-alt: \f5a0;
$fa-var-map-pin: \f276;
$fa-var-marker: \f5a1;
$fa-var-mars: \f222;
$fa-var-mars-double: \f227;
$fa-var-mars-stroke: \f229;
$fa-var-mars-stroke-right: \f22b;
$fa-var-mars-stroke-h: \f22b;
$fa-var-mars-stroke-up: \f22a;
$fa-var-mars-stroke-v: \f22a;
$fa-var-martini-glass: \f57b;
$fa-var-glass-martini-alt: \f57b;
$fa-var-martini-glass-citrus: \f561;
$fa-var-cocktail: \f561;
$fa-var-martini-glass-empty: \f000;
$fa-var-glass-martini: \f000;
$fa-var-mask: \f6fa;
$fa-var-mask-face: \e1e6;
$fa-var-masks-theater: \f630;
$fa-var-theater-masks: \f630;
$fa-var-maximize: \f31e;
$fa-var-expand-arrows-alt: \f31e;
$fa-var-meat: \f814;
$fa-var-medal: \f5a2;
$fa-var-megaphone: \f675;
$fa-var-memo: \e1e7;
$fa-var-memo-circle-check: \e1e8;
$fa-var-memory: \f538;
$fa-var-menorah: \f676;
$fa-var-mercury: \f223;
$fa-var-message: \f27a;
$fa-var-comment-alt: \f27a;
$fa-var-message-arrow-down: \e1e9;
$fa-var-comment-alt-arrow-down: \e1e9;
$fa-var-message-arrow-up: \e1ea;
$fa-var-comment-alt-arrow-up: \e1ea;
$fa-var-message-arrow-up-right: \e1eb;
$fa-var-message-captions: \e1ec;
$fa-var-comment-alt-captions: \e1ec;
$fa-var-message-check: \f4a2;
$fa-var-comment-alt-check: \f4a2;
$fa-var-message-code: \e1ed;
$fa-var-message-dollar: \f650;
$fa-var-comment-alt-dollar: \f650;
$fa-var-message-dots: \f4a3;
$fa-var-comment-alt-dots: \f4a3;
$fa-var-messaging: \f4a3;
$fa-var-message-exclamation: \f4a5;
$fa-var-comment-alt-exclamation: \f4a5;
$fa-var-message-image: \e1ee;
$fa-var-comment-alt-image: \e1ee;
$fa-var-message-lines: \f4a6;
$fa-var-comment-alt-lines: \f4a6;
$fa-var-message-medical: \f7f4;
$fa-var-comment-alt-medical: \f7f4;
$fa-var-message-middle: \e1ef;
$fa-var-comment-middle-alt: \e1ef;
$fa-var-message-middle-top: \e1f0;
$fa-var-comment-middle-top-alt: \e1f0;
$fa-var-message-minus: \f4a7;
$fa-var-comment-alt-minus: \f4a7;
$fa-var-message-music: \f8af;
$fa-var-comment-alt-music: \f8af;
$fa-var-message-pen: \f4a4;
$fa-var-message-edit: \f4a4;
$fa-var-comment-alt-edit: \f4a4;
$fa-var-message-plus: \f4a8;
$fa-var-comment-alt-plus: \f4a8;
$fa-var-message-question: \e1f1;
$fa-var-message-quote: \e1f2;
$fa-var-comment-alt-quote: \e1f2;
$fa-var-message-slash: \f4a9;
$fa-var-comment-alt-slash: \f4a9;
$fa-var-message-smile: \f4aa;
$fa-var-comment-alt-smile: \f4aa;
$fa-var-message-sms: \e1f3;
$fa-var-message-text: \e1f4;
$fa-var-comment-alt-text: \e1f4;
$fa-var-message-xmark: \f4ab;
$fa-var-comment-alt-times: \f4ab;
$fa-var-message-times: \f4ab;
$fa-var-messages: \f4b6;
$fa-var-comments-alt: \f4b6;
$fa-var-messages-dollar: \f652;
$fa-var-comments-alt-dollar: \f652;
$fa-var-messages-question: \e1f5;
$fa-var-meteor: \f753;
$fa-var-meter: \e1f6;
$fa-var-meter-bolt: \e1f7;
$fa-var-meter-droplet: \e1f8;
$fa-var-meter-fire: \e1f9;
$fa-var-microchip: \f2db;
$fa-var-microchip-ai: \e1fa;
$fa-var-microphone: \f130;
$fa-var-microphone-lines: \f3c9;
$fa-var-microphone-alt: \f3c9;
$fa-var-microphone-lines-slash: \f539;
$fa-var-microphone-alt-slash: \f539;
$fa-var-microphone-slash: \f131;
$fa-var-microphone-stand: \f8cb;
$fa-var-microscope: \f610;
$fa-var-microwave: \e01b;
$fa-var-mill-sign: \e1fb;
$fa-var-minimize: \f78c;
$fa-var-compress-arrows-alt: \f78c;
$fa-var-minus: \f068;
$fa-var-subtract: \f068;
$fa-var-mistletoe: \f7b4;
$fa-var-mitten: \f7b5;
$fa-var-mobile: \f3ce;
$fa-var-mobile-android: \f3ce;
$fa-var-mobile-phone: \f3ce;
$fa-var-mobile-button: \f10b;
$fa-var-mobile-notch: \e1fc;
$fa-var-mobile-iphone: \e1fc;
$fa-var-mobile-screen: \f3cf;
$fa-var-mobile-android-alt: \f3cf;
$fa-var-mobile-screen-button: \f3cd;
$fa-var-mobile-alt: \f3cd;
$fa-var-money-bill: \f0d6;
$fa-var-money-bill-1: \f3d1;
$fa-var-money-bill-alt: \f3d1;
$fa-var-money-bill-1-wave: \f53b;
$fa-var-money-bill-wave-alt: \f53b;
$fa-var-money-bill-simple: \e1fd;
$fa-var-money-bill-simple-wave: \e1fe;
$fa-var-money-bill-wave: \f53a;
$fa-var-money-bills: \e1ff;
$fa-var-money-bills-simple: \e200;
$fa-var-money-bills-alt: \e200;
$fa-var-money-check: \f53c;
$fa-var-money-check-dollar: \f53d;
$fa-var-money-check-alt: \f53d;
$fa-var-money-check-dollar-pen: \f873;
$fa-var-money-check-edit-alt: \f873;
$fa-var-money-check-pen: \f872;
$fa-var-money-check-edit: \f872;
$fa-var-monitor-waveform: \f611;
$fa-var-monitor-heart-rate: \f611;
$fa-var-monkey: \f6fb;
$fa-var-monument: \f5a6;
$fa-var-moon: \f186;
$fa-var-moon-cloud: \f754;
$fa-var-moon-over-sun: \f74a;
$fa-var-eclipse-alt: \f74a;
$fa-var-moon-stars: \f755;
$fa-var-mortar-pestle: \f5a7;
$fa-var-mosque: \f678;
$fa-var-motorcycle: \f21c;
$fa-var-mountain: \f6fc;
$fa-var-mountains: \f6fd;
$fa-var-mp3-player: \f8ce;
$fa-var-mug: \f874;
$fa-var-mug-hot: \f7b6;
$fa-var-mug-marshmallows: \f7b7;
$fa-var-mug-saucer: \f0f4;
$fa-var-coffee: \f0f4;
$fa-var-mug-tea: \f875;
$fa-var-mug-tea-saucer: \e201;
$fa-var-music: \f001;
$fa-var-music-note: \f8cf;
$fa-var-music-alt: \f8cf;
$fa-var-music-note-slash: \f8d0;
$fa-var-music-alt-slash: \f8d0;
$fa-var-music-slash: \f8d1;
$fa-var-n: \e202;
$fa-var-naira-sign: \e203;
$fa-var-narwhal: \f6fe;
$fa-var-network-wired: \f6ff;
$fa-var-neuter: \f22c;
$fa-var-newspaper: \f1ea;
$fa-var-not-equal: \f53e;
$fa-var-notdef: \e204;
$fa-var-note: \e205;
$fa-var-note-medical: \e206;
$fa-var-note-sticky: \f249;
$fa-var-sticky-note: \f249;
$fa-var-notes: \e207;
$fa-var-notes-medical: \f481;
$fa-var-o: \e208;
$fa-var-object-group: \f247;
$fa-var-object-ungroup: \f248;
$fa-var-octagon: \f306;
$fa-var-octagon-divide: \e209;
$fa-var-octagon-exclamation: \e20a;
$fa-var-octagon-minus: \f308;
$fa-var-minus-octagon: \f308;
$fa-var-octagon-plus: \f301;
$fa-var-plus-octagon: \f301;
$fa-var-octagon-xmark: \f2f0;
$fa-var-times-octagon: \f2f0;
$fa-var-xmark-octagon: \f2f0;
$fa-var-oil-can: \f613;
$fa-var-oil-can-drip: \e20b;
$fa-var-oil-temperature: \f614;
$fa-var-oil-temp: \f614;
$fa-var-om: \f679;
$fa-var-omega: \f67a;
$fa-var-option: \e20c;
$fa-var-ornament: \f7b8;
$fa-var-otter: \f700;
$fa-var-outdent: \f03b;
$fa-var-dedent: \f03b;
$fa-var-outlet: \e01c;
$fa-var-oven: \e01d;
$fa-var-overline: \f876;
$fa-var-p: \e20d;
$fa-var-pager: \f815;
$fa-var-paint-brush: \f1fc;
$fa-var-paint-brush-fine: \f5a9;
$fa-var-paint-brush-alt: \f5a9;
$fa-var-paint-roller: \f5aa;
$fa-var-paintbrush-pencil: \e20e;
$fa-var-palette: \f53f;
$fa-var-palette-boxes: \e20f;
$fa-var-pallet: \f482;
$fa-var-pallet-box: \e210;
$fa-var-pallet-boxes: \f483;
$fa-var-pallet-alt: \f483;
$fa-var-panorama: \e211;
$fa-var-paper-plane: \f1d8;
$fa-var-paper-plane-top: \e212;
$fa-var-paper-plane-alt: \e212;
$fa-var-send: \e212;
$fa-var-paperclip: \f0c6;
$fa-var-parachute-box: \f4cd;
$fa-var-paragraph: \f1dd;
$fa-var-paragraph-left: \f878;
$fa-var-paragraph-rtl: \f878;
$fa-var-passport: \f5ab;
$fa-var-paste: \f0ea;
$fa-var-file-clipboard: \f0ea;
$fa-var-pause: \f04c;
$fa-var-paw: \f1b0;
$fa-var-paw-claws: \f702;
$fa-var-paw-simple: \f701;
$fa-var-paw-alt: \f701;
$fa-var-peace: \f67c;
$fa-var-peach: \e213;
$fa-var-pear: \e214;
$fa-var-pedestal: \e215;
$fa-var-pegasus: \f703;
$fa-var-pen: \f304;
$fa-var-pen-circle: \e216;
$fa-var-pen-clip: \f305;
$fa-var-pen-alt: \f305;
$fa-var-pen-clip-slash: \e217;
$fa-var-pen-alt-slash: \e217;
$fa-var-pen-fancy: \f5ac;
$fa-var-pen-fancy-slash: \e218;
$fa-var-pen-field: \e219;
$fa-var-pen-line: \e21a;
$fa-var-pen-nib: \f5ad;
$fa-var-pen-paintbrush: \f618;
$fa-var-pencil-paintbrush: \f618;
$fa-var-pen-ruler: \f5ae;
$fa-var-pencil-ruler: \f5ae;
$fa-var-pen-slash: \e21b;
$fa-var-pen-swirl: \e21c;
$fa-var-pen-to-square: \f044;
$fa-var-edit: \f044;
$fa-var-pencil: \f040;
$fa-var-pencil-alt: \f040;
$fa-var-pencil-slash: \e21d;
$fa-var-people-arrows-left-right: \e068;
$fa-var-people-arrows: \e068;
$fa-var-people-carry-box: \f4ce;
$fa-var-people-carry: \f4ce;
$fa-var-pepper-hot: \f816;
$fa-var-percent: \f295;
$fa-var-percentage: \f295;
$fa-var-person: \f183;
$fa-var-male: \f183;
$fa-var-person-biking: \f84a;
$fa-var-biking: \f84a;
$fa-var-person-biking-mountain: \f84b;
$fa-var-biking-mountain: \f84b;
$fa-var-person-booth: \f756;
$fa-var-person-carry-box: \f4cf;
$fa-var-person-carry: \f4cf;
$fa-var-person-digging: \f85e;
$fa-var-digging: \f85e;
$fa-var-person-dolly: \f4d0;
$fa-var-person-dolly-empty: \f4d1;
$fa-var-person-dots-from-line: \f470;
$fa-var-diagnoses: \f470;
$fa-var-person-dress: \f182;
$fa-var-female: \f182;
$fa-var-person-dress-simple: \e21e;
$fa-var-person-from-portal: \e023;
$fa-var-portal-exit: \e023;
$fa-var-person-hiking: \f6ec;
$fa-var-hiking: \f6ec;
$fa-var-person-pinball: \e21f;
$fa-var-person-praying: \f683;
$fa-var-pray: \f683;
$fa-var-person-running: \f70c;
$fa-var-running: \f70c;
$fa-var-person-seat: \e220;
$fa-var-person-seat-reclined: \e221;
$fa-var-person-sign: \f757;
$fa-var-person-simple: \e222;
$fa-var-person-skating: \f7c5;
$fa-var-skating: \f7c5;
$fa-var-person-ski-jumping: \f7c7;
$fa-var-ski-jump: \f7c7;
$fa-var-person-ski-lift: \f7c8;
$fa-var-ski-lift: \f7c8;
$fa-var-person-skiing: \f7c9;
$fa-var-skiing: \f7c9;
$fa-var-person-skiing-nordic: \f7ca;
$fa-var-skiing-nordic: \f7ca;
$fa-var-person-sledding: \f7cb;
$fa-var-sledding: \f7cb;
$fa-var-person-snowboarding: \f7ce;
$fa-var-snowboarding: \f7ce;
$fa-var-person-snowmobiling: \f7d1;
$fa-var-snowmobile: \f7d1;
$fa-var-person-swimming: \f5c4;
$fa-var-swimmer: \f5c4;
$fa-var-person-to-portal: \e022;
$fa-var-portal-enter: \e022;
$fa-var-person-walking: \f554;
$fa-var-walking: \f554;
$fa-var-person-walking-with-cane: \f29d;
$fa-var-blind: \f29d;
$fa-var-peseta-sign: \e223;
$fa-var-peso-sign: \e224;
$fa-var-phone: \f095;
$fa-var-phone-arrow-down-left: \e225;
$fa-var-phone-incoming: \e225;
$fa-var-phone-arrow-down: \e225;
$fa-var-phone-arrow-up-right: \e226;
$fa-var-phone-outgoing: \e226;
$fa-var-phone-arrow-up: \e226;
$fa-var-phone-flip: \f879;
$fa-var-phone-alt: \f879;
$fa-var-phone-hangup: \e227;
$fa-var-phone-missed: \e228;
$fa-var-phone-office: \f67d;
$fa-var-phone-plus: \f4d2;
$fa-var-phone-rotary: \f8d3;
$fa-var-phone-slash: \f3dd;
$fa-var-phone-volume: \f2a0;
$fa-var-volume-control-phone: \f2a0;
$fa-var-phone-xmark: \e229;
$fa-var-photo-film: \f87c;
$fa-var-photo-video: \f87c;
$fa-var-photo-film-music: \e22a;
$fa-var-pi: \f67e;
$fa-var-piano: \f8d4;
$fa-var-piano-keyboard: \f8d5;
$fa-var-pie: \f705;
$fa-var-pig: \f706;
$fa-var-piggy-bank: \f4d3;
$fa-var-pills: \f484;
$fa-var-pinball: \e22b;
$fa-var-pizza: \f817;
$fa-var-pizza-slice: \f818;
$fa-var-place-of-worship: \f67f;
$fa-var-plane: \f072;
$fa-var-plane-arrival: \f5af;
$fa-var-plane-departure: \f5b0;
$fa-var-plane-engines: \f3de;
$fa-var-plane-alt: \f3de;
$fa-var-plane-prop: \e22c;
$fa-var-plane-slash: \e069;
$fa-var-plane-tail: \e22d;
$fa-var-plane-up: \e22e;
$fa-var-plane-up-slash: \e22f;
$fa-var-planet-moon: \e01f;
$fa-var-planet-ringed: \e020;
$fa-var-play: \f04b;
$fa-var-play-pause: \e230;
$fa-var-plug: \f1e6;
$fa-var-plus: \f067;
$fa-var-add: \f067;
$fa-var-plus-minus: \e231;
$fa-var-podcast: \f2ce;
$fa-var-podium: \f680;
$fa-var-podium-star: \f758;
$fa-var-police-box: \e021;
$fa-var-poll-people: \f759;
$fa-var-poo: \f2fe;
$fa-var-poo-bolt: \f75a;
$fa-var-poo-storm: \f75a;
$fa-var-poop: \f619;
$fa-var-popcorn: \f819;
$fa-var-power-off: \f011;
$fa-var-prescription: \f5b1;
$fa-var-prescription-bottle: \f485;
$fa-var-prescription-bottle-medical: \f486;
$fa-var-prescription-bottle-alt: \f486;
$fa-var-presentation-screen: \f685;
$fa-var-presentation: \f685;
$fa-var-print: \f02f;
$fa-var-print-magnifying-glass: \f81a;
$fa-var-print-search: \f81a;
$fa-var-print-slash: \f686;
$fa-var-projector: \f8d6;
$fa-var-pump-medical: \e06a;
$fa-var-pump-soap: \e06b;
$fa-var-pumpkin: \f707;
$fa-var-puzzle-piece: \f12e;
$fa-var-puzzle-piece-simple: \e232;
$fa-var-puzzle-piece-alt: \e232;
$fa-var-q: \e233;
$fa-var-qrcode: \f029;
$fa-var-question: \f128;
$fa-var-quidditch-broom-ball: \f458;
$fa-var-broom-ball: \f458;
$fa-var-quidditch: \f458;
$fa-var-quote-left: \f10d;
$fa-var-quote-left-round: \e234;
$fa-var-quote-left-alt: \e234;
$fa-var-quote-right: \f10e;
$fa-var-quote-right-round: \e235;
$fa-var-quote-right-alt: \e235;
$fa-var-r: \e236;
$fa-var-rabbit: \f708;
$fa-var-rabbit-running: \f709;
$fa-var-rabbit-fast: \f709;
$fa-var-racquet: \f45a;
$fa-var-radar: \e024;
$fa-var-radiation: \f7b9;
$fa-var-radio: \f8d7;
$fa-var-radio-tuner: \f8d8;
$fa-var-radio-alt: \f8d8;
$fa-var-rainbow: \f75b;
$fa-var-raindrops: \f75c;
$fa-var-ram: \f70a;
$fa-var-ramp-loading: \f4d4;
$fa-var-raygun: \e025;
$fa-var-receipt: \f543;
$fa-var-record-vinyl: \f8d9;
$fa-var-rectangle: \f2fa;
$fa-var-rectangle-landscape: \f2fa;
$fa-var-rectangle-ad: \f641;
$fa-var-ad: \f641;
$fa-var-rectangle-barcode: \f463;
$fa-var-barcode-alt: \f463;
$fa-var-rectangle-list: \f022;
$fa-var-list-alt: \f022;
$fa-var-rectangle-terminal: \e237;
$fa-var-rectangle-vertical: \f2fb;
$fa-var-rectangle-portrait: \f2fb;
$fa-var-rectangle-vertical-history: \e238;
$fa-var-rectangle-wide: \f2fc;
$fa-var-rectangle-xmark: \f410;
$fa-var-times-rectangle: \f410;
$fa-var-rectangle-times: \f410;
$fa-var-window-close: \f410;
$fa-var-recycle: \f1b8;
$fa-var-refrigerator: \e026;
$fa-var-registered: \f25d;
$fa-var-repeat: \f363;
$fa-var-repeat-1: \f365;
$fa-var-reply: \f3e5;
$fa-var-mail-reply: \f3e5;
$fa-var-reply-all: \f122;
$fa-var-mail-reply-all: \f122;
$fa-var-reply-clock: \e239;
$fa-var-reply-time: \e239;
$fa-var-republican: \f75e;
$fa-var-restroom: \f7bd;
$fa-var-restroom-simple: \e23a;
$fa-var-retweet: \f079;
$fa-var-rhombus: \e23b;
$fa-var-ribbon: \f4d6;
$fa-var-right: \f356;
$fa-var-arrow-alt-right: \f356;
$fa-var-right-from-bracket: \f2f5;
$fa-var-sign-out-alt: \f2f5;
$fa-var-right-from-line: \f347;
$fa-var-arrow-alt-from-left: \f347;
$fa-var-right-left: \f362;
$fa-var-exchange-alt: \f362;
$fa-var-right-long: \f30b;
$fa-var-long-arrow-alt-right: \f30b;
$fa-var-right-to-bracket: \f2f6;
$fa-var-sign-in-alt: \f2f6;
$fa-var-right-to-line: \f34c;
$fa-var-arrow-alt-to-right: \f34c;
$fa-var-ring: \f70b;
$fa-var-rings-wedding: \f81b;
$fa-var-road: \f018;
$fa-var-robot: \f544;
$fa-var-rocket: \f135;
$fa-var-rocket-launch: \e027;
$fa-var-rotate: \f2f1;
$fa-var-sync-alt: \f2f1;
$fa-var-rotate-exclamation: \e23c;
$fa-var-rotate-forward: \f2f9;
$fa-var-redo-alt: \f2f9;
$fa-var-rotate-left: \f2ea;
$fa-var-undo-alt: \f2ea;
$fa-var-route: \f4d7;
$fa-var-route-highway: \f61a;
$fa-var-route-interstate: \f61b;
$fa-var-router: \f8da;
$fa-var-rss: \f09e;
$fa-var-feed: \f09e;
$fa-var-ruble-sign: \f158;
$fa-var-rouble: \f158;
$fa-var-rub: \f158;
$fa-var-ruble: \f158;
$fa-var-ruler: \f545;
$fa-var-ruler-combined: \f546;
$fa-var-ruler-horizontal: \f547;
$fa-var-ruler-triangle: \f61c;
$fa-var-ruler-vertical: \f548;
$fa-var-rupee-sign: \f156;
$fa-var-rupee: \f156;
$fa-var-rupiah-sign: \e23d;
$fa-var-rv: \f7be;
$fa-var-s: \e23e;
$fa-var-sack: \f81c;
$fa-var-sack-dollar: \f81d;
$fa-var-salad: \f81e;
$fa-var-bowl-salad: \f81e;
$fa-var-sandwich: \f81f;
$fa-var-satellite: \f7bf;
$fa-var-satellite-dish: \f7c0;
$fa-var-sausage: \f820;
$fa-var-saxophone: \f8dc;
$fa-var-saxophone-fire: \f8db;
$fa-var-sax-hot: \f8db;
$fa-var-scale-balanced: \f24e;
$fa-var-balance-scale: \f24e;
$fa-var-scale-unbalanced: \f515;
$fa-var-balance-scale-left: \f515;
$fa-var-scale-unbalanced-flip: \f516;
$fa-var-balance-scale-right: \f516;
$fa-var-scalpel: \f61d;
$fa-var-scalpel-line-dashed: \f61e;
$fa-var-scalpel-path: \f61e;
$fa-var-scanner: \f8f3;
$fa-var-scanner-image: \f8f3;
$fa-var-scanner-gun: \f488;
$fa-var-scanner-keyboard: \f489;
$fa-var-scanner-touchscreen: \f48a;
$fa-var-scarecrow: \f70d;
$fa-var-scarf: \f7c1;
$fa-var-school: \f549;
$fa-var-scissors: \f0c4;
$fa-var-cut: \f0c4;
$fa-var-screen-users: \f63d;
$fa-var-users-class: \f63d;
$fa-var-screencast: \e23f;
$fa-var-screwdriver: \f54a;
$fa-var-screwdriver-wrench: \f7d9;
$fa-var-tools: \f7d9;
$fa-var-scribble: \e240;
$fa-var-scroll: \f70e;
$fa-var-scroll-old: \f70f;
$fa-var-scroll-torah: \f6a0;
$fa-var-torah: \f6a0;
$fa-var-scrubber: \f2f8;
$fa-var-scythe: \f710;
$fa-var-sd-card: \f7c2;
$fa-var-sd-cards: \e241;
$fa-var-seal: \e242;
$fa-var-seal-exclamation: \e243;
$fa-var-seal-question: \e244;
$fa-var-seat-airline: \e245;
$fa-var-section: \e246;
$fa-var-seedling: \f4d8;
$fa-var-sprout: \f4d8;
$fa-var-send-back: \f87e;
$fa-var-send-backward: \f87f;
$fa-var-sensor: \e028;
$fa-var-sensor-cloud: \e02c;
$fa-var-sensor-smoke: \e02c;
$fa-var-sensor-fire: \e02a;
$fa-var-sensor-on: \e02b;
$fa-var-sensor-triangle-exclamation: \e029;
$fa-var-sensor-alert: \e029;
$fa-var-server: \f233;
$fa-var-shapes: \f61f;
$fa-var-triangle-circle-square: \f61f;
$fa-var-share: \f064;
$fa-var-arrow-turn-right: \f064;
$fa-var-mail-forward: \f064;
$fa-var-share-all: \f367;
$fa-var-arrows-turn-right: \f367;
$fa-var-share-from-square: \f14d;
$fa-var-share-square: \f14d;
$fa-var-share-nodes: \f1e0;
$fa-var-share-alt: \f1e0;
$fa-var-sheep: \f711;
$fa-var-shekel-sign: \f20b;
$fa-var-sheqel-sign: \f20b;
$fa-var-shekel: \f20b;
$fa-var-sheqel: \f20b;
$fa-var-ils: \f20b;
$fa-var-shelves: \f480;
$fa-var-inventory: \f480;
$fa-var-shelves-empty: \e247;
$fa-var-shield: \f132;
$fa-var-shield-blank: \f3ed;
$fa-var-shield-alt: \f3ed;
$fa-var-shield-check: \f2f7;
$fa-var-shield-cross: \f712;
$fa-var-shield-exclamation: \e248;
$fa-var-shield-keyhole: \e249;
$fa-var-shield-minus: \e24a;
$fa-var-shield-plus: \e24b;
$fa-var-shield-slash: \e24c;
$fa-var-shield-virus: \e06c;
$fa-var-shield-xmark: \e24d;
$fa-var-shield-times: \e24d;
$fa-var-ship: \f21a;
$fa-var-shish-kebab: \f821;
$fa-var-shoe-prints: \f54b;
$fa-var-shop: \f54f;
$fa-var-store-alt: \f54f;
$fa-var-shop-slash: \e070;
$fa-var-store-alt-slash: \e070;
$fa-var-shovel: \f713;
$fa-var-shovel-snow: \f7c3;
$fa-var-shower: \f2cc;
$fa-var-shower-down: \e24e;
$fa-var-shower-alt: \e24e;
$fa-var-shredder: \f68a;
$fa-var-shuffle: \f074;
$fa-var-random: \f074;
$fa-var-shuttle-space: \f197;
$fa-var-space-shuttle: \f197;
$fa-var-shuttlecock: \f45b;
$fa-var-sickle: \f822;
$fa-var-sidebar: \e24f;
$fa-var-sidebar-flip: \e250;
$fa-var-sigma: \f68b;
$fa-var-sign-hanging: \f4d9;
$fa-var-sign: \f4d9;
$fa-var-signal: \f012;
$fa-var-signal-perfect: \f012;
$fa-var-signal-5: \f012;
$fa-var-signal-bars: \f690;
$fa-var-signal-bars-strong: \f690;
$fa-var-signal-alt: \f690;
$fa-var-signal-alt-4: \f690;
$fa-var-signal-bars-fair: \f692;
$fa-var-signal-alt-2: \f692;
$fa-var-signal-bars-good: \f693;
$fa-var-signal-alt-3: \f693;
$fa-var-signal-bars-slash: \f694;
$fa-var-signal-alt-slash: \f694;
$fa-var-signal-bars-weak: \f691;
$fa-var-signal-alt-1: \f691;
$fa-var-signal-fair: \f68d;
$fa-var-signal-2: \f68d;
$fa-var-signal-good: \f68e;
$fa-var-signal-3: \f68e;
$fa-var-signal-slash: \f695;
$fa-var-signal-stream: \f8dd;
$fa-var-signal-stream-slash: \e251;
$fa-var-signal-strong: \f68f;
$fa-var-signal-4: \f68f;
$fa-var-signal-weak: \f68c;
$fa-var-signal-1: \f68c;
$fa-var-signature: \f5b7;
$fa-var-signs-post: \f277;
$fa-var-map-signs: \f277;
$fa-var-sim-card: \f7c4;
$fa-var-sim-cards: \e252;
$fa-var-sink: \e06d;
$fa-var-siren: \e02d;
$fa-var-siren-on: \e02e;
$fa-var-sitemap: \f0e8;
$fa-var-skeleton: \f620;
$fa-var-skull: \f54c;
$fa-var-skull-cow: \f8de;
$fa-var-skull-crossbones: \f714;
$fa-var-slash: \f715;
$fa-var-sleigh: \f7cc;
$fa-var-slider: \e253;
$fa-var-sliders: \f1de;
$fa-var-sliders-h: \f1de;
$fa-var-sliders-up: \f3f1;
$fa-var-sliders-v: \f3f1;
$fa-var-smog: \f75f;
$fa-var-smoke: \f760;
$fa-var-smoking: \f48d;
$fa-var-snake: \f716;
$fa-var-snooze: \f880;
$fa-var-zzz: \f880;
$fa-var-snow-blowing: \f761;
$fa-var-snowflake: \f2dc;
$fa-var-snowflakes: \f7cf;
$fa-var-snowman: \f7d0;
$fa-var-snowman-head: \f79b;
$fa-var-frosty-head: \f79b;
$fa-var-snowplow: \f7d2;
$fa-var-soap: \e06e;
$fa-var-socks: \f696;
$fa-var-solar-panel: \f5ba;
$fa-var-solar-system: \e02f;
$fa-var-sort: \f0dc;
$fa-var-unsorted: \f0dc;
$fa-var-sort-down: \f0dd;
$fa-var-sort-desc: \f0dd;
$fa-var-sort-up: \f0de;
$fa-var-sort-asc: \f0de;
$fa-var-spa: \f5bb;
$fa-var-space-station-moon: \e033;
$fa-var-space-station-moon-construction: \e034;
$fa-var-space-station-moon-alt: \e034;
$fa-var-spade: \f2f4;
$fa-var-spaghetti-monster-flying: \f67b;
$fa-var-pastafarianism: \f67b;
$fa-var-sparkles: \f890;
$fa-var-speaker: \f8df;
$fa-var-speakers: \f8e0;
$fa-var-spell-check: \f891;
$fa-var-spider: \f717;
$fa-var-spider-black-widow: \f718;
$fa-var-spider-web: \f719;
$fa-var-spinner: \f110;
$fa-var-spinner-third: \f3f4;
$fa-var-split: \e254;
$fa-var-splotch: \f5bc;
$fa-var-spoon: \f2e5;
$fa-var-utensil-spoon: \f2e5;
$fa-var-spray-can: \f5bd;
$fa-var-sprinkler: \e035;
$fa-var-square: \f0c8;
$fa-var-square-0: \e255;
$fa-var-square-1: \e256;
$fa-var-square-2: \e257;
$fa-var-square-3: \e258;
$fa-var-square-4: \e259;
$fa-var-square-5: \e25a;
$fa-var-square-6: \e25b;
$fa-var-square-7: \e25c;
$fa-var-square-8: \e25d;
$fa-var-square-9: \e25e;
$fa-var-square-a: \e25f;
$fa-var-square-ampersand: \e260;
$fa-var-square-arrow-down: \f339;
$fa-var-arrow-square-down: \f339;
$fa-var-square-arrow-down-left: \e261;
$fa-var-square-arrow-down-right: \e262;
$fa-var-square-arrow-left: \f33a;
$fa-var-arrow-square-left: \f33a;
$fa-var-square-arrow-right: \f33b;
$fa-var-arrow-square-right: \f33b;
$fa-var-square-arrow-up: \f33c;
$fa-var-arrow-square-up: \f33c;
$fa-var-square-arrow-up-left: \e263;
$fa-var-square-arrow-up-right: \f14c;
$fa-var-external-link-square: \f14c;
$fa-var-square-b: \e264;
$fa-var-square-bolt: \e265;
$fa-var-square-c: \e266;
$fa-var-square-caret-down: \f150;
$fa-var-caret-square-down: \f150;
$fa-var-square-caret-left: \f191;
$fa-var-caret-square-left: \f191;
$fa-var-square-caret-right: \f152;
$fa-var-caret-square-right: \f152;
$fa-var-square-caret-up: \f151;
$fa-var-caret-square-up: \f151;
$fa-var-square-check: \f14a;
$fa-var-check-square: \f14a;
$fa-var-square-chevron-down: \f329;
$fa-var-chevron-square-down: \f329;
$fa-var-square-chevron-left: \f32a;
$fa-var-chevron-square-left: \f32a;
$fa-var-square-chevron-right: \f32b;
$fa-var-chevron-square-right: \f32b;
$fa-var-square-chevron-up: \f32c;
$fa-var-chevron-square-up: \f32c;
$fa-var-square-code: \e267;
$fa-var-square-d: \e268;
$fa-var-square-dashed: \e269;
$fa-var-square-divide: \e26a;
$fa-var-square-dollar: \f2e9;
$fa-var-usd-square: \f2e9;
$fa-var-dollar-square: \f2e9;
$fa-var-square-down: \f350;
$fa-var-arrow-alt-square-down: \f350;
$fa-var-square-down-left: \e26b;
$fa-var-square-down-right: \e26c;
$fa-var-square-e: \e26d;
$fa-var-square-envelope: \f199;
$fa-var-envelope-square: \f199;
$fa-var-square-exclamation: \f321;
$fa-var-exclamation-square: \f321;
$fa-var-square-f: \e26e;
$fa-var-square-fragile: \f49b;
$fa-var-box-fragile: \f49b;
$fa-var-square-wine-glass-crack: \f49b;
$fa-var-square-full: \f45c;
$fa-var-square-g: \e26f;
$fa-var-square-h: \f0fd;
$fa-var-h-square: \f0fd;
$fa-var-square-heart: \f4c8;
$fa-var-heart-square: \f4c8;
$fa-var-square-i: \e270;
$fa-var-square-info: \f30f;
$fa-var-info-square: \f30f;
$fa-var-square-j: \e271;
$fa-var-square-k: \e272;
$fa-var-square-l: \e273;
$fa-var-square-left: \f351;
$fa-var-arrow-alt-square-left: \f351;
$fa-var-square-m: \e274;
$fa-var-square-minus: \f146;
$fa-var-minus-square: \f146;
$fa-var-square-n: \e275;
$fa-var-square-o: \e276;
$fa-var-square-p: \e277;
$fa-var-square-parking: \f540;
$fa-var-parking: \f540;
$fa-var-square-parking-slash: \f617;
$fa-var-parking-slash: \f617;
$fa-var-square-pen: \f14b;
$fa-var-pen-square: \f14b;
$fa-var-pencil-square: \f14b;
$fa-var-square-phone: \f098;
$fa-var-phone-square: \f098;
$fa-var-square-phone-flip: \f87b;
$fa-var-phone-square-alt: \f87b;
$fa-var-square-phone-hangup: \e278;
$fa-var-phone-square-down: \e278;
$fa-var-square-plus: \f0fe;
$fa-var-plus-square: \f0fe;
$fa-var-square-poll-horizontal: \f682;
$fa-var-poll-h: \f682;
$fa-var-square-poll-vertical: \f681;
$fa-var-poll: \f681;
$fa-var-square-q: \e279;
$fa-var-square-question: \f2fd;
$fa-var-question-square: \f2fd;
$fa-var-square-r: \e27a;
$fa-var-square-right: \f352;
$fa-var-arrow-alt-square-right: \f352;
$fa-var-square-root: \f697;
$fa-var-square-root-variable: \f698;
$fa-var-square-root-alt: \f698;
$fa-var-square-rss: \f143;
$fa-var-rss-square: \f143;
$fa-var-square-s: \e27b;
$fa-var-square-share-nodes: \f1e1;
$fa-var-share-alt-square: \f1e1;
$fa-var-square-sliders: \f3f0;
$fa-var-sliders-h-square: \f3f0;
$fa-var-square-sliders-vertical: \f3f2;
$fa-var-sliders-v-square: \f3f2;
$fa-var-square-small: \e27c;
$fa-var-square-star: \e27d;
$fa-var-square-t: \e27e;
$fa-var-square-this-way-up: \f49f;
$fa-var-box-up: \f49f;
$fa-var-square-u: \e27f;
$fa-var-square-up: \f353;
$fa-var-arrow-alt-square-up: \f353;
$fa-var-square-up-left: \e280;
$fa-var-square-up-right: \f360;
$fa-var-external-link-square-alt: \f360;
$fa-var-square-user: \e281;
$fa-var-square-v: \e282;
$fa-var-square-w: \e283;
$fa-var-square-x: \e284;
$fa-var-square-xmark: \f2d3;
$fa-var-times-square: \f2d3;
$fa-var-xmark-square: \f2d3;
$fa-var-square-y: \e285;
$fa-var-square-z: \e286;
$fa-var-squirrel: \f71a;
$fa-var-staff: \f71b;
$fa-var-stairs: \e287;
$fa-var-stamp: \f5bf;
$fa-var-standard-definition: \e288;
$fa-var-star: \f005;
$fa-var-star-and-crescent: \f699;
$fa-var-star-christmas: \f7d4;
$fa-var-star-exclamation: \f2f3;
$fa-var-star-half: \f089;
$fa-var-star-half-stroke: \f5c0;
$fa-var-star-half-alt: \f5c0;
$fa-var-star-of-david: \f69a;
$fa-var-star-of-life: \f621;
$fa-var-star-sharp: \e289;
$fa-var-star-sharp-half: \e28a;
$fa-var-star-sharp-half-stroke: \e28b;
$fa-var-star-sharp-half-alt: \e28b;
$fa-var-star-shooting: \e036;
$fa-var-starfighter: \e037;
$fa-var-starfighter-twin-ion-engine: \e038;
$fa-var-starfighter-alt: \e038;
$fa-var-starfighter-twin-ion-engine-advanced: \e28c;
$fa-var-starfighter-alt-advanced: \e28c;
$fa-var-stars: \f762;
$fa-var-starship: \e039;
$fa-var-starship-freighter: \e03a;
$fa-var-steak: \f824;
$fa-var-steering-wheel: \f622;
$fa-var-sterling-sign: \f154;
$fa-var-pound-sign: \f154;
$fa-var-gbp: \f154;
$fa-var-stethoscope: \f0f1;
$fa-var-stocking: \f7d5;
$fa-var-stomach: \f623;
$fa-var-stop: \f04d;
$fa-var-stopwatch: \f2f2;
$fa-var-stopwatch-20: \e06f;
$fa-var-store: \f54e;
$fa-var-store-slash: \e071;
$fa-var-street-view: \f21d;
$fa-var-stretcher: \f825;
$fa-var-strikethrough: \f0cc;
$fa-var-stroopwafel: \f551;
$fa-var-subscript: \f12c;
$fa-var-suitcase: \f0f2;
$fa-var-suitcase-medical: \f0fa;
$fa-var-medkit: \f0fa;
$fa-var-suitcase-rolling: \f5c1;
$fa-var-sun: \f185;
$fa-var-sun-bright: \e28d;
$fa-var-sun-alt: \e28d;
$fa-var-sun-cloud: \f763;
$fa-var-sun-dust: \f764;
$fa-var-sun-haze: \f765;
$fa-var-sunglasses: \f892;
$fa-var-sunrise: \f766;
$fa-var-sunset: \f767;
$fa-var-superscript: \f12b;
$fa-var-swatchbook: \f5c3;
$fa-var-sword: \f71c;
$fa-var-sword-laser: \e03b;
$fa-var-sword-laser-alt: \e03c;
$fa-var-swords: \f71d;
$fa-var-swords-laser: \e03d;
$fa-var-symbols: \f86e;
$fa-var-icons-alt: \f86e;
$fa-var-synagogue: \f69b;
$fa-var-syringe: \f48e;
$fa-var-t: \e28e;
$fa-var-table: \f0ce;
$fa-var-table-cells: \f00a;
$fa-var-th: \f00a;
$fa-var-table-cells-large: \f009;
$fa-var-th-large: \f009;
$fa-var-table-columns: \f0db;
$fa-var-columns: \f0db;
$fa-var-table-layout: \e28f;
$fa-var-table-list: \f00b;
$fa-var-th-list: \f00b;
$fa-var-table-pivot: \e290;
$fa-var-table-rows: \e291;
$fa-var-rows: \e291;
$fa-var-table-tennis-paddle-ball: \f45d;
$fa-var-ping-pong-paddle-ball: \f45d;
$fa-var-table-tennis: \f45d;
$fa-var-table-tree: \e292;
$fa-var-tablet: \f3fb;
$fa-var-tablet-android: \f3fb;
$fa-var-tablet-button: \f10a;
$fa-var-tablet-rugged: \f48f;
$fa-var-tablet-screen: \f3fc;
$fa-var-tablet-android-alt: \f3fc;
$fa-var-tablet-screen-button: \f3fa;
$fa-var-tablet-alt: \f3fa;
$fa-var-tablets: \f490;
$fa-var-tachograph-digital: \f566;
$fa-var-digital-tachograph: \f566;
$fa-var-taco: \f826;
$fa-var-tag: \f02b;
$fa-var-tags: \f02c;
$fa-var-tally: \f69c;
$fa-var-tally-5: \f69c;
$fa-var-tally-1: \e293;
$fa-var-tally-2: \e294;
$fa-var-tally-3: \e295;
$fa-var-tally-4: \e296;
$fa-var-tape: \f4db;
$fa-var-taxi: \f1ba;
$fa-var-cab: \f1ba;
$fa-var-taxi-bus: \e297;
$fa-var-teeth: \f62e;
$fa-var-teeth-open: \f62f;
$fa-var-telescope: \e03e;
$fa-var-temperature-arrow-down: \e03f;
$fa-var-temperature-down: \e03f;
$fa-var-temperature-arrow-up: \e040;
$fa-var-temperature-up: \e040;
$fa-var-temperature-empty: \f2cb;
$fa-var-thermometer-empty: \f2cb;
$fa-var-thermometer-0: \f2cb;
$fa-var-temperature-0: \f2cb;
$fa-var-temperature-full: \f2c7;
$fa-var-thermometer-full: \f2c7;
$fa-var-thermometer-4: \f2c7;
$fa-var-temperature-4: \f2c7;
$fa-var-temperature-half: \f2c9;
$fa-var-thermometer-half: \f2c9;
$fa-var-thermometer-2: \f2c9;
$fa-var-temperature-2: \f2c9;
$fa-var-temperature-high: \f769;
$fa-var-temperature-list: \e298;
$fa-var-temperature-low: \f76b;
$fa-var-temperature-quarter: \f2ca;
$fa-var-thermometer-quarter: \f2ca;
$fa-var-thermometer-1: \f2ca;
$fa-var-temperature-1: \f2ca;
$fa-var-temperature-snow: \f768;
$fa-var-temperature-frigid: \f768;
$fa-var-temperature-sun: \f76a;
$fa-var-temperature-hot: \f76a;
$fa-var-temperature-three-quarters: \f2c8;
$fa-var-thermometer-three-quarters: \f2c8;
$fa-var-thermometer-3: \f2c8;
$fa-var-temperature-3: \f2c8;
$fa-var-tenge-sign: \f7d7;
$fa-var-tenge: \f7d7;
$fa-var-tennis-ball: \f45e;
$fa-var-terminal: \f120;
$fa-var-text: \f893;
$fa-var-text-height: \f034;
$fa-var-text-size: \f894;
$fa-var-text-slash: \f87d;
$fa-var-remove-format: \f87d;
$fa-var-text-width: \f035;
$fa-var-thermometer: \f491;
$fa-var-theta: \f69e;
$fa-var-thumbs-down: \f165;
$fa-var-thumbs-up: \f164;
$fa-var-thumbtack: \f08d;
$fa-var-thumb-tack: \f08d;
$fa-var-ticket: \f145;
$fa-var-ticket-airline: \e299;
$fa-var-ticket-simple: \f3ff;
$fa-var-ticket-alt: \f3ff;
$fa-var-tickets-airline: \e29a;
$fa-var-tilde: \f69f;
$fa-var-timeline: \e29b;
$fa-var-timeline-arrow: \e29c;
$fa-var-timer: \e29d;
$fa-var-tire: \f631;
$fa-var-tire-flat: \f632;
$fa-var-tire-pressure-warning: \f633;
$fa-var-tire-rugged: \f634;
$fa-var-toggle-off: \f204;
$fa-var-toggle-on: \f205;
$fa-var-toilet: \f7d8;
$fa-var-toilet-paper: \f71e;
$fa-var-toilet-paper-blank: \f71f;
$fa-var-toilet-paper-alt: \f71f;
$fa-var-toilet-paper-blank-under: \e29e;
$fa-var-toilet-paper-reverse-alt: \e29e;
$fa-var-toilet-paper-slash: \e072;
$fa-var-toilet-paper-under: \e29f;
$fa-var-toilet-paper-reverse: \e29f;
$fa-var-toilet-paper-under-slash: \e2a0;
$fa-var-toilet-paper-reverse-slash: \e2a0;
$fa-var-tombstone: \f720;
$fa-var-tombstone-blank: \f721;
$fa-var-tombstone-alt: \f721;
$fa-var-toolbox: \f552;
$fa-var-tooth: \f5c9;
$fa-var-toothbrush: \f635;
$fa-var-torii-gate: \f6a1;
$fa-var-tornado: \f76f;
$fa-var-tower-broadcast: \f519;
$fa-var-broadcast-tower: \f519;
$fa-var-tower-control: \e2a1;
$fa-var-tractor: \f722;
$fa-var-trademark: \f25c;
$fa-var-traffic-cone: \f636;
$fa-var-traffic-light: \f637;
$fa-var-traffic-light-go: \f638;
$fa-var-traffic-light-slow: \f639;
$fa-var-traffic-light-stop: \f63a;
$fa-var-trailer: \e041;
$fa-var-train: \f238;
$fa-var-train-subway: \f239;
$fa-var-subway: \f239;
$fa-var-train-subway-tunnel: \e2a2;
$fa-var-subway-tunnel: \e2a2;
$fa-var-train-tram: \f7da;
$fa-var-tram: \f7da;
$fa-var-transformer-bolt: \e2a3;
$fa-var-transgender: \f224;
$fa-var-transgender-alt: \f225;
$fa-var-transporter: \e042;
$fa-var-transporter-1: \e043;
$fa-var-transporter-2: \e044;
$fa-var-transporter-3: \e045;
$fa-var-transporter-4: \e2a4;
$fa-var-transporter-5: \e2a5;
$fa-var-transporter-6: \e2a6;
$fa-var-transporter-7: \e2a7;
$fa-var-transporter-empty: \e046;
$fa-var-trash: \f1f8;
$fa-var-trash-arrow-up: \f829;
$fa-var-trash-restore: \f829;
$fa-var-trash-can: \f2ed;
$fa-var-trash-alt: \f2ed;
$fa-var-trash-can-arrow-up: \f82a;
$fa-var-trash-restore-alt: \f82a;
$fa-var-trash-can-clock: \e2a8;
$fa-var-trash-can-list: \e2a9;
$fa-var-trash-can-slash: \e2aa;
$fa-var-trash-alt-slash: \e2aa;
$fa-var-trash-can-undo: \f896;
$fa-var-trash-can-arrow-turn-left: \f896;
$fa-var-trash-undo-alt: \f896;
$fa-var-trash-clock: \e2ab;
$fa-var-trash-list: \e2ac;
$fa-var-trash-slash: \e2ad;
$fa-var-trash-undo: \f895;
$fa-var-trash-arrow-turn-left: \f895;
$fa-var-treasure-chest: \f723;
$fa-var-tree: \f1bb;
$fa-var-tree-christmas: \f7db;
$fa-var-tree-deciduous: \f400;
$fa-var-tree-alt: \f400;
$fa-var-tree-decorated: \f7dc;
$fa-var-tree-large: \f7dd;
$fa-var-tree-palm: \f82b;
$fa-var-trees: \f724;
$fa-var-triangle: \f2ec;
$fa-var-triangle-exclamation: \f071;
$fa-var-exclamation-triangle: \f071;
$fa-var-warning: \f071;
$fa-var-triangle-instrument: \f8e2;
$fa-var-triangle-music: \f8e2;
$fa-var-triangle-person-digging: \f85d;
$fa-var-construction: \f85d;
$fa-var-trophy: \f091;
$fa-var-trophy-star: \f2eb;
$fa-var-trophy-alt: \f2eb;
$fa-var-truck: \f0d1;
$fa-var-truck-clock: \f48c;
$fa-var-shipping-timed: \f48c;
$fa-var-truck-container: \f4dc;
$fa-var-truck-container-empty: \e2ae;
$fa-var-truck-fast: \f48b;
$fa-var-shipping-fast: \f48b;
$fa-var-truck-flatbed: \e2af;
$fa-var-truck-front: \e2b0;
$fa-var-truck-medical: \f0f9;
$fa-var-ambulance: \f0f9;
$fa-var-truck-monster: \f63b;
$fa-var-truck-moving: \f4df;
$fa-var-truck-pickup: \f63c;
$fa-var-truck-plow: \f7de;
$fa-var-truck-ramp: \f4e0;
$fa-var-truck-ramp-box: \f4de;
$fa-var-truck-loading: \f4de;
$fa-var-truck-ramp-couch: \f4dd;
$fa-var-truck-couch: \f4dd;
$fa-var-truck-tow: \e2b1;
$fa-var-trumpet: \f8e3;
$fa-var-tshirt: \f553;
$fa-var-tty: \f1e4;
$fa-var-teletype: \f1e4;
$fa-var-tty-answer: \e2b2;
$fa-var-teletype-answer: \e2b2;
$fa-var-tugrik-sign: \e2b3;
$fa-var-turkey: \f725;
$fa-var-turkish-lira-sign: \e2b4;
$fa-var-try: \e2b4;
$fa-var-turkish-lira: \e2b4;
$fa-var-turn-down: \f3be;
$fa-var-level-down-alt: \f3be;
$fa-var-turn-up: \f3bf;
$fa-var-level-up-alt: \f3bf;
$fa-var-turntable: \f8e4;
$fa-var-turtle: \f726;
$fa-var-tv: \f26c;
$fa-var-tv-alt: \f26c;
$fa-var-television: \f26c;
$fa-var-tv-music: \f8e6;
$fa-var-tv-retro: \f401;
$fa-var-typewriter: \f8e7;
$fa-var-u: \e2b5;
$fa-var-ufo: \e047;
$fa-var-ufo-beam: \e048;
$fa-var-umbrella: \f0e9;
$fa-var-umbrella-beach: \f5ca;
$fa-var-umbrella-simple: \e2b6;
$fa-var-umbrella-alt: \e2b6;
$fa-var-underline: \f0cd;
$fa-var-unicorn: \f727;
$fa-var-union: \f6a2;
$fa-var-universal-access: \f29a;
$fa-var-unlock: \f09c;
$fa-var-unlock-keyhole: \f13e;
$fa-var-unlock-alt: \f13e;
$fa-var-up: \f357;
$fa-var-arrow-alt-up: \f357;
$fa-var-up-down: \f338;
$fa-var-arrows-alt-v: \f338;
$fa-var-up-down-left-right: \f0b2;
$fa-var-arrows-alt: \f0b2;
$fa-var-up-from-line: \f346;
$fa-var-arrow-alt-from-bottom: \f346;
$fa-var-up-left: \e2b7;
$fa-var-up-long: \f30c;
$fa-var-long-arrow-alt-up: \f30c;
$fa-var-up-right: \e2b8;
$fa-var-up-right-and-down-left-from-center: \f424;
$fa-var-expand-alt: \f424;
$fa-var-up-right-from-square: \f35d;
$fa-var-external-link-alt: \f35d;
$fa-var-up-to-line: \f34d;
$fa-var-arrow-alt-to-top: \f34d;
$fa-var-upload: \f093;
$fa-var-usb-drive: \f8e9;
$fa-var-user: \f007;
$fa-var-user-alien: \e04a;
$fa-var-user-astronaut: \f4fb;
$fa-var-user-bounty-hunter: \e2b9;
$fa-var-user-check: \f4fc;
$fa-var-user-clock: \f4fd;
$fa-var-user-cowboy: \f8ea;
$fa-var-user-crown: \f6a4;
$fa-var-user-doctor: \f0f0;
$fa-var-user-md: \f0f0;
$fa-var-user-doctor-message: \f82e;
$fa-var-user-md-chat: \f82e;
$fa-var-user-gear: \f4fe;
$fa-var-user-cog: \f4fe;
$fa-var-user-graduate: \f501;
$fa-var-user-group: \f500;
$fa-var-user-friends: \f500;
$fa-var-user-group-crown: \f6a5;
$fa-var-users-crown: \f6a5;
$fa-var-user-headset: \f82d;
$fa-var-user-helmet-safety: \f82c;
$fa-var-user-construction: \f82c;
$fa-var-user-hard-hat: \f82c;
$fa-var-user-injured: \f728;
$fa-var-user-large: \f406;
$fa-var-user-alt: \f406;
$fa-var-user-large-slash: \f4fa;
$fa-var-user-alt-slash: \f4fa;
$fa-var-user-lock: \f502;
$fa-var-user-minus: \f503;
$fa-var-user-music: \f8eb;
$fa-var-user-ninja: \f504;
$fa-var-user-nurse: \f82f;
$fa-var-user-pen: \f4ff;
$fa-var-user-edit: \f4ff;
$fa-var-user-pilot: \e2ba;
$fa-var-user-pilot-tie: \e2bb;
$fa-var-user-plus: \f234;
$fa-var-user-robot: \e04b;
$fa-var-user-secret: \f21b;
$fa-var-user-shakespeare: \e2bc;
$fa-var-user-shield: \f505;
$fa-var-user-slash: \f506;
$fa-var-user-tag: \f507;
$fa-var-user-tie: \f508;
$fa-var-user-unlock: \e058;
$fa-var-user-visor: \e04c;
$fa-var-user-xmark: \f235;
$fa-var-user-times: \f235;
$fa-var-users: \f0c0;
$fa-var-group: \f0c0;
$fa-var-users-gear: \f509;
$fa-var-users-cog: \f509;
$fa-var-users-medical: \f830;
$fa-var-users-slash: \e073;
$fa-var-utensils: \f2e7;
$fa-var-cutlery: \f2e7;
$fa-var-utility-pole: \e2bd;
$fa-var-utility-pole-double: \e2be;
$fa-var-v: \e2bf;
$fa-var-vacuum: \e04d;
$fa-var-vacuum-robot: \e04e;
$fa-var-value-absolute: \f6a6;
$fa-var-van-shuttle: \f5b6;
$fa-var-shuttle-van: \f5b6;
$fa-var-vault: \e2c0;
$fa-var-vector-circle: \e2c1;
$fa-var-vector-polygon: \e2c2;
$fa-var-vector-square: \f5cb;
$fa-var-venus: \f221;
$fa-var-venus-double: \f226;
$fa-var-venus-mars: \f228;
$fa-var-vest: \e085;
$fa-var-vest-patches: \e086;
$fa-var-vial: \f492;
$fa-var-vials: \f493;
$fa-var-video: \f03d;
$fa-var-video-camera: \f03d;
$fa-var-video-arrow-down-left: \e2c3;
$fa-var-video-arrow-up-right: \e2c4;
$fa-var-video-plus: \f4e1;
$fa-var-video-slash: \f4e2;
$fa-var-vihara: \f6a7;
$fa-var-violin: \f8ed;
$fa-var-virus: \e074;
$fa-var-virus-slash: \e075;
$fa-var-viruses: \e076;
$fa-var-voicemail: \f897;
$fa-var-volcano: \f770;
$fa-var-volleyball-ball: \f45f;
$fa-var-volume: \f6a8;
$fa-var-volume-medium: \f6a8;
$fa-var-volume-high: \f028;
$fa-var-volume-up: \f028;
$fa-var-volume-low: \f027;
$fa-var-volume-down: \f027;
$fa-var-volume-off: \f026;
$fa-var-volume-slash: \f2e2;
$fa-var-volume-xmark: \f6a9;
$fa-var-volume-times: \f6a9;
$fa-var-volume-mute: \f6a9;
$fa-var-vr-cardboard: \f729;
$fa-var-w: \e2c5;
$fa-var-wagon-covered: \f8ee;
$fa-var-walker: \f831;
$fa-var-walkie-talkie: \f8ef;
$fa-var-wallet: \f555;
$fa-var-wand: \f72a;
$fa-var-wand-magic: \f0d0;
$fa-var-magic: \f0d0;
$fa-var-wand-magic-sparkles: \e2c6;
$fa-var-magic-wand-sparkles: \e2c6;
$fa-var-wand-sparkles: \f72b;
$fa-var-warehouse: \f494;
$fa-var-warehouse-full: \f495;
$fa-var-warehouse-alt: \f495;
$fa-var-washing-machine: \f898;
$fa-var-washer: \f898;
$fa-var-watch: \f2e1;
$fa-var-watch-apple: \e2c7;
$fa-var-watch-calculator: \f8f0;
$fa-var-watch-fitness: \f63e;
$fa-var-watch-smart: \e2c8;
$fa-var-water: \f773;
$fa-var-water-arrow-down: \f774;
$fa-var-water-lower: \f774;
$fa-var-water-arrow-up: \f775;
$fa-var-water-rise: \f775;
$fa-var-water-ladder: \f5c5;
$fa-var-swimming-pool: \f5c5;
$fa-var-ladder-water: \f5c5;
$fa-var-wave-pulse: \f5f8;
$fa-var-heart-rate: \f5f8;
$fa-var-wave-sine: \f899;
$fa-var-wave-square: \f83e;
$fa-var-wave-triangle: \f89a;
$fa-var-waveform: \f8f1;
$fa-var-waveform-lines: \f8f2;
$fa-var-weight-hanging: \f5cd;
$fa-var-weight-scale: \f496;
$fa-var-weight: \f496;
$fa-var-whale: \f72c;
$fa-var-wheat: \f72d;
$fa-var-wheat-awn: \e2c9;
$fa-var-wheat-alt: \e2c9;
$fa-var-wheelchair: \f193;
$fa-var-wheelchair-move: \e2ca;
$fa-var-wheelchair-alt: \e2ca;
$fa-var-whiskey-glass: \f7a0;
$fa-var-glass-whiskey: \f7a0;
$fa-var-whiskey-glass-ice: \f7a1;
$fa-var-glass-whiskey-rocks: \f7a1;
$fa-var-whistle: \f460;
$fa-var-wifi: \f1eb;
$fa-var-wifi-strong: \f1eb;
$fa-var-wifi-3: \f1eb;
$fa-var-wifi-exclamation: \e2cb;
$fa-var-wifi-fair: \f6ab;
$fa-var-wifi-2: \f6ab;
$fa-var-wifi-slash: \f6ac;
$fa-var-wifi-weak: \f6aa;
$fa-var-wifi-1: \f6aa;
$fa-var-wind: \f72e;
$fa-var-wind-turbine: \f89b;
$fa-var-wind-warning: \f776;
$fa-var-wind-circle-exclamation: \f776;
$fa-var-window: \f40e;
$fa-var-window-flip: \f40f;
$fa-var-window-alt: \f40f;
$fa-var-window-frame: \e04f;
$fa-var-window-frame-open: \e050;
$fa-var-window-maximize: \f2d0;
$fa-var-window-minimize: \f2d1;
$fa-var-window-restore: \f2d2;
$fa-var-windsock: \f777;
$fa-var-wine-bottle: \f72f;
$fa-var-wine-glass: \f4e3;
$fa-var-wine-glass-crack: \f4bb;
$fa-var-fragile: \f4bb;
$fa-var-wine-glass-empty: \f5ce;
$fa-var-wine-glass-alt: \f5ce;
$fa-var-won-sign: \f159;
$fa-var-won: \f159;
$fa-var-krw: \f159;
$fa-var-wreath: \f7e2;
$fa-var-wrench: \f0ad;
$fa-var-wrench-simple: \e2cc;
$fa-var-x: \e2cd;
$fa-var-x-ray: \f497;
$fa-var-xmark: \f00d;
$fa-var-multiply: \f00d;
$fa-var-times: \f00d;
$fa-var-close: \f00d;
$fa-var-remove: \f00d;
$fa-var-xmark-to-slot: \f771;
$fa-var-times-to-slot: \f771;
$fa-var-vote-nay: \f771;
$fa-var-y: \e2ce;
$fa-var-yen-sign: \f157;
$fa-var-yen: \f157;
$fa-var-cny: \f157;
$fa-var-jpy: \f157;
$fa-var-rmb: \f157;
$fa-var-yin-yang: \f6ad;
$fa-var-z: \e2cf;

$fa-var-500px: \f26e;
$fa-var-accessible-icon: \f368;
$fa-var-accusoft: \f369;
$fa-var-acquisitions-incorporated: \f6af;
$fa-var-adn: \f170;
$fa-var-adversal: \f36a;
$fa-var-affiliatetheme: \f36b;
$fa-var-airbnb: \f834;
$fa-var-algolia: \f36c;
$fa-var-alipay: \f642;
$fa-var-amazon: \f270;
$fa-var-amazon-pay: \f42c;
$fa-var-amilia: \f36d;
$fa-var-android: \f17b;
$fa-var-angellist: \f209;
$fa-var-angrycreative: \f36e;
$fa-var-angular: \f420;
$fa-var-app-store: \f36f;
$fa-var-app-store-ios: \f370;
$fa-var-apper: \f371;
$fa-var-apple: \f179;
$fa-var-apple-pay: \f415;
$fa-var-artstation: \f77a;
$fa-var-asymmetrik: \f372;
$fa-var-atlassian: \f77b;
$fa-var-audible: \f373;
$fa-var-autoprefixer: \f41c;
$fa-var-avianex: \f374;
$fa-var-aviato: \f421;
$fa-var-aws: \f375;
$fa-var-bandcamp: \f2d5;
$fa-var-battle-net: \f835;
$fa-var-behance: \f1b4;
$fa-var-behance-square: \f1b5;
$fa-var-bimobject: \f378;
$fa-var-bitbucket: \f171;
$fa-var-bitcoin: \f379;
$fa-var-bity: \f37a;
$fa-var-black-tie: \f27e;
$fa-var-blackberry: \f37b;
$fa-var-blogger: \f37c;
$fa-var-blogger-b: \f37d;
$fa-var-bluetooth: \f293;
$fa-var-bluetooth-b: \f294;
$fa-var-bootstrap: \f836;
$fa-var-btc: \f15a;
$fa-var-buffer: \f837;
$fa-var-buromobelexperte: \f37f;
$fa-var-buy-n-large: \f8a6;
$fa-var-buysellads: \f20d;
$fa-var-canadian-maple-leaf: \f785;
$fa-var-cc-amazon-pay: \f42d;
$fa-var-cc-amex: \f1f3;
$fa-var-cc-apple-pay: \f416;
$fa-var-cc-diners-club: \f24c;
$fa-var-cc-discover: \f1f2;
$fa-var-cc-jcb: \f24b;
$fa-var-cc-mastercard: \f1f1;
$fa-var-cc-paypal: \f1f4;
$fa-var-cc-stripe: \f1f5;
$fa-var-cc-visa: \f1f0;
$fa-var-centercode: \f380;
$fa-var-centos: \f789;
$fa-var-chrome: \f268;
$fa-var-chromecast: \f838;
$fa-var-cloudflare: \e07d;
$fa-var-cloudscale: \f383;
$fa-var-cloudsmith: \f384;
$fa-var-cloudversify: \f385;
$fa-var-codepen: \f1cb;
$fa-var-codiepie: \f284;
$fa-var-confluence: \f78d;
$fa-var-connectdevelop: \f20e;
$fa-var-contao: \f26d;
$fa-var-cotton-bureau: \f89e;
$fa-var-cpanel: \f388;
$fa-var-creative-commons: \f25e;
$fa-var-creative-commons-by: \f4e7;
$fa-var-creative-commons-nc: \f4e8;
$fa-var-creative-commons-nc-eu: \f4e9;
$fa-var-creative-commons-nc-jp: \f4ea;
$fa-var-creative-commons-nd: \f4eb;
$fa-var-creative-commons-pd: \f4ec;
$fa-var-creative-commons-pd-alt: \f4ed;
$fa-var-creative-commons-remix: \f4ee;
$fa-var-creative-commons-sa: \f4ef;
$fa-var-creative-commons-sampling: \f4f0;
$fa-var-creative-commons-sampling-plus: \f4f1;
$fa-var-creative-commons-share: \f4f2;
$fa-var-creative-commons-zero: \f4f3;
$fa-var-critical-role: \f6c9;
$fa-var-css3: \f13c;
$fa-var-css3-alt: \f38b;
$fa-var-cuttlefish: \f38c;
$fa-var-d-and-d: \f38d;
$fa-var-d-and-d-beyond: \f6ca;
$fa-var-dailymotion: \e052;
$fa-var-dashcube: \f210;
$fa-var-deezer: \e077;
$fa-var-delicious: \f1a5;
$fa-var-deploydog: \f38e;
$fa-var-deskpro: \f38f;
$fa-var-dev: \f6cc;
$fa-var-deviantart: \f1bd;
$fa-var-dhl: \f790;
$fa-var-diaspora: \f791;
$fa-var-digg: \f1a6;
$fa-var-digital-ocean: \f391;
$fa-var-discord: \f392;
$fa-var-discourse: \f393;
$fa-var-dochub: \f394;
$fa-var-docker: \f395;
$fa-var-draft2digital: \f396;
$fa-var-dribbble: \f17d;
$fa-var-dribbble-square: \f397;
$fa-var-dropbox: \f16b;
$fa-var-drupal: \f1a9;
$fa-var-dyalog: \f399;
$fa-var-earlybirds: \f39a;
$fa-var-ebay: \f4f4;
$fa-var-edge: \f282;
$fa-var-edge-legacy: \e078;
$fa-var-elementor: \f430;
$fa-var-ello: \f5f1;
$fa-var-ember: \f423;
$fa-var-empire: \f1d1;
$fa-var-envira: \f299;
$fa-var-erlang: \f39d;
$fa-var-ethereum: \f42e;
$fa-var-etsy: \f2d7;
$fa-var-evernote: \f839;
$fa-var-expeditedssl: \f23e;
$fa-var-facebook: \f09a;
$fa-var-facebook-f: \f39e;
$fa-var-facebook-messenger: \f39f;
$fa-var-facebook-square: \f082;
$fa-var-fantasy-flight-games: \f6dc;
$fa-var-fedex: \f797;
$fa-var-fedora: \f798;
$fa-var-figma: \f799;
$fa-var-firefox: \f269;
$fa-var-firefox-browser: \e007;
$fa-var-first-order: \f2b0;
$fa-var-first-order-alt: \f50a;
$fa-var-firstdraft: \f3a1;
$fa-var-flickr: \f16e;
$fa-var-flipboard: \f44d;
$fa-var-fly: \f417;
$fa-var-font-awesome: \f2b4;
$fa-var-font-awesome-logo-full: \f2b4;
$fa-var-font-awesome-flag: \f2b4;
$fa-var-fonticons: \f280;
$fa-var-fonticons-fi: \f3a2;
$fa-var-fort-awesome: \f286;
$fa-var-fort-awesome-alt: \f3a3;
$fa-var-forumbee: \f211;
$fa-var-foursquare: \f180;
$fa-var-free-code-camp: \f2c5;
$fa-var-freebsd: \f3a4;
$fa-var-fulcrum: \f50b;
$fa-var-galactic-republic: \f50c;
$fa-var-galactic-senate: \f50d;
$fa-var-get-pocket: \f265;
$fa-var-gg: \f260;
$fa-var-gg-circle: \f261;
$fa-var-git: \f1d3;
$fa-var-git-alt: \f841;
$fa-var-git-square: \f1d2;
$fa-var-github: \f09b;
$fa-var-github-alt: \f113;
$fa-var-github-square: \f092;
$fa-var-gitkraken: \f3a6;
$fa-var-gitlab: \f296;
$fa-var-gitter: \f426;
$fa-var-glide: \f2a5;
$fa-var-glide-g: \f2a6;
$fa-var-gofore: \f3a7;
$fa-var-goodreads: \f3a8;
$fa-var-goodreads-g: \f3a9;
$fa-var-google: \f1a0;
$fa-var-google-drive: \f3aa;
$fa-var-google-pay: \e079;
$fa-var-google-play: \f3ab;
$fa-var-google-plus: \f2b3;
$fa-var-google-plus-g: \f0d5;
$fa-var-google-plus-square: \f0d4;
$fa-var-google-wallet: \f1ee;
$fa-var-gratipay: \f184;
$fa-var-grav: \f2d6;
$fa-var-gripfire: \f3ac;
$fa-var-grunt: \f3ad;
$fa-var-guilded: \e07e;
$fa-var-gulp: \f3ae;
$fa-var-hacker-news: \f1d4;
$fa-var-hacker-news-square: \f3af;
$fa-var-hackerrank: \f5f7;
$fa-var-hips: \f452;
$fa-var-hire-a-helper: \f3b0;
$fa-var-hive: \e07f;
$fa-var-hooli: \f427;
$fa-var-hornbill: \f592;
$fa-var-hotjar: \f3b1;
$fa-var-houzz: \f27c;
$fa-var-html5: \f13b;
$fa-var-hubspot: \f3b2;
$fa-var-ideal: \e013;
$fa-var-imdb: \f2d8;
$fa-var-innosoft: \e080;
$fa-var-instagram: \f16d;
$fa-var-instagram-square: \e055;
$fa-var-instalod: \e081;
$fa-var-intercom: \f7af;
$fa-var-internet-explorer: \f26b;
$fa-var-invision: \f7b0;
$fa-var-ioxhost: \f208;
$fa-var-itch-io: \f83a;
$fa-var-itunes: \f3b4;
$fa-var-itunes-note: \f3b5;
$fa-var-java: \f4e4;
$fa-var-jedi-order: \f50e;
$fa-var-jenkins: \f3b6;
$fa-var-jira: \f7b1;
$fa-var-joget: \f3b7;
$fa-var-joomla: \f1aa;
$fa-var-js: \f3b8;
$fa-var-js-square: \f3b9;
$fa-var-jsfiddle: \f1cc;
$fa-var-kaggle: \f5fa;
$fa-var-keybase: \f4f5;
$fa-var-keycdn: \f3ba;
$fa-var-kickstarter: \f3bb;
$fa-var-kickstarter-k: \f3bc;
$fa-var-korvue: \f42f;
$fa-var-laravel: \f3bd;
$fa-var-lastfm: \f202;
$fa-var-lastfm-square: \f203;
$fa-var-leanpub: \f212;
$fa-var-less: \f41d;
$fa-var-line: \f3c0;
$fa-var-linkedin: \f08c;
$fa-var-linkedin-in: \f0e1;
$fa-var-linode: \f2b8;
$fa-var-linux: \f17c;
$fa-var-lyft: \f3c3;
$fa-var-magento: \f3c4;
$fa-var-mailchimp: \f59e;
$fa-var-mandalorian: \f50f;
$fa-var-markdown: \f60f;
$fa-var-mastodon: \f4f6;
$fa-var-maxcdn: \f136;
$fa-var-mdb: \f8ca;
$fa-var-medapps: \f3c6;
$fa-var-medium: \f23a;
$fa-var-medium-m: \f23a;
$fa-var-medrt: \f3c8;
$fa-var-meetup: \f2e0;
$fa-var-megaport: \f5a3;
$fa-var-mendeley: \f7b3;
$fa-var-microblog: \e01a;
$fa-var-microsoft: \f3ca;
$fa-var-mix: \f3cb;
$fa-var-mixcloud: \f289;
$fa-var-mixer: \e056;
$fa-var-mizuni: \f3cc;
$fa-var-modx: \f285;
$fa-var-monero: \f3d0;
$fa-var-napster: \f3d2;
$fa-var-neos: \f612;
$fa-var-nimblr: \f5a8;
$fa-var-node: \f419;
$fa-var-node-js: \f3d3;
$fa-var-npm: \f3d4;
$fa-var-ns8: \f3d5;
$fa-var-nutritionix: \f3d6;
$fa-var-octopus-deploy: \e082;
$fa-var-odnoklassniki: \f263;
$fa-var-odnoklassniki-square: \f264;
$fa-var-old-republic: \f510;
$fa-var-opencart: \f23d;
$fa-var-openid: \f19b;
$fa-var-opera: \f26a;
$fa-var-optin-monster: \f23c;
$fa-var-orcid: \f8d2;
$fa-var-osi: \f41a;
$fa-var-page4: \f3d7;
$fa-var-pagelines: \f18c;
$fa-var-palfed: \f3d8;
$fa-var-patreon: \f3d9;
$fa-var-paypal: \f1ed;
$fa-var-penny-arcade: \f704;
$fa-var-perbyte: \e083;
$fa-var-periscope: \f3da;
$fa-var-phabricator: \f3db;
$fa-var-phoenix-framework: \f3dc;
$fa-var-phoenix-squadron: \f511;
$fa-var-php: \f457;
$fa-var-pied-piper: \f2ae;
$fa-var-pied-piper-alt: \f1a8;
$fa-var-pied-piper-hat: \f4e5;
$fa-var-pied-piper-pp: \f1a7;
$fa-var-pied-piper-square: \e01e;
$fa-var-pinterest: \f0d2;
$fa-var-pinterest-p: \f231;
$fa-var-pinterest-square: \f0d3;
$fa-var-playstation: \f3df;
$fa-var-product-hunt: \f288;
$fa-var-pushed: \f3e1;
$fa-var-python: \f3e2;
$fa-var-qq: \f1d6;
$fa-var-quinscape: \f459;
$fa-var-quora: \f2c4;
$fa-var-r-project: \f4f7;
$fa-var-raspberry-pi: \f7bb;
$fa-var-ravelry: \f2d9;
$fa-var-react: \f41b;
$fa-var-reacteurope: \f75d;
$fa-var-readme: \f4d5;
$fa-var-rebel: \f1d0;
$fa-var-red-river: \f3e3;
$fa-var-reddit: \f1a1;
$fa-var-reddit-alien: \f281;
$fa-var-reddit-square: \f1a2;
$fa-var-redhat: \f7bc;
$fa-var-renren: \f18b;
$fa-var-replyd: \f3e6;
$fa-var-researchgate: \f4f8;
$fa-var-resolving: \f3e7;
$fa-var-rev: \f5b2;
$fa-var-rocketchat: \f3e8;
$fa-var-rockrms: \f3e9;
$fa-var-rust: \e07a;
$fa-var-safari: \f267;
$fa-var-salesforce: \f83b;
$fa-var-sass: \f41e;
$fa-var-schlix: \f3ea;
$fa-var-scribd: \f28a;
$fa-var-searchengin: \f3eb;
$fa-var-sellcast: \f2da;
$fa-var-sellsy: \f213;
$fa-var-servicestack: \f3ec;
$fa-var-shirtsinbulk: \f214;
$fa-var-shopify: \e057;
$fa-var-shopware: \f5b5;
$fa-var-simplybuilt: \f215;
$fa-var-sistrix: \f3ee;
$fa-var-sith: \f512;
$fa-var-sketch: \f7c6;
$fa-var-skyatlas: \f216;
$fa-var-skype: \f17e;
$fa-var-slack: \f198;
$fa-var-slack-hash: \f198;
$fa-var-slideshare: \f1e7;
$fa-var-snapchat: \f2ab;
$fa-var-snapchat-ghost: \f2ab;
$fa-var-snapchat-square: \f2ad;
$fa-var-soundcloud: \f1be;
$fa-var-sourcetree: \f7d3;
$fa-var-speakap: \f3f3;
$fa-var-speaker-deck: \f83c;
$fa-var-spotify: \f1bc;
$fa-var-square-font-awesome: \f425;
$fa-var-square-font-awesome-stroke: \f35c;
$fa-var-font-awesome-alt: \f35c;
$fa-var-squarespace: \f5be;
$fa-var-stack-exchange: \f18d;
$fa-var-stack-overflow: \f16c;
$fa-var-stackpath: \f842;
$fa-var-staylinked: \f3f5;
$fa-var-steam: \f1b6;
$fa-var-steam-square: \f1b7;
$fa-var-steam-symbol: \f3f6;
$fa-var-sticker-mule: \f3f7;
$fa-var-strava: \f428;
$fa-var-stripe: \f429;
$fa-var-stripe-s: \f42a;
$fa-var-studiovinari: \f3f8;
$fa-var-stumbleupon: \f1a4;
$fa-var-stumbleupon-circle: \f1a3;
$fa-var-superpowers: \f2dd;
$fa-var-supple: \f3f9;
$fa-var-suse: \f7d6;
$fa-var-swift: \f8e1;
$fa-var-symfony: \f83d;
$fa-var-teamspeak: \f4f9;
$fa-var-telegram: \f2c6;
$fa-var-telegram-plane: \f2c6;
$fa-var-tencent-weibo: \f1d5;
$fa-var-the-red-yeti: \f69d;
$fa-var-themeco: \f5c6;
$fa-var-themeisle: \f2b2;
$fa-var-think-peaks: \f731;
$fa-var-tiktok: \e07b;
$fa-var-trade-federation: \f513;
$fa-var-trello: \f181;
$fa-var-tripadvisor: \f262;
$fa-var-tumblr: \f173;
$fa-var-tumblr-square: \f174;
$fa-var-twitch: \f1e8;
$fa-var-twitter: \f099;
$fa-var-twitter-square: \f081;
$fa-var-typo3: \f42b;
$fa-var-uber: \f402;
$fa-var-ubuntu: \f7df;
$fa-var-uikit: \f403;
$fa-var-umbraco: \f8e8;
$fa-var-uncharted: \e084;
$fa-var-uniregistry: \f404;
$fa-var-unity: \e049;
$fa-var-unsplash: \e07c;
$fa-var-untappd: \f405;
$fa-var-ups: \f7e0;
$fa-var-usb: \f287;
$fa-var-usps: \f7e1;
$fa-var-ussunnah: \f407;
$fa-var-vaadin: \f408;
$fa-var-viacoin: \f237;
$fa-var-viadeo: \f2a9;
$fa-var-viadeo-square: \f2aa;
$fa-var-viber: \f409;
$fa-var-vimeo: \f40a;
$fa-var-vimeo-square: \f194;
$fa-var-vimeo-v: \f27d;
$fa-var-vine: \f1ca;
$fa-var-vk: \f189;
$fa-var-vnv: \f40b;
$fa-var-vuejs: \f41f;
$fa-var-watchman-monitoring: \e087;
$fa-var-waze: \f83f;
$fa-var-weebly: \f5cc;
$fa-var-weibo: \f18a;
$fa-var-weixin: \f1d7;
$fa-var-whatsapp: \f232;
$fa-var-whatsapp-square: \f40c;
$fa-var-whmcs: \f40d;
$fa-var-wikipedia-w: \f266;
$fa-var-windows: \f17a;
$fa-var-wix: \f5cf;
$fa-var-wizards-of-the-coast: \f730;
$fa-var-wodu: \e088;
$fa-var-wolf-pack-battalion: \f514;
$fa-var-wordpress: \f19a;
$fa-var-wordpress-simple: \f411;
$fa-var-wpbeginner: \f297;
$fa-var-wpexplorer: \f2de;
$fa-var-wpforms: \f298;
$fa-var-wpressr: \f3e4;
$fa-var-xbox: \f412;
$fa-var-xing: \f168;
$fa-var-xing-square: \f169;
$fa-var-y-combinator: \f23b;
$fa-var-yahoo: \f19e;
$fa-var-yammer: \f840;
$fa-var-yandex: \f413;
$fa-var-yandex-international: \f414;
$fa-var-yarn: \f7e3;
$fa-var-yelp: \f1e9;
$fa-var-yoast: \f2b1;
$fa-var-youtube: \f167;
$fa-var-youtube-square: \f431;
$fa-var-zhihu: \f63f;

$fa-icons: (
	0: $fa-var-0,
	1: $fa-var-1,
	2: $fa-var-2,
	3: $fa-var-3,
	4: $fa-var-4,
	5: $fa-var-5,
	6: $fa-var-6,
	7: $fa-var-7,
	8: $fa-var-8,
	9: $fa-var-9,
	a: $fa-var-a,
	abacus: $fa-var-abacus,
	acorn: $fa-var-acorn,
	address-book: $fa-var-address-book,
	contact-book: $fa-var-contact-book,
	address-card: $fa-var-address-card,
	contact-card: $fa-var-contact-card,
	vcard: $fa-var-vcard,
	air-conditioner: $fa-var-air-conditioner,
	air-freshener: $fa-var-air-freshener,
	airplay: $fa-var-airplay,
	alarm-clock: $fa-var-alarm-clock,
	alarm-exclamation: $fa-var-alarm-exclamation,
	alarm-plus: $fa-var-alarm-plus,
	alarm-snooze: $fa-var-alarm-snooze,
	album: $fa-var-album,
	album-collection: $fa-var-album-collection,
	alicorn: $fa-var-alicorn,
	alien: $fa-var-alien,
	alien-8bit: $fa-var-alien-8bit,
	alien-monster: $fa-var-alien-monster,
	align-center: $fa-var-align-center,
	align-justify: $fa-var-align-justify,
	align-left: $fa-var-align-left,
	align-right: $fa-var-align-right,
	align-slash: $fa-var-align-slash,
	alt: $fa-var-alt,
	amp-guitar: $fa-var-amp-guitar,
	ampersand: $fa-var-ampersand,
	anchor: $fa-var-anchor,
	angel: $fa-var-angel,
	angle: $fa-var-angle,
	angle-90: $fa-var-angle-90,
	angle-down: $fa-var-angle-down,
	angle-left: $fa-var-angle-left,
	angle-right: $fa-var-angle-right,
	angle-up: $fa-var-angle-up,
	angles-down: $fa-var-angles-down,
	angle-double-down: $fa-var-angle-double-down,
	angles-left: $fa-var-angles-left,
	angle-double-left: $fa-var-angle-double-left,
	angles-right: $fa-var-angles-right,
	angle-double-right: $fa-var-angle-double-right,
	angles-up: $fa-var-angles-up,
	angle-double-up: $fa-var-angle-double-up,
	ankh: $fa-var-ankh,
	aperture: $fa-var-aperture,
	apple-core: $fa-var-apple-core,
	apple-crate: $fa-var-apple-crate,
	apple-whole: $fa-var-apple-whole,
	apple-alt: $fa-var-apple-alt,
	archway: $fa-var-archway,
	arrow-down: $fa-var-arrow-down,
	arrow-down-1-9: $fa-var-arrow-down-1-9,
	sort-numeric-down: $fa-var-sort-numeric-down,
	sort-numeric-asc: $fa-var-sort-numeric-asc,
	arrow-down-9-1: $fa-var-arrow-down-9-1,
	sort-numeric-down-alt: $fa-var-sort-numeric-down-alt,
	sort-numeric-desc: $fa-var-sort-numeric-desc,
	arrow-down-a-z: $fa-var-arrow-down-a-z,
	sort-alpha-down: $fa-var-sort-alpha-down,
	sort-alpha-asc: $fa-var-sort-alpha-asc,
	arrow-down-arrow-up: $fa-var-arrow-down-arrow-up,
	sort-alt: $fa-var-sort-alt,
	arrow-down-big-small: $fa-var-arrow-down-big-small,
	sort-size-down: $fa-var-sort-size-down,
	arrow-down-from-dotted-line: $fa-var-arrow-down-from-dotted-line,
	arrow-down-from-line: $fa-var-arrow-down-from-line,
	arrow-from-top: $fa-var-arrow-from-top,
	arrow-down-left: $fa-var-arrow-down-left,
	arrow-down-left-and-arrow-up-right-to-center: $fa-var-arrow-down-left-and-arrow-up-right-to-center,
	arrow-down-long: $fa-var-arrow-down-long,
	long-arrow-down: $fa-var-long-arrow-down,
	arrow-down-right: $fa-var-arrow-down-right,
	arrow-down-short-wide: $fa-var-arrow-down-short-wide,
	sort-amount-down-alt: $fa-var-sort-amount-down-alt,
	sort-amount-desc: $fa-var-sort-amount-desc,
	arrow-down-small-big: $fa-var-arrow-down-small-big,
	sort-size-down-alt: $fa-var-sort-size-down-alt,
	arrow-down-square-triangle: $fa-var-arrow-down-square-triangle,
	sort-shapes-down-alt: $fa-var-sort-shapes-down-alt,
	arrow-down-to-bracket: $fa-var-arrow-down-to-bracket,
	arrow-down-to-dotted-line: $fa-var-arrow-down-to-dotted-line,
	arrow-down-to-line: $fa-var-arrow-down-to-line,
	arrow-to-bottom: $fa-var-arrow-to-bottom,
	arrow-down-to-square: $fa-var-arrow-down-to-square,
	arrow-down-triangle-square: $fa-var-arrow-down-triangle-square,
	sort-shapes-down: $fa-var-sort-shapes-down,
	arrow-down-wide-short: $fa-var-arrow-down-wide-short,
	sort-amount-down: $fa-var-sort-amount-down,
	sort-amount-asc: $fa-var-sort-amount-asc,
	arrow-down-z-a: $fa-var-arrow-down-z-a,
	sort-alpha-down-alt: $fa-var-sort-alpha-down-alt,
	sort-alpha-desc: $fa-var-sort-alpha-desc,
	arrow-left: $fa-var-arrow-left,
	arrow-left-from-line: $fa-var-arrow-left-from-line,
	arrow-from-right: $fa-var-arrow-from-right,
	arrow-left-long: $fa-var-arrow-left-long,
	long-arrow-left: $fa-var-long-arrow-left,
	arrow-left-rotate: $fa-var-arrow-left-rotate,
	undo: $fa-var-undo,
	arrow-rotate-left: $fa-var-arrow-rotate-left,
	arrow-left-to-line: $fa-var-arrow-left-to-line,
	arrow-to-left: $fa-var-arrow-to-left,
	arrow-pointer: $fa-var-arrow-pointer,
	mouse-pointer: $fa-var-mouse-pointer,
	arrow-right: $fa-var-arrow-right,
	arrow-right-arrow-left: $fa-var-arrow-right-arrow-left,
	exchange: $fa-var-exchange,
	arrow-right-from-bracket: $fa-var-arrow-right-from-bracket,
	sign-out: $fa-var-sign-out,
	arrow-right-from-line: $fa-var-arrow-right-from-line,
	arrow-from-left: $fa-var-arrow-from-left,
	arrow-right-long: $fa-var-arrow-right-long,
	long-arrow-right: $fa-var-long-arrow-right,
	arrow-right-to-bracket: $fa-var-arrow-right-to-bracket,
	sign-in: $fa-var-sign-in,
	arrow-right-to-line: $fa-var-arrow-right-to-line,
	arrow-to-right: $fa-var-arrow-to-right,
	arrow-rotate-forward: $fa-var-arrow-rotate-forward,
	redo: $fa-var-redo,
	rotate-right: $fa-var-rotate-right,
	arrow-trend-down: $fa-var-arrow-trend-down,
	arrow-trend-up: $fa-var-arrow-trend-up,
	arrow-turn-down: $fa-var-arrow-turn-down,
	level-down: $fa-var-level-down,
	arrow-turn-up: $fa-var-arrow-turn-up,
	level-up: $fa-var-level-up,
	arrow-up: $fa-var-arrow-up,
	arrow-up-1-9: $fa-var-arrow-up-1-9,
	sort-numeric-up: $fa-var-sort-numeric-up,
	arrow-up-9-1: $fa-var-arrow-up-9-1,
	sort-numeric-up-alt: $fa-var-sort-numeric-up-alt,
	arrow-up-a-z: $fa-var-arrow-up-a-z,
	sort-alpha-up: $fa-var-sort-alpha-up,
	arrow-up-arrow-down: $fa-var-arrow-up-arrow-down,
	sort-up-down: $fa-var-sort-up-down,
	arrow-up-big-small: $fa-var-arrow-up-big-small,
	sort-size-up: $fa-var-sort-size-up,
	arrow-up-from-bracket: $fa-var-arrow-up-from-bracket,
	arrow-up-from-dotted-line: $fa-var-arrow-up-from-dotted-line,
	arrow-up-from-line: $fa-var-arrow-up-from-line,
	arrow-from-bottom: $fa-var-arrow-from-bottom,
	arrow-up-from-square: $fa-var-arrow-up-from-square,
	arrow-up-left: $fa-var-arrow-up-left,
	arrow-up-left-from-circle: $fa-var-arrow-up-left-from-circle,
	arrow-up-long: $fa-var-arrow-up-long,
	long-arrow-up: $fa-var-long-arrow-up,
	arrow-up-right: $fa-var-arrow-up-right,
	arrow-up-right-and-arrow-down-left-from-center: $fa-var-arrow-up-right-and-arrow-down-left-from-center,
	arrow-up-right-from-square: $fa-var-arrow-up-right-from-square,
	external-link: $fa-var-external-link,
	arrow-up-short-wide: $fa-var-arrow-up-short-wide,
	sort-amount-up-alt: $fa-var-sort-amount-up-alt,
	arrow-up-small-big: $fa-var-arrow-up-small-big,
	sort-size-up-alt: $fa-var-sort-size-up-alt,
	arrow-up-square-triangle: $fa-var-arrow-up-square-triangle,
	sort-shapes-up-alt: $fa-var-sort-shapes-up-alt,
	arrow-up-to-dotted-line: $fa-var-arrow-up-to-dotted-line,
	arrow-up-to-line: $fa-var-arrow-up-to-line,
	arrow-to-top: $fa-var-arrow-to-top,
	arrow-up-triangle-square: $fa-var-arrow-up-triangle-square,
	sort-shapes-up: $fa-var-sort-shapes-up,
	arrow-up-wide-short: $fa-var-arrow-up-wide-short,
	sort-amount-up: $fa-var-sort-amount-up,
	arrow-up-z-a: $fa-var-arrow-up-z-a,
	sort-alpha-up-alt: $fa-var-sort-alpha-up-alt,
	arrows-cross: $fa-var-arrows-cross,
	arrows-from-dotted-line: $fa-var-arrows-from-dotted-line,
	arrows-from-line: $fa-var-arrows-from-line,
	arrows-left-right: $fa-var-arrows-left-right,
	arrows-h: $fa-var-arrows-h,
	arrows-maximize: $fa-var-arrows-maximize,
	expand-arrows: $fa-var-expand-arrows,
	arrows-minimize: $fa-var-arrows-minimize,
	compress-arrows: $fa-var-compress-arrows,
	arrows-repeat: $fa-var-arrows-repeat,
	repeat-alt: $fa-var-repeat-alt,
	arrows-repeat-1: $fa-var-arrows-repeat-1,
	repeat-1-alt: $fa-var-repeat-1-alt,
	arrows-retweet: $fa-var-arrows-retweet,
	retweet-alt: $fa-var-retweet-alt,
	arrows-rotate: $fa-var-arrows-rotate,
	sync: $fa-var-sync,
	refresh: $fa-var-refresh,
	arrows-to-dotted-line: $fa-var-arrows-to-dotted-line,
	arrows-to-line: $fa-var-arrows-to-line,
	arrows-up-down: $fa-var-arrows-up-down,
	arrows-v: $fa-var-arrows-v,
	arrows-up-down-left-right: $fa-var-arrows-up-down-left-right,
	arrows: $fa-var-arrows,
	asterisk: $fa-var-asterisk,
	at: $fa-var-at,
	atom: $fa-var-atom,
	atom-simple: $fa-var-atom-simple,
	atom-alt: $fa-var-atom-alt,
	audio-description: $fa-var-audio-description,
	audio-description-slash: $fa-var-audio-description-slash,
	austral-sign: $fa-var-austral-sign,
	avocado: $fa-var-avocado,
	award: $fa-var-award,
	award-simple: $fa-var-award-simple,
	axe: $fa-var-axe,
	axe-battle: $fa-var-axe-battle,
	b: $fa-var-b,
	baby: $fa-var-baby,
	baby-carriage: $fa-var-baby-carriage,
	carriage-baby: $fa-var-carriage-baby,
	backpack: $fa-var-backpack,
	backward: $fa-var-backward,
	backward-fast: $fa-var-backward-fast,
	fast-backward: $fa-var-fast-backward,
	backward-step: $fa-var-backward-step,
	step-backward: $fa-var-step-backward,
	bacon: $fa-var-bacon,
	bacteria: $fa-var-bacteria,
	bacterium: $fa-var-bacterium,
	badge: $fa-var-badge,
	badge-check: $fa-var-badge-check,
	badge-dollar: $fa-var-badge-dollar,
	badge-percent: $fa-var-badge-percent,
	badge-sheriff: $fa-var-badge-sheriff,
	badger-honey: $fa-var-badger-honey,
	bag-shopping: $fa-var-bag-shopping,
	shopping-bag: $fa-var-shopping-bag,
	bags-shopping: $fa-var-bags-shopping,
	bahai: $fa-var-bahai,
	baht-sign: $fa-var-baht-sign,
	ball-pile: $fa-var-ball-pile,
	ballot: $fa-var-ballot,
	ballot-check: $fa-var-ballot-check,
	ban: $fa-var-ban,
	cancel: $fa-var-cancel,
	ban-bug: $fa-var-ban-bug,
	debug: $fa-var-debug,
	ban-parking: $fa-var-ban-parking,
	parking-circle-slash: $fa-var-parking-circle-slash,
	ban-smoking: $fa-var-ban-smoking,
	smoking-ban: $fa-var-smoking-ban,
	bandage: $fa-var-bandage,
	band-aid: $fa-var-band-aid,
	banjo: $fa-var-banjo,
	bank: $fa-var-bank,
	university: $fa-var-university,
	institution: $fa-var-institution,
	barcode: $fa-var-barcode,
	barcode-read: $fa-var-barcode-read,
	barcode-scan: $fa-var-barcode-scan,
	bars: $fa-var-bars,
	navicon: $fa-var-navicon,
	bars-filter: $fa-var-bars-filter,
	bars-progress: $fa-var-bars-progress,
	tasks-alt: $fa-var-tasks-alt,
	bars-sort: $fa-var-bars-sort,
	bars-staggered: $fa-var-bars-staggered,
	stream: $fa-var-stream,
	reorder: $fa-var-reorder,
	baseball-ball: $fa-var-baseball-ball,
	baseball-bat-ball: $fa-var-baseball-bat-ball,
	baseball: $fa-var-baseball,
	basket-shopping: $fa-var-basket-shopping,
	shopping-basket: $fa-var-shopping-basket,
	basket-shopping-simple: $fa-var-basket-shopping-simple,
	shopping-basket-alt: $fa-var-shopping-basket-alt,
	basketball-ball: $fa-var-basketball-ball,
	basketball-hoop: $fa-var-basketball-hoop,
	bat: $fa-var-bat,
	bath: $fa-var-bath,
	bathtub: $fa-var-bathtub,
	battery-bolt: $fa-var-battery-bolt,
	battery-empty: $fa-var-battery-empty,
	battery-0: $fa-var-battery-0,
	battery-exclamation: $fa-var-battery-exclamation,
	battery-full: $fa-var-battery-full,
	battery: $fa-var-battery,
	battery-5: $fa-var-battery-5,
	battery-half: $fa-var-battery-half,
	battery-3: $fa-var-battery-3,
	battery-low: $fa-var-battery-low,
	battery-1: $fa-var-battery-1,
	battery-quarter: $fa-var-battery-quarter,
	battery-2: $fa-var-battery-2,
	battery-slash: $fa-var-battery-slash,
	battery-three-quarters: $fa-var-battery-three-quarters,
	battery-4: $fa-var-battery-4,
	bed: $fa-var-bed,
	bed-bunk: $fa-var-bed-bunk,
	bed-empty: $fa-var-bed-empty,
	bed-front: $fa-var-bed-front,
	bed-alt: $fa-var-bed-alt,
	bed-pulse: $fa-var-bed-pulse,
	procedures: $fa-var-procedures,
	bee: $fa-var-bee,
	beer-mug: $fa-var-beer-mug,
	beer-foam: $fa-var-beer-foam,
	beer-mug-empty: $fa-var-beer-mug-empty,
	beer: $fa-var-beer,
	bell: $fa-var-bell,
	bell-concierge: $fa-var-bell-concierge,
	concierge-bell: $fa-var-concierge-bell,
	bell-exclamation: $fa-var-bell-exclamation,
	bell-on: $fa-var-bell-on,
	bell-plus: $fa-var-bell-plus,
	bell-school: $fa-var-bell-school,
	bell-school-slash: $fa-var-bell-school-slash,
	bell-slash: $fa-var-bell-slash,
	bells: $fa-var-bells,
	bezier-curve: $fa-var-bezier-curve,
	bicycle: $fa-var-bicycle,
	binoculars: $fa-var-binoculars,
	biohazard: $fa-var-biohazard,
	bitcoin-sign: $fa-var-bitcoin-sign,
	blanket: $fa-var-blanket,
	blender: $fa-var-blender,
	blender-phone: $fa-var-blender-phone,
	blinds: $fa-var-blinds,
	blinds-open: $fa-var-blinds-open,
	blinds-raised: $fa-var-blinds-raised,
	block-quote: $fa-var-block-quote,
	blog: $fa-var-blog,
	bold: $fa-var-bold,
	bolt: $fa-var-bolt,
	flash: $fa-var-flash,
	bolt-auto: $fa-var-bolt-auto,
	bolt-lightning: $fa-var-bolt-lightning,
	bolt-slash: $fa-var-bolt-slash,
	bomb: $fa-var-bomb,
	bone: $fa-var-bone,
	bone-break: $fa-var-bone-break,
	bong: $fa-var-bong,
	book: $fa-var-book,
	book-arrow-right: $fa-var-book-arrow-right,
	book-arrow-up: $fa-var-book-arrow-up,
	book-atlas: $fa-var-book-atlas,
	atlas: $fa-var-atlas,
	book-bible: $fa-var-book-bible,
	bible: $fa-var-bible,
	book-blank: $fa-var-book-blank,
	book-alt: $fa-var-book-alt,
	book-bookmark: $fa-var-book-bookmark,
	book-circle-arrow-right: $fa-var-book-circle-arrow-right,
	book-circle-arrow-up: $fa-var-book-circle-arrow-up,
	book-copy: $fa-var-book-copy,
	book-font: $fa-var-book-font,
	book-heart: $fa-var-book-heart,
	book-journal-whills: $fa-var-book-journal-whills,
	journal-whills: $fa-var-journal-whills,
	book-law: $fa-var-book-law,
	book-medical: $fa-var-book-medical,
	book-open: $fa-var-book-open,
	book-open-cover: $fa-var-book-open-cover,
	book-open-alt: $fa-var-book-open-alt,
	book-open-reader: $fa-var-book-open-reader,
	book-reader: $fa-var-book-reader,
	book-quran: $fa-var-book-quran,
	quran: $fa-var-quran,
	book-section: $fa-var-book-section,
	book-skull: $fa-var-book-skull,
	book-dead: $fa-var-book-dead,
	book-sparkles: $fa-var-book-sparkles,
	book-spells: $fa-var-book-spells,
	book-tanakh: $fa-var-book-tanakh,
	tanakh: $fa-var-tanakh,
	book-user: $fa-var-book-user,
	bookmark: $fa-var-bookmark,
	bookmark-slash: $fa-var-bookmark-slash,
	books: $fa-var-books,
	books-medical: $fa-var-books-medical,
	boombox: $fa-var-boombox,
	boot: $fa-var-boot,
	booth-curtain: $fa-var-booth-curtain,
	border-all: $fa-var-border-all,
	border-bottom: $fa-var-border-bottom,
	border-bottom-right: $fa-var-border-bottom-right,
	border-style-alt: $fa-var-border-style-alt,
	border-center-h: $fa-var-border-center-h,
	border-center-v: $fa-var-border-center-v,
	border-inner: $fa-var-border-inner,
	border-left: $fa-var-border-left,
	border-none: $fa-var-border-none,
	border-outer: $fa-var-border-outer,
	border-right: $fa-var-border-right,
	border-top: $fa-var-border-top,
	border-top-left: $fa-var-border-top-left,
	border-style: $fa-var-border-style,
	bow-arrow: $fa-var-bow-arrow,
	bowl-hot: $fa-var-bowl-hot,
	soup: $fa-var-soup,
	bowling-ball: $fa-var-bowling-ball,
	bowling-ball-pin: $fa-var-bowling-ball-pin,
	bowling-pins: $fa-var-bowling-pins,
	box: $fa-var-box,
	box-archive: $fa-var-box-archive,
	archive: $fa-var-archive,
	box-ballot: $fa-var-box-ballot,
	box-check: $fa-var-box-check,
	box-circle-check: $fa-var-box-circle-check,
	box-dollar: $fa-var-box-dollar,
	box-usd: $fa-var-box-usd,
	box-heart: $fa-var-box-heart,
	box-open: $fa-var-box-open,
	box-open-full: $fa-var-box-open-full,
	box-full: $fa-var-box-full,
	box-taped: $fa-var-box-taped,
	box-alt: $fa-var-box-alt,
	box-tissue: $fa-var-box-tissue,
	boxes-stacked: $fa-var-boxes-stacked,
	boxes: $fa-var-boxes,
	boxes-alt: $fa-var-boxes-alt,
	boxing-glove: $fa-var-boxing-glove,
	glove-boxing: $fa-var-glove-boxing,
	bracket-curly: $fa-var-bracket-curly,
	bracket-curly-left: $fa-var-bracket-curly-left,
	bracket-curly-right: $fa-var-bracket-curly-right,
	bracket-round: $fa-var-bracket-round,
	parenthesis: $fa-var-parenthesis,
	bracket-round-right: $fa-var-bracket-round-right,
	bracket-square: $fa-var-bracket-square,
	bracket: $fa-var-bracket,
	bracket-left: $fa-var-bracket-left,
	bracket-square-right: $fa-var-bracket-square-right,
	brackets-curly: $fa-var-brackets-curly,
	brackets-round: $fa-var-brackets-round,
	parentheses: $fa-var-parentheses,
	brackets-square: $fa-var-brackets-square,
	brackets: $fa-var-brackets,
	braille: $fa-var-braille,
	brain: $fa-var-brain,
	brain-arrow-curved-right: $fa-var-brain-arrow-curved-right,
	mind-share: $fa-var-mind-share,
	brain-circuit: $fa-var-brain-circuit,
	brake-warning: $fa-var-brake-warning,
	bread-loaf: $fa-var-bread-loaf,
	bread-slice: $fa-var-bread-slice,
	briefcase: $fa-var-briefcase,
	briefcase-arrow-right: $fa-var-briefcase-arrow-right,
	briefcase-clock: $fa-var-briefcase-clock,
	business-time: $fa-var-business-time,
	briefcase-medical: $fa-var-briefcase-medical,
	bring-forward: $fa-var-bring-forward,
	bring-front: $fa-var-bring-front,
	broom: $fa-var-broom,
	browser: $fa-var-browser,
	browsers: $fa-var-browsers,
	brush: $fa-var-brush,
	bug: $fa-var-bug,
	building: $fa-var-building,
	buildings: $fa-var-buildings,
	bullhorn: $fa-var-bullhorn,
	bullseye: $fa-var-bullseye,
	bullseye-arrow: $fa-var-bullseye-arrow,
	bullseye-pointer: $fa-var-bullseye-pointer,
	burger: $fa-var-burger,
	hamburger: $fa-var-hamburger,
	burger-cheese: $fa-var-burger-cheese,
	cheeseburger: $fa-var-cheeseburger,
	burger-fries: $fa-var-burger-fries,
	burger-glass: $fa-var-burger-glass,
	burger-soda: $fa-var-burger-soda,
	burrito: $fa-var-burrito,
	bus: $fa-var-bus,
	bus-school: $fa-var-bus-school,
	bus-simple: $fa-var-bus-simple,
	bus-alt: $fa-var-bus-alt,
	c: $fa-var-c,
	cabinet-filing: $fa-var-cabinet-filing,
	cable-car: $fa-var-cable-car,
	cactus: $fa-var-cactus,
	cake-candles: $fa-var-cake-candles,
	birthday-cake: $fa-var-birthday-cake,
	calculator: $fa-var-calculator,
	calculator-simple: $fa-var-calculator-simple,
	calculator-alt: $fa-var-calculator-alt,
	calendar: $fa-var-calendar,
	calendar-arrow-down: $fa-var-calendar-arrow-down,
	calendar-download: $fa-var-calendar-download,
	calendar-arrow-up: $fa-var-calendar-arrow-up,
	calendar-upload: $fa-var-calendar-upload,
	calendar-check: $fa-var-calendar-check,
	calendar-clock: $fa-var-calendar-clock,
	calendar-time: $fa-var-calendar-time,
	calendar-day: $fa-var-calendar-day,
	calendar-days: $fa-var-calendar-days,
	calendar-alt: $fa-var-calendar-alt,
	calendar-exclamation: $fa-var-calendar-exclamation,
	calendar-heart: $fa-var-calendar-heart,
	calendar-image: $fa-var-calendar-image,
	calendar-lines: $fa-var-calendar-lines,
	calendar-note: $fa-var-calendar-note,
	calendar-minus: $fa-var-calendar-minus,
	calendar-pen: $fa-var-calendar-pen,
	calendar-edit: $fa-var-calendar-edit,
	calendar-plus: $fa-var-calendar-plus,
	calendar-range: $fa-var-calendar-range,
	calendar-star: $fa-var-calendar-star,
	calendar-week: $fa-var-calendar-week,
	calendar-xmark: $fa-var-calendar-xmark,
	calendar-times: $fa-var-calendar-times,
	calendars: $fa-var-calendars,
	camcorder: $fa-var-camcorder,
	video-handheld: $fa-var-video-handheld,
	camera: $fa-var-camera,
	camera-alt: $fa-var-camera-alt,
	camera-cctv: $fa-var-camera-cctv,
	cctv: $fa-var-cctv,
	camera-movie: $fa-var-camera-movie,
	camera-polaroid: $fa-var-camera-polaroid,
	camera-retro: $fa-var-camera-retro,
	camera-rotate: $fa-var-camera-rotate,
	camera-security: $fa-var-camera-security,
	camera-home: $fa-var-camera-home,
	camera-slash: $fa-var-camera-slash,
	camera-viewfinder: $fa-var-camera-viewfinder,
	camera-web: $fa-var-camera-web,
	webcam: $fa-var-webcam,
	camera-web-slash: $fa-var-camera-web-slash,
	webcam-slash: $fa-var-webcam-slash,
	campfire: $fa-var-campfire,
	campground: $fa-var-campground,
	candle-holder: $fa-var-candle-holder,
	candy-cane: $fa-var-candy-cane,
	candy-corn: $fa-var-candy-corn,
	cannabis: $fa-var-cannabis,
	capsules: $fa-var-capsules,
	car: $fa-var-car,
	automobile: $fa-var-automobile,
	car-battery: $fa-var-car-battery,
	battery-car: $fa-var-battery-car,
	car-building: $fa-var-car-building,
	car-bump: $fa-var-car-bump,
	car-bus: $fa-var-car-bus,
	car-crash: $fa-var-car-crash,
	car-garage: $fa-var-car-garage,
	car-rear: $fa-var-car-rear,
	car-alt: $fa-var-car-alt,
	car-side: $fa-var-car-side,
	car-tilt: $fa-var-car-tilt,
	car-wash: $fa-var-car-wash,
	car-wrench: $fa-var-car-wrench,
	car-mechanic: $fa-var-car-mechanic,
	caravan: $fa-var-caravan,
	caravan-simple: $fa-var-caravan-simple,
	caravan-alt: $fa-var-caravan-alt,
	caret-down: $fa-var-caret-down,
	caret-left: $fa-var-caret-left,
	caret-right: $fa-var-caret-right,
	caret-up: $fa-var-caret-up,
	carrot: $fa-var-carrot,
	cars: $fa-var-cars,
	cart-arrow-down: $fa-var-cart-arrow-down,
	cart-flatbed: $fa-var-cart-flatbed,
	dolly-flatbed: $fa-var-dolly-flatbed,
	cart-flatbed-boxes: $fa-var-cart-flatbed-boxes,
	dolly-flatbed-alt: $fa-var-dolly-flatbed-alt,
	cart-flatbed-empty: $fa-var-cart-flatbed-empty,
	dolly-flatbed-empty: $fa-var-dolly-flatbed-empty,
	cart-flatbed-suitcase: $fa-var-cart-flatbed-suitcase,
	luggage-cart: $fa-var-luggage-cart,
	cart-plus: $fa-var-cart-plus,
	cart-shopping: $fa-var-cart-shopping,
	shopping-cart: $fa-var-shopping-cart,
	cart-shopping-fast: $fa-var-cart-shopping-fast,
	casette-betamax: $fa-var-casette-betamax,
	betamax: $fa-var-betamax,
	casette-vhs: $fa-var-casette-vhs,
	vhs: $fa-var-vhs,
	cash-register: $fa-var-cash-register,
	cassette-tape: $fa-var-cassette-tape,
	castle: $fa-var-castle,
	cat: $fa-var-cat,
	cat-space: $fa-var-cat-space,
	cauldron: $fa-var-cauldron,
	cedi-sign: $fa-var-cedi-sign,
	cent-sign: $fa-var-cent-sign,
	certificate: $fa-var-certificate,
	chair: $fa-var-chair,
	chair-office: $fa-var-chair-office,
	chalkboard: $fa-var-chalkboard,
	blackboard: $fa-var-blackboard,
	chalkboard-user: $fa-var-chalkboard-user,
	chalkboard-teacher: $fa-var-chalkboard-teacher,
	champagne-glass: $fa-var-champagne-glass,
	glass-champagne: $fa-var-glass-champagne,
	champagne-glasses: $fa-var-champagne-glasses,
	glass-cheers: $fa-var-glass-cheers,
	charging-station: $fa-var-charging-station,
	chart-area: $fa-var-chart-area,
	area-chart: $fa-var-area-chart,
	chart-bar: $fa-var-chart-bar,
	bar-chart: $fa-var-bar-chart,
	chart-bullet: $fa-var-chart-bullet,
	chart-candlestick: $fa-var-chart-candlestick,
	chart-column: $fa-var-chart-column,
	chart-gantt: $fa-var-chart-gantt,
	chart-line: $fa-var-chart-line,
	line-chart: $fa-var-line-chart,
	chart-line-down: $fa-var-chart-line-down,
	chart-line-up: $fa-var-chart-line-up,
	chart-mixed: $fa-var-chart-mixed,
	analytics: $fa-var-analytics,
	chart-network: $fa-var-chart-network,
	chart-pie: $fa-var-chart-pie,
	pie-chart: $fa-var-pie-chart,
	chart-pie-simple: $fa-var-chart-pie-simple,
	chart-pie-alt: $fa-var-chart-pie-alt,
	chart-pyramid: $fa-var-chart-pyramid,
	chart-radar: $fa-var-chart-radar,
	chart-scatter: $fa-var-chart-scatter,
	chart-scatter-3d: $fa-var-chart-scatter-3d,
	chart-scatter-bubble: $fa-var-chart-scatter-bubble,
	chart-tree-map: $fa-var-chart-tree-map,
	chart-user: $fa-var-chart-user,
	user-chart: $fa-var-user-chart,
	chart-waterfall: $fa-var-chart-waterfall,
	check: $fa-var-check,
	check-double: $fa-var-check-double,
	check-to-slot: $fa-var-check-to-slot,
	vote-yea: $fa-var-vote-yea,
	cheese: $fa-var-cheese,
	cheese-swiss: $fa-var-cheese-swiss,
	cherries: $fa-var-cherries,
	chess: $fa-var-chess,
	chess-bishop: $fa-var-chess-bishop,
	chess-bishop-piece: $fa-var-chess-bishop-piece,
	chess-bishop-alt: $fa-var-chess-bishop-alt,
	chess-board: $fa-var-chess-board,
	chess-clock: $fa-var-chess-clock,
	chess-clock-flip: $fa-var-chess-clock-flip,
	chess-clock-alt: $fa-var-chess-clock-alt,
	chess-king: $fa-var-chess-king,
	chess-king-piece: $fa-var-chess-king-piece,
	chess-king-alt: $fa-var-chess-king-alt,
	chess-knight: $fa-var-chess-knight,
	chess-knight-piece: $fa-var-chess-knight-piece,
	chess-knight-alt: $fa-var-chess-knight-alt,
	chess-pawn: $fa-var-chess-pawn,
	chess-pawn-piece: $fa-var-chess-pawn-piece,
	chess-pawn-alt: $fa-var-chess-pawn-alt,
	chess-queen: $fa-var-chess-queen,
	chess-queen-piece: $fa-var-chess-queen-piece,
	chess-queen-alt: $fa-var-chess-queen-alt,
	chess-rook: $fa-var-chess-rook,
	chess-rook-piece: $fa-var-chess-rook-piece,
	chess-rook-alt: $fa-var-chess-rook-alt,
	chevron-down: $fa-var-chevron-down,
	chevron-left: $fa-var-chevron-left,
	chevron-right: $fa-var-chevron-right,
	chevron-up: $fa-var-chevron-up,
	chevrons-down: $fa-var-chevrons-down,
	chevron-double-down: $fa-var-chevron-double-down,
	chevrons-left: $fa-var-chevrons-left,
	chevron-double-left: $fa-var-chevron-double-left,
	chevrons-right: $fa-var-chevrons-right,
	chevron-double-right: $fa-var-chevron-double-right,
	chevrons-up: $fa-var-chevrons-up,
	chevron-double-up: $fa-var-chevron-double-up,
	child: $fa-var-child,
	chimney: $fa-var-chimney,
	church: $fa-var-church,
	circle: $fa-var-circle,
	circle-0: $fa-var-circle-0,
	circle-1: $fa-var-circle-1,
	circle-2: $fa-var-circle-2,
	circle-3: $fa-var-circle-3,
	circle-4: $fa-var-circle-4,
	circle-5: $fa-var-circle-5,
	circle-6: $fa-var-circle-6,
	circle-7: $fa-var-circle-7,
	circle-8: $fa-var-circle-8,
	circle-9: $fa-var-circle-9,
	circle-a: $fa-var-circle-a,
	circle-ampersand: $fa-var-circle-ampersand,
	circle-arrow-down: $fa-var-circle-arrow-down,
	arrow-circle-down: $fa-var-arrow-circle-down,
	circle-arrow-down-left: $fa-var-circle-arrow-down-left,
	circle-arrow-down-right: $fa-var-circle-arrow-down-right,
	circle-arrow-left: $fa-var-circle-arrow-left,
	arrow-circle-left: $fa-var-arrow-circle-left,
	circle-arrow-right: $fa-var-circle-arrow-right,
	arrow-circle-right: $fa-var-arrow-circle-right,
	circle-arrow-up: $fa-var-circle-arrow-up,
	arrow-circle-up: $fa-var-arrow-circle-up,
	circle-arrow-up-left: $fa-var-circle-arrow-up-left,
	circle-arrow-up-right: $fa-var-circle-arrow-up-right,
	circle-b: $fa-var-circle-b,
	circle-bolt: $fa-var-circle-bolt,
	circle-book-open: $fa-var-circle-book-open,
	book-circle: $fa-var-book-circle,
	circle-bookmark: $fa-var-circle-bookmark,
	bookmark-circle: $fa-var-bookmark-circle,
	circle-c: $fa-var-circle-c,
	circle-calendar: $fa-var-circle-calendar,
	calendar-circle: $fa-var-calendar-circle,
	circle-camera: $fa-var-circle-camera,
	camera-circle: $fa-var-camera-circle,
	circle-caret-down: $fa-var-circle-caret-down,
	caret-circle-down: $fa-var-caret-circle-down,
	circle-caret-left: $fa-var-circle-caret-left,
	caret-circle-left: $fa-var-caret-circle-left,
	circle-caret-right: $fa-var-circle-caret-right,
	caret-circle-right: $fa-var-caret-circle-right,
	circle-caret-up: $fa-var-circle-caret-up,
	caret-circle-up: $fa-var-caret-circle-up,
	circle-check: $fa-var-circle-check,
	check-circle: $fa-var-check-circle,
	circle-chevron-down: $fa-var-circle-chevron-down,
	chevron-circle-down: $fa-var-chevron-circle-down,
	circle-chevron-left: $fa-var-circle-chevron-left,
	chevron-circle-left: $fa-var-chevron-circle-left,
	circle-chevron-right: $fa-var-circle-chevron-right,
	chevron-circle-right: $fa-var-chevron-circle-right,
	circle-chevron-up: $fa-var-circle-chevron-up,
	chevron-circle-up: $fa-var-chevron-circle-up,
	circle-d: $fa-var-circle-d,
	circle-dashed: $fa-var-circle-dashed,
	circle-divide: $fa-var-circle-divide,
	circle-dollar: $fa-var-circle-dollar,
	usd-circle: $fa-var-usd-circle,
	dollar-circle: $fa-var-dollar-circle,
	circle-dollar-to-slot: $fa-var-circle-dollar-to-slot,
	donate: $fa-var-donate,
	circle-dot: $fa-var-circle-dot,
	dot-circle: $fa-var-dot-circle,
	circle-down: $fa-var-circle-down,
	arrow-alt-circle-down: $fa-var-arrow-alt-circle-down,
	circle-down-left: $fa-var-circle-down-left,
	circle-down-right: $fa-var-circle-down-right,
	circle-e: $fa-var-circle-e,
	circle-envelope: $fa-var-circle-envelope,
	envelope-circle: $fa-var-envelope-circle,
	circle-exclamation: $fa-var-circle-exclamation,
	exclamation-circle: $fa-var-exclamation-circle,
	circle-exclamation-check: $fa-var-circle-exclamation-check,
	circle-f: $fa-var-circle-f,
	circle-g: $fa-var-circle-g,
	circle-h: $fa-var-circle-h,
	hospital-symbol: $fa-var-hospital-symbol,
	circle-half: $fa-var-circle-half,
	circle-half-stroke: $fa-var-circle-half-stroke,
	adjust: $fa-var-adjust,
	circle-heart: $fa-var-circle-heart,
	heart-circle: $fa-var-heart-circle,
	circle-i: $fa-var-circle-i,
	circle-info: $fa-var-circle-info,
	info-circle: $fa-var-info-circle,
	circle-j: $fa-var-circle-j,
	circle-k: $fa-var-circle-k,
	circle-l: $fa-var-circle-l,
	circle-left: $fa-var-circle-left,
	arrow-alt-circle-left: $fa-var-arrow-alt-circle-left,
	circle-location-arrow: $fa-var-circle-location-arrow,
	location-circle: $fa-var-location-circle,
	circle-m: $fa-var-circle-m,
	circle-microphone: $fa-var-circle-microphone,
	microphone-circle: $fa-var-microphone-circle,
	circle-microphone-lines: $fa-var-circle-microphone-lines,
	microphone-circle-alt: $fa-var-microphone-circle-alt,
	circle-minus: $fa-var-circle-minus,
	minus-circle: $fa-var-minus-circle,
	circle-n: $fa-var-circle-n,
	circle-notch: $fa-var-circle-notch,
	circle-o: $fa-var-circle-o,
	circle-p: $fa-var-circle-p,
	circle-parking: $fa-var-circle-parking,
	parking-circle: $fa-var-parking-circle,
	circle-pause: $fa-var-circle-pause,
	pause-circle: $fa-var-pause-circle,
	circle-phone: $fa-var-circle-phone,
	phone-circle: $fa-var-phone-circle,
	circle-phone-flip: $fa-var-circle-phone-flip,
	phone-circle-alt: $fa-var-phone-circle-alt,
	circle-phone-hangup: $fa-var-circle-phone-hangup,
	phone-circle-down: $fa-var-phone-circle-down,
	circle-play: $fa-var-circle-play,
	play-circle: $fa-var-play-circle,
	circle-plus: $fa-var-circle-plus,
	plus-circle: $fa-var-plus-circle,
	circle-q: $fa-var-circle-q,
	circle-quarter: $fa-var-circle-quarter,
	circle-question: $fa-var-circle-question,
	question-circle: $fa-var-question-circle,
	circle-r: $fa-var-circle-r,
	circle-radiation: $fa-var-circle-radiation,
	radiation-alt: $fa-var-radiation-alt,
	circle-right: $fa-var-circle-right,
	arrow-alt-circle-right: $fa-var-arrow-alt-circle-right,
	circle-s: $fa-var-circle-s,
	circle-small: $fa-var-circle-small,
	circle-sort: $fa-var-circle-sort,
	sort-circle: $fa-var-sort-circle,
	circle-sort-down: $fa-var-circle-sort-down,
	sort-circle-down: $fa-var-sort-circle-down,
	circle-sort-up: $fa-var-circle-sort-up,
	sort-circle-up: $fa-var-sort-circle-up,
	circle-star: $fa-var-circle-star,
	star-circle: $fa-var-star-circle,
	circle-stop: $fa-var-circle-stop,
	stop-circle: $fa-var-stop-circle,
	circle-t: $fa-var-circle-t,
	circle-three-quarters: $fa-var-circle-three-quarters,
	circle-trash: $fa-var-circle-trash,
	trash-circle: $fa-var-trash-circle,
	circle-u: $fa-var-circle-u,
	circle-up: $fa-var-circle-up,
	arrow-alt-circle-up: $fa-var-arrow-alt-circle-up,
	circle-up-left: $fa-var-circle-up-left,
	circle-up-right: $fa-var-circle-up-right,
	circle-user: $fa-var-circle-user,
	user-circle: $fa-var-user-circle,
	circle-v: $fa-var-circle-v,
	circle-video: $fa-var-circle-video,
	video-circle: $fa-var-video-circle,
	circle-w: $fa-var-circle-w,
	circle-waveform-lines: $fa-var-circle-waveform-lines,
	waveform-circle: $fa-var-waveform-circle,
	circle-x: $fa-var-circle-x,
	circle-xmark: $fa-var-circle-xmark,
	times-circle: $fa-var-times-circle,
	xmark-circle: $fa-var-xmark-circle,
	circle-y: $fa-var-circle-y,
	circle-z: $fa-var-circle-z,
	city: $fa-var-city,
	clapperboard: $fa-var-clapperboard,
	clapperboard-play: $fa-var-clapperboard-play,
	clarinet: $fa-var-clarinet,
	claw-marks: $fa-var-claw-marks,
	clipboard: $fa-var-clipboard,
	clipboard-check: $fa-var-clipboard-check,
	clipboard-list: $fa-var-clipboard-list,
	clipboard-list-check: $fa-var-clipboard-list-check,
	clipboard-medical: $fa-var-clipboard-medical,
	clipboard-prescription: $fa-var-clipboard-prescription,
	clipboard-user: $fa-var-clipboard-user,
	clock: $fa-var-clock,
	clock-desk: $fa-var-clock-desk,
	clock-rotate-left: $fa-var-clock-rotate-left,
	history: $fa-var-history,
	clone: $fa-var-clone,
	closed-captioning: $fa-var-closed-captioning,
	closed-captioning-slash: $fa-var-closed-captioning-slash,
	clothes-hanger: $fa-var-clothes-hanger,
	cloud: $fa-var-cloud,
	cloud-arrow-down: $fa-var-cloud-arrow-down,
	cloud-download: $fa-var-cloud-download,
	cloud-download-alt: $fa-var-cloud-download-alt,
	cloud-arrow-up: $fa-var-cloud-arrow-up,
	cloud-upload: $fa-var-cloud-upload,
	cloud-upload-alt: $fa-var-cloud-upload-alt,
	cloud-bolt: $fa-var-cloud-bolt,
	thunderstorm: $fa-var-thunderstorm,
	cloud-bolt-moon: $fa-var-cloud-bolt-moon,
	thunderstorm-moon: $fa-var-thunderstorm-moon,
	cloud-bolt-sun: $fa-var-cloud-bolt-sun,
	thunderstorm-sun: $fa-var-thunderstorm-sun,
	cloud-drizzle: $fa-var-cloud-drizzle,
	cloud-fog: $fa-var-cloud-fog,
	fog: $fa-var-fog,
	cloud-hail: $fa-var-cloud-hail,
	cloud-hail-mixed: $fa-var-cloud-hail-mixed,
	cloud-meatball: $fa-var-cloud-meatball,
	cloud-moon: $fa-var-cloud-moon,
	cloud-moon-rain: $fa-var-cloud-moon-rain,
	cloud-music: $fa-var-cloud-music,
	cloud-rain: $fa-var-cloud-rain,
	cloud-rainbow: $fa-var-cloud-rainbow,
	cloud-showers: $fa-var-cloud-showers,
	cloud-showers-heavy: $fa-var-cloud-showers-heavy,
	cloud-slash: $fa-var-cloud-slash,
	cloud-sleet: $fa-var-cloud-sleet,
	cloud-snow: $fa-var-cloud-snow,
	cloud-sun: $fa-var-cloud-sun,
	cloud-sun-rain: $fa-var-cloud-sun-rain,
	cloud-word: $fa-var-cloud-word,
	clouds: $fa-var-clouds,
	clouds-moon: $fa-var-clouds-moon,
	clouds-sun: $fa-var-clouds-sun,
	clover: $fa-var-clover,
	club: $fa-var-club,
	code: $fa-var-code,
	code-branch: $fa-var-code-branch,
	code-commit: $fa-var-code-commit,
	code-compare: $fa-var-code-compare,
	code-fork: $fa-var-code-fork,
	code-merge: $fa-var-code-merge,
	code-pull-request: $fa-var-code-pull-request,
	code-simple: $fa-var-code-simple,
	coffee-bean: $fa-var-coffee-bean,
	coffee-beans: $fa-var-coffee-beans,
	coffee-pot: $fa-var-coffee-pot,
	coffin: $fa-var-coffin,
	coffin-cross: $fa-var-coffin-cross,
	coin: $fa-var-coin,
	coins: $fa-var-coins,
	colon-sign: $fa-var-colon-sign,
	comet: $fa-var-comet,
	command: $fa-var-command,
	comment: $fa-var-comment,
	comment-arrow-down: $fa-var-comment-arrow-down,
	comment-arrow-up: $fa-var-comment-arrow-up,
	comment-arrow-up-right: $fa-var-comment-arrow-up-right,
	comment-captions: $fa-var-comment-captions,
	comment-check: $fa-var-comment-check,
	comment-code: $fa-var-comment-code,
	comment-dollar: $fa-var-comment-dollar,
	comment-dots: $fa-var-comment-dots,
	commenting: $fa-var-commenting,
	comment-exclamation: $fa-var-comment-exclamation,
	comment-image: $fa-var-comment-image,
	comment-lines: $fa-var-comment-lines,
	comment-medical: $fa-var-comment-medical,
	comment-middle: $fa-var-comment-middle,
	comment-middle-top: $fa-var-comment-middle-top,
	comment-minus: $fa-var-comment-minus,
	comment-music: $fa-var-comment-music,
	comment-pen: $fa-var-comment-pen,
	comment-edit: $fa-var-comment-edit,
	comment-plus: $fa-var-comment-plus,
	comment-question: $fa-var-comment-question,
	comment-quote: $fa-var-comment-quote,
	comment-slash: $fa-var-comment-slash,
	comment-smile: $fa-var-comment-smile,
	comment-sms: $fa-var-comment-sms,
	sms: $fa-var-sms,
	comment-text: $fa-var-comment-text,
	comment-xmark: $fa-var-comment-xmark,
	comment-times: $fa-var-comment-times,
	comments: $fa-var-comments,
	comments-dollar: $fa-var-comments-dollar,
	comments-question: $fa-var-comments-question,
	comments-question-check: $fa-var-comments-question-check,
	compact-disc: $fa-var-compact-disc,
	compass: $fa-var-compass,
	compass-drafting: $fa-var-compass-drafting,
	drafting-compass: $fa-var-drafting-compass,
	compass-slash: $fa-var-compass-slash,
	compress: $fa-var-compress,
	compress-wide: $fa-var-compress-wide,
	computer-classic: $fa-var-computer-classic,
	computer-mouse: $fa-var-computer-mouse,
	mouse: $fa-var-mouse,
	computer-mouse-scrollwheel: $fa-var-computer-mouse-scrollwheel,
	mouse-alt: $fa-var-mouse-alt,
	computer-speaker: $fa-var-computer-speaker,
	container-storage: $fa-var-container-storage,
	conveyor-belt: $fa-var-conveyor-belt,
	conveyor-belt-boxes: $fa-var-conveyor-belt-boxes,
	conveyor-belt-alt: $fa-var-conveyor-belt-alt,
	conveyor-belt-empty: $fa-var-conveyor-belt-empty,
	cookie: $fa-var-cookie,
	cookie-bite: $fa-var-cookie-bite,
	copy: $fa-var-copy,
	copyright: $fa-var-copyright,
	corn: $fa-var-corn,
	couch: $fa-var-couch,
	cow: $fa-var-cow,
	cowbell: $fa-var-cowbell,
	cowbell-circle-plus: $fa-var-cowbell-circle-plus,
	cowbell-more: $fa-var-cowbell-more,
	credit-card: $fa-var-credit-card,
	credit-card-alt: $fa-var-credit-card-alt,
	credit-card-blank: $fa-var-credit-card-blank,
	credit-card-front: $fa-var-credit-card-front,
	cricket-bat-ball: $fa-var-cricket-bat-ball,
	cricket: $fa-var-cricket,
	croissant: $fa-var-croissant,
	crop: $fa-var-crop,
	crop-simple: $fa-var-crop-simple,
	crop-alt: $fa-var-crop-alt,
	cross: $fa-var-cross,
	crosshairs: $fa-var-crosshairs,
	crow: $fa-var-crow,
	crown: $fa-var-crown,
	crutch: $fa-var-crutch,
	crutches: $fa-var-crutches,
	cruzeiro-sign: $fa-var-cruzeiro-sign,
	cube: $fa-var-cube,
	cubes: $fa-var-cubes,
	cup-togo: $fa-var-cup-togo,
	coffee-togo: $fa-var-coffee-togo,
	curling-stone: $fa-var-curling-stone,
	curling: $fa-var-curling,
	d: $fa-var-d,
	dagger: $fa-var-dagger,
	database: $fa-var-database,
	deer: $fa-var-deer,
	deer-rudolph: $fa-var-deer-rudolph,
	delete-left: $fa-var-delete-left,
	backspace: $fa-var-backspace,
	delete-right: $fa-var-delete-right,
	democrat: $fa-var-democrat,
	desktop: $fa-var-desktop,
	desktop-alt: $fa-var-desktop-alt,
	desktop-arrow-down: $fa-var-desktop-arrow-down,
	dharmachakra: $fa-var-dharmachakra,
	diagram-lean-canvas: $fa-var-diagram-lean-canvas,
	diagram-nested: $fa-var-diagram-nested,
	diagram-project: $fa-var-diagram-project,
	project-diagram: $fa-var-project-diagram,
	diagram-sankey: $fa-var-diagram-sankey,
	diagram-stankey: $fa-var-diagram-stankey,
	diagram-venn: $fa-var-diagram-venn,
	dial: $fa-var-dial,
	dial-med-high: $fa-var-dial-med-high,
	dial-high: $fa-var-dial-high,
	dial-low: $fa-var-dial-low,
	dial-max: $fa-var-dial-max,
	dial-med: $fa-var-dial-med,
	dial-med-low: $fa-var-dial-med-low,
	dial-min: $fa-var-dial-min,
	diamond: $fa-var-diamond,
	diamond-turn-right: $fa-var-diamond-turn-right,
	directions: $fa-var-directions,
	dice: $fa-var-dice,
	dice-d10: $fa-var-dice-d10,
	dice-d12: $fa-var-dice-d12,
	dice-d20: $fa-var-dice-d20,
	dice-d4: $fa-var-dice-d4,
	dice-d6: $fa-var-dice-d6,
	dice-d8: $fa-var-dice-d8,
	dice-five: $fa-var-dice-five,
	dice-four: $fa-var-dice-four,
	dice-one: $fa-var-dice-one,
	dice-six: $fa-var-dice-six,
	dice-three: $fa-var-dice-three,
	dice-two: $fa-var-dice-two,
	diploma: $fa-var-diploma,
	scroll-ribbon: $fa-var-scroll-ribbon,
	disc-drive: $fa-var-disc-drive,
	disease: $fa-var-disease,
	display: $fa-var-display,
	display-arrow-down: $fa-var-display-arrow-down,
	display-code: $fa-var-display-code,
	desktop-code: $fa-var-desktop-code,
	display-medical: $fa-var-display-medical,
	desktop-medical: $fa-var-desktop-medical,
	display-slash: $fa-var-display-slash,
	desktop-slash: $fa-var-desktop-slash,
	divide: $fa-var-divide,
	dna: $fa-var-dna,
	do-not-enter: $fa-var-do-not-enter,
	dog: $fa-var-dog,
	dog-leashed: $fa-var-dog-leashed,
	dollar-sign: $fa-var-dollar-sign,
	dollar: $fa-var-dollar,
	usd: $fa-var-usd,
	dolly: $fa-var-dolly,
	dolly-box: $fa-var-dolly-box,
	dolly-empty: $fa-var-dolly-empty,
	dolphin: $fa-var-dolphin,
	dong-sign: $fa-var-dong-sign,
	door-closed: $fa-var-door-closed,
	door-open: $fa-var-door-open,
	dove: $fa-var-dove,
	down: $fa-var-down,
	arrow-alt-down: $fa-var-arrow-alt-down,
	down-from-line: $fa-var-down-from-line,
	arrow-alt-from-top: $fa-var-arrow-alt-from-top,
	down-left: $fa-var-down-left,
	down-left-and-up-right-to-center: $fa-var-down-left-and-up-right-to-center,
	compress-alt: $fa-var-compress-alt,
	down-long: $fa-var-down-long,
	long-arrow-alt-down: $fa-var-long-arrow-alt-down,
	down-right: $fa-var-down-right,
	down-to-line: $fa-var-down-to-line,
	arrow-alt-to-bottom: $fa-var-arrow-alt-to-bottom,
	download: $fa-var-download,
	dragon: $fa-var-dragon,
	draw-circle: $fa-var-draw-circle,
	draw-polygon: $fa-var-draw-polygon,
	draw-square: $fa-var-draw-square,
	dreidel: $fa-var-dreidel,
	drone: $fa-var-drone,
	drone-front: $fa-var-drone-front,
	drone-alt: $fa-var-drone-alt,
	droplet: $fa-var-droplet,
	tint: $fa-var-tint,
	droplet-degree: $fa-var-droplet-degree,
	dewpoint: $fa-var-dewpoint,
	droplet-percent: $fa-var-droplet-percent,
	humidity: $fa-var-humidity,
	droplet-slash: $fa-var-droplet-slash,
	tint-slash: $fa-var-tint-slash,
	drum: $fa-var-drum,
	drum-steelpan: $fa-var-drum-steelpan,
	drumstick: $fa-var-drumstick,
	drumstick-bite: $fa-var-drumstick-bite,
	dryer: $fa-var-dryer,
	dryer-heat: $fa-var-dryer-heat,
	dryer-alt: $fa-var-dryer-alt,
	duck: $fa-var-duck,
	dumbbell: $fa-var-dumbbell,
	dumpster: $fa-var-dumpster,
	dumpster-fire: $fa-var-dumpster-fire,
	dungeon: $fa-var-dungeon,
	e: $fa-var-e,
	ear: $fa-var-ear,
	ear-deaf: $fa-var-ear-deaf,
	deaf: $fa-var-deaf,
	deafness: $fa-var-deafness,
	hard-of-hearing: $fa-var-hard-of-hearing,
	ear-listen: $fa-var-ear-listen,
	assistive-listening-systems: $fa-var-assistive-listening-systems,
	ear-muffs: $fa-var-ear-muffs,
	earth-africa: $fa-var-earth-africa,
	globe-africa: $fa-var-globe-africa,
	earth-americas: $fa-var-earth-americas,
	earth: $fa-var-earth,
	globe-americas: $fa-var-globe-americas,
	earth-asia: $fa-var-earth-asia,
	globe-asia: $fa-var-globe-asia,
	earth-europa: $fa-var-earth-europa,
	globe-europe: $fa-var-globe-europe,
	eclipse: $fa-var-eclipse,
	egg: $fa-var-egg,
	egg-fried: $fa-var-egg-fried,
	eggplant: $fa-var-eggplant,
	eject: $fa-var-eject,
	elephant: $fa-var-elephant,
	elevator: $fa-var-elevator,
	ellipsis: $fa-var-ellipsis,
	ellipsis-h: $fa-var-ellipsis-h,
	ellipsis-stroke: $fa-var-ellipsis-stroke,
	ellipsis-h-alt: $fa-var-ellipsis-h-alt,
	ellipsis-stroke-vertical: $fa-var-ellipsis-stroke-vertical,
	ellipsis-v-alt: $fa-var-ellipsis-v-alt,
	ellipsis-vertical: $fa-var-ellipsis-vertical,
	ellipsis-v: $fa-var-ellipsis-v,
	empty-set: $fa-var-empty-set,
	engine: $fa-var-engine,
	engine-warning: $fa-var-engine-warning,
	engine-exclamation: $fa-var-engine-exclamation,
	envelope: $fa-var-envelope,
	envelope-dot: $fa-var-envelope-dot,
	envelope-badge: $fa-var-envelope-badge,
	envelope-open: $fa-var-envelope-open,
	envelope-open-dollar: $fa-var-envelope-open-dollar,
	envelope-open-text: $fa-var-envelope-open-text,
	envelopes: $fa-var-envelopes,
	envelopes-bulk: $fa-var-envelopes-bulk,
	mail-bulk: $fa-var-mail-bulk,
	equals: $fa-var-equals,
	eraser: $fa-var-eraser,
	escalator: $fa-var-escalator,
	ethernet: $fa-var-ethernet,
	euro-sign: $fa-var-euro-sign,
	euro: $fa-var-euro,
	eur: $fa-var-eur,
	exclamation: $fa-var-exclamation,
	expand: $fa-var-expand,
	expand-wide: $fa-var-expand-wide,
	eye: $fa-var-eye,
	eye-dropper: $fa-var-eye-dropper,
	eye-dropper-empty: $fa-var-eye-dropper-empty,
	eyedropper: $fa-var-eyedropper,
	eye-dropper-full: $fa-var-eye-dropper-full,
	eye-dropper-half: $fa-var-eye-dropper-half,
	eye-evil: $fa-var-eye-evil,
	eye-low-vision: $fa-var-eye-low-vision,
	low-vision: $fa-var-low-vision,
	eye-slash: $fa-var-eye-slash,
	f: $fa-var-f,
	face-angry: $fa-var-face-angry,
	angry: $fa-var-angry,
	face-dizzy: $fa-var-face-dizzy,
	dizzy: $fa-var-dizzy,
	face-flushed: $fa-var-face-flushed,
	flushed: $fa-var-flushed,
	face-frown: $fa-var-face-frown,
	frown: $fa-var-frown,
	face-frown-open: $fa-var-face-frown-open,
	frown-open: $fa-var-frown-open,
	face-grimace: $fa-var-face-grimace,
	grimace: $fa-var-grimace,
	face-grin: $fa-var-face-grin,
	grin: $fa-var-grin,
	face-grin-beam: $fa-var-face-grin-beam,
	grin-beam: $fa-var-grin-beam,
	face-grin-beam-sweat: $fa-var-face-grin-beam-sweat,
	grin-beam-sweat: $fa-var-grin-beam-sweat,
	face-grin-hearts: $fa-var-face-grin-hearts,
	grin-hearts: $fa-var-grin-hearts,
	face-grin-squint: $fa-var-face-grin-squint,
	grin-squint: $fa-var-grin-squint,
	face-grin-squint-tears: $fa-var-face-grin-squint-tears,
	grin-squint-tears: $fa-var-grin-squint-tears,
	face-grin-stars: $fa-var-face-grin-stars,
	grin-stars: $fa-var-grin-stars,
	face-grin-tears: $fa-var-face-grin-tears,
	grin-tears: $fa-var-grin-tears,
	face-grin-tongue: $fa-var-face-grin-tongue,
	grin-tongue: $fa-var-grin-tongue,
	face-grin-tongue-squint: $fa-var-face-grin-tongue-squint,
	grin-tongue-squint: $fa-var-grin-tongue-squint,
	face-grin-tongue-wink: $fa-var-face-grin-tongue-wink,
	grin-tongue-wink: $fa-var-grin-tongue-wink,
	face-grin-wide: $fa-var-face-grin-wide,
	grin-alt: $fa-var-grin-alt,
	face-grin-wink: $fa-var-face-grin-wink,
	grin-wink: $fa-var-grin-wink,
	face-kiss: $fa-var-face-kiss,
	kiss: $fa-var-kiss,
	face-kiss-beam: $fa-var-face-kiss-beam,
	kiss-beam: $fa-var-kiss-beam,
	face-kiss-wink-heart: $fa-var-face-kiss-wink-heart,
	kiss-wink-heart: $fa-var-kiss-wink-heart,
	face-laugh: $fa-var-face-laugh,
	laugh: $fa-var-laugh,
	face-laugh-beam: $fa-var-face-laugh-beam,
	laugh-beam: $fa-var-laugh-beam,
	face-laugh-squint: $fa-var-face-laugh-squint,
	laugh-squint: $fa-var-laugh-squint,
	face-laugh-wink: $fa-var-face-laugh-wink,
	laugh-wink: $fa-var-laugh-wink,
	face-meh: $fa-var-face-meh,
	meh: $fa-var-meh,
	face-meh-blank: $fa-var-face-meh-blank,
	meh-blank: $fa-var-meh-blank,
	face-rolling-eyes: $fa-var-face-rolling-eyes,
	meh-rolling-eyes: $fa-var-meh-rolling-eyes,
	face-sad-cry: $fa-var-face-sad-cry,
	sad-cry: $fa-var-sad-cry,
	face-sad-tear: $fa-var-face-sad-tear,
	sad-tear: $fa-var-sad-tear,
	face-smile: $fa-var-face-smile,
	smile: $fa-var-smile,
	face-smile-beam: $fa-var-face-smile-beam,
	smile-beam: $fa-var-smile-beam,
	face-smile-plus: $fa-var-face-smile-plus,
	smile-plus: $fa-var-smile-plus,
	face-smile-wink: $fa-var-face-smile-wink,
	smile-wink: $fa-var-smile-wink,
	face-surprise: $fa-var-face-surprise,
	surprise: $fa-var-surprise,
	face-tired: $fa-var-face-tired,
	tired: $fa-var-tired,
	fan: $fa-var-fan,
	fan-table: $fa-var-fan-table,
	farm: $fa-var-farm,
	barn-silo: $fa-var-barn-silo,
	faucet: $fa-var-faucet,
	faucet-drip: $fa-var-faucet-drip,
	fax: $fa-var-fax,
	feather: $fa-var-feather,
	feather-pointed: $fa-var-feather-pointed,
	feather-alt: $fa-var-feather-alt,
	fence: $fa-var-fence,
	field-hockey-stick-ball: $fa-var-field-hockey-stick-ball,
	field-hockey: $fa-var-field-hockey,
	file: $fa-var-file,
	file-arrow-down: $fa-var-file-arrow-down,
	file-download: $fa-var-file-download,
	file-arrow-up: $fa-var-file-arrow-up,
	file-upload: $fa-var-file-upload,
	file-audio: $fa-var-file-audio,
	file-binary: $fa-var-file-binary,
	file-certificate: $fa-var-file-certificate,
	file-award: $fa-var-file-award,
	file-chart-column: $fa-var-file-chart-column,
	file-chart-line: $fa-var-file-chart-line,
	file-chart-pie: $fa-var-file-chart-pie,
	file-check: $fa-var-file-check,
	file-code: $fa-var-file-code,
	file-contract: $fa-var-file-contract,
	file-csv: $fa-var-file-csv,
	file-dashed-line: $fa-var-file-dashed-line,
	page-break: $fa-var-page-break,
	file-excel: $fa-var-file-excel,
	file-exclamation: $fa-var-file-exclamation,
	file-export: $fa-var-file-export,
	arrow-right-from-file: $fa-var-arrow-right-from-file,
	file-heart: $fa-var-file-heart,
	file-image: $fa-var-file-image,
	file-import: $fa-var-file-import,
	arrow-right-to-file: $fa-var-arrow-right-to-file,
	file-invoice: $fa-var-file-invoice,
	file-invoice-dollar: $fa-var-file-invoice-dollar,
	file-lines: $fa-var-file-lines,
	file-alt: $fa-var-file-alt,
	file-text: $fa-var-file-text,
	file-magnifying-glass: $fa-var-file-magnifying-glass,
	file-search: $fa-var-file-search,
	file-medical: $fa-var-file-medical,
	file-minus: $fa-var-file-minus,
	file-music: $fa-var-file-music,
	file-pdf: $fa-var-file-pdf,
	file-pen: $fa-var-file-pen,
	file-edit: $fa-var-file-edit,
	file-plus: $fa-var-file-plus,
	file-plus-minus: $fa-var-file-plus-minus,
	file-powerpoint: $fa-var-file-powerpoint,
	file-prescription: $fa-var-file-prescription,
	file-signature: $fa-var-file-signature,
	file-spreadsheet: $fa-var-file-spreadsheet,
	file-user: $fa-var-file-user,
	file-video: $fa-var-file-video,
	file-waveform: $fa-var-file-waveform,
	file-medical-alt: $fa-var-file-medical-alt,
	file-word: $fa-var-file-word,
	file-xmark: $fa-var-file-xmark,
	file-times: $fa-var-file-times,
	file-zipper: $fa-var-file-zipper,
	file-archive: $fa-var-file-archive,
	files: $fa-var-files,
	files-medical: $fa-var-files-medical,
	fill: $fa-var-fill,
	fill-drip: $fa-var-fill-drip,
	film: $fa-var-film,
	film-canister: $fa-var-film-canister,
	film-simple: $fa-var-film-simple,
	film-alt: $fa-var-film-alt,
	film-slash: $fa-var-film-slash,
	films: $fa-var-films,
	filter: $fa-var-filter,
	filter-circle-dollar: $fa-var-filter-circle-dollar,
	funnel-dollar: $fa-var-funnel-dollar,
	filter-circle-xmark: $fa-var-filter-circle-xmark,
	filter-list: $fa-var-filter-list,
	filter-slash: $fa-var-filter-slash,
	filters: $fa-var-filters,
	fingerprint: $fa-var-fingerprint,
	fire: $fa-var-fire,
	fire-extinguisher: $fa-var-fire-extinguisher,
	fire-flame: $fa-var-fire-flame,
	flame: $fa-var-flame,
	fire-flame-curved: $fa-var-fire-flame-curved,
	fire-alt: $fa-var-fire-alt,
	fire-flame-simple: $fa-var-fire-flame-simple,
	burn: $fa-var-burn,
	fire-hydrant: $fa-var-fire-hydrant,
	fire-smoke: $fa-var-fire-smoke,
	fireplace: $fa-var-fireplace,
	fish: $fa-var-fish,
	fish-cooked: $fa-var-fish-cooked,
	flag: $fa-var-flag,
	flag-checkered: $fa-var-flag-checkered,
	flag-pennant: $fa-var-flag-pennant,
	pennant: $fa-var-pennant,
	flag-swallowtail: $fa-var-flag-swallowtail,
	flag-alt: $fa-var-flag-alt,
	flag-usa: $fa-var-flag-usa,
	flashlight: $fa-var-flashlight,
	flask: $fa-var-flask,
	flask-round-poison: $fa-var-flask-round-poison,
	flask-poison: $fa-var-flask-poison,
	flask-round-potion: $fa-var-flask-round-potion,
	flask-potion: $fa-var-flask-potion,
	floppy-disk: $fa-var-floppy-disk,
	save: $fa-var-save,
	floppy-disk-circle-arrow-right: $fa-var-floppy-disk-circle-arrow-right,
	save-circle-arrow-right: $fa-var-save-circle-arrow-right,
	floppy-disk-circle-xmark: $fa-var-floppy-disk-circle-xmark,
	floppy-disk-times: $fa-var-floppy-disk-times,
	save-times: $fa-var-save-times,
	save-circle-xmark: $fa-var-save-circle-xmark,
	floppy-disk-pen: $fa-var-floppy-disk-pen,
	floppy-disks: $fa-var-floppy-disks,
	florin-sign: $fa-var-florin-sign,
	flower: $fa-var-flower,
	flower-daffodil: $fa-var-flower-daffodil,
	flower-tulip: $fa-var-flower-tulip,
	flute: $fa-var-flute,
	flux-capacitor: $fa-var-flux-capacitor,
	folder: $fa-var-folder,
	folder-arrow-down: $fa-var-folder-arrow-down,
	folder-download: $fa-var-folder-download,
	folder-arrow-up: $fa-var-folder-arrow-up,
	folder-upload: $fa-var-folder-upload,
	folder-blank: $fa-var-folder-blank,
	folder-bookmark: $fa-var-folder-bookmark,
	folder-gear: $fa-var-folder-gear,
	folder-cog: $fa-var-folder-cog,
	folder-grid: $fa-var-folder-grid,
	folder-heart: $fa-var-folder-heart,
	folder-image: $fa-var-folder-image,
	folder-magnifying-glass: $fa-var-folder-magnifying-glass,
	folder-search: $fa-var-folder-search,
	folder-medical: $fa-var-folder-medical,
	folder-minus: $fa-var-folder-minus,
	folder-music: $fa-var-folder-music,
	folder-open: $fa-var-folder-open,
	folder-plus: $fa-var-folder-plus,
	folder-tree: $fa-var-folder-tree,
	folder-user: $fa-var-folder-user,
	folder-xmark: $fa-var-folder-xmark,
	folder-times: $fa-var-folder-times,
	folders: $fa-var-folders,
	font: $fa-var-font,
	font-case: $fa-var-font-case,
	football-ball: $fa-var-football-ball,
	football-helmet: $fa-var-football-helmet,
	fork: $fa-var-fork,
	utensil-fork: $fa-var-utensil-fork,
	fork-knife: $fa-var-fork-knife,
	utensils-alt: $fa-var-utensils-alt,
	forklift: $fa-var-forklift,
	forward: $fa-var-forward,
	forward-fast: $fa-var-forward-fast,
	fast-forward: $fa-var-fast-forward,
	forward-step: $fa-var-forward-step,
	step-forward: $fa-var-step-forward,
	franc-sign: $fa-var-franc-sign,
	french-fries: $fa-var-french-fries,
	frog: $fa-var-frog,
	function: $fa-var-function,
	futbol-ball: $fa-var-futbol-ball,
	soccer-ball: $fa-var-soccer-ball,
	futbol: $fa-var-futbol,
	g: $fa-var-g,
	galaxy: $fa-var-galaxy,
	game-board: $fa-var-game-board,
	game-board-simple: $fa-var-game-board-simple,
	game-board-alt: $fa-var-game-board-alt,
	game-console-handheld: $fa-var-game-console-handheld,
	gamepad: $fa-var-gamepad,
	gamepad-modern: $fa-var-gamepad-modern,
	gamepad-alt: $fa-var-gamepad-alt,
	garage: $fa-var-garage,
	garage-car: $fa-var-garage-car,
	garage-open: $fa-var-garage-open,
	gas-pump: $fa-var-gas-pump,
	gas-pump-slash: $fa-var-gas-pump-slash,
	gauge: $fa-var-gauge,
	gauge-high: $fa-var-gauge-high,
	tachometer-alt: $fa-var-tachometer-alt,
	tachometer-alt-fast: $fa-var-tachometer-alt-fast,
	dashboard: $fa-var-dashboard,
	gauge-low: $fa-var-gauge-low,
	tachometer-alt-slow: $fa-var-tachometer-alt-slow,
	gauge-max: $fa-var-gauge-max,
	tachometer-alt-fastest: $fa-var-tachometer-alt-fastest,
	gauge-med: $fa-var-gauge-med,
	tachometer-alt-average: $fa-var-tachometer-alt-average,
	gauge-min: $fa-var-gauge-min,
	tachometer-alt-slowest: $fa-var-tachometer-alt-slowest,
	gauge-simple: $fa-var-gauge-simple,
	gauge-simple-high: $fa-var-gauge-simple-high,
	tachometer: $fa-var-tachometer,
	gauge-simple-low: $fa-var-gauge-simple-low,
	tachometer-slow: $fa-var-tachometer-slow,
	gauge-simple-max: $fa-var-gauge-simple-max,
	tachometer-fastest: $fa-var-tachometer-fastest,
	gauge-simple-med: $fa-var-gauge-simple-med,
	tachometer-average: $fa-var-tachometer-average,
	gauge-simple-min: $fa-var-gauge-simple-min,
	tachometer-slowest: $fa-var-tachometer-slowest,
	gavel: $fa-var-gavel,
	legal: $fa-var-legal,
	gear: $fa-var-gear,
	cog: $fa-var-cog,
	gears: $fa-var-gears,
	cogs: $fa-var-cogs,
	gem: $fa-var-gem,
	genderless: $fa-var-genderless,
	ghost: $fa-var-ghost,
	gif: $fa-var-gif,
	gift: $fa-var-gift,
	gift-card: $fa-var-gift-card,
	gifts: $fa-var-gifts,
	gingerbread-man: $fa-var-gingerbread-man,
	glass: $fa-var-glass,
	glass-citrus: $fa-var-glass-citrus,
	glass-empty: $fa-var-glass-empty,
	glass-half: $fa-var-glass-half,
	glass-half-full: $fa-var-glass-half-full,
	glass-half-empty: $fa-var-glass-half-empty,
	glasses: $fa-var-glasses,
	glasses-round: $fa-var-glasses-round,
	glasses-alt: $fa-var-glasses-alt,
	globe: $fa-var-globe,
	globe-snow: $fa-var-globe-snow,
	globe-stand: $fa-var-globe-stand,
	golf-ball-tee: $fa-var-golf-ball-tee,
	golf-ball: $fa-var-golf-ball,
	golf-club: $fa-var-golf-club,
	gopuram: $fa-var-gopuram,
	graduation-cap: $fa-var-graduation-cap,
	mortar-board: $fa-var-mortar-board,
	gramophone: $fa-var-gramophone,
	grate: $fa-var-grate,
	grate-droplet: $fa-var-grate-droplet,
	greater-than: $fa-var-greater-than,
	greater-than-equal: $fa-var-greater-than-equal,
	grid: $fa-var-grid,
	grid-3: $fa-var-grid-3,
	grid-2: $fa-var-grid-2,
	grid-2-plus: $fa-var-grid-2-plus,
	grid-4: $fa-var-grid-4,
	grid-5: $fa-var-grid-5,
	grip: $fa-var-grip,
	grip-horizontal: $fa-var-grip-horizontal,
	grip-lines: $fa-var-grip-lines,
	grip-lines-vertical: $fa-var-grip-lines-vertical,
	grip-vertical: $fa-var-grip-vertical,
	guarani-sign: $fa-var-guarani-sign,
	guitar: $fa-var-guitar,
	guitar-electric: $fa-var-guitar-electric,
	guitars: $fa-var-guitars,
	gun: $fa-var-gun,
	gun-slash: $fa-var-gun-slash,
	gun-squirt: $fa-var-gun-squirt,
	h: $fa-var-h,
	h1: $fa-var-h1,
	h2: $fa-var-h2,
	h3: $fa-var-h3,
	h4: $fa-var-h4,
	hammer: $fa-var-hammer,
	hammer-war: $fa-var-hammer-war,
	hamsa: $fa-var-hamsa,
	hand-back-point-down: $fa-var-hand-back-point-down,
	hand-back-point-left: $fa-var-hand-back-point-left,
	hand-back-point-right: $fa-var-hand-back-point-right,
	hand-back-point-up: $fa-var-hand-back-point-up,
	hand-dots: $fa-var-hand-dots,
	allergies: $fa-var-allergies,
	hand-fingers-crossed: $fa-var-hand-fingers-crossed,
	hand-fist: $fa-var-hand-fist,
	fist-raised: $fa-var-fist-raised,
	hand-heart: $fa-var-hand-heart,
	hand-holding: $fa-var-hand-holding,
	hand-holding-box: $fa-var-hand-holding-box,
	hand-holding-dollar: $fa-var-hand-holding-dollar,
	hand-holding-usd: $fa-var-hand-holding-usd,
	hand-holding-droplet: $fa-var-hand-holding-droplet,
	hand-holding-water: $fa-var-hand-holding-water,
	hand-holding-heart: $fa-var-hand-holding-heart,
	hand-holding-magic: $fa-var-hand-holding-magic,
	hand-holding-medical: $fa-var-hand-holding-medical,
	hand-holding-seedling: $fa-var-hand-holding-seedling,
	hand-holding-skull: $fa-var-hand-holding-skull,
	hand-lizard: $fa-var-hand-lizard,
	hand-love: $fa-var-hand-love,
	hand-middle-finger: $fa-var-hand-middle-finger,
	hand-paper: $fa-var-hand-paper,
	hand-peace: $fa-var-hand-peace,
	hand-point-down: $fa-var-hand-point-down,
	hand-point-left: $fa-var-hand-point-left,
	hand-point-right: $fa-var-hand-point-right,
	hand-point-up: $fa-var-hand-point-up,
	hand-pointer: $fa-var-hand-pointer,
	hand-rock: $fa-var-hand-rock,
	hand-scissors: $fa-var-hand-scissors,
	hand-sparkles: $fa-var-hand-sparkles,
	hand-spock: $fa-var-hand-spock,
	hand-wave: $fa-var-hand-wave,
	hands: $fa-var-hands,
	sign-language: $fa-var-sign-language,
	signing: $fa-var-signing,
	hands-asl-interpreting: $fa-var-hands-asl-interpreting,
	american-sign-language-interpreting: $fa-var-american-sign-language-interpreting,
	hands-american-sign-language-interpreting: $fa-var-hands-american-sign-language-interpreting,
	asl-interpreting: $fa-var-asl-interpreting,
	hands-bubbles: $fa-var-hands-bubbles,
	hands-wash: $fa-var-hands-wash,
	hands-clapping: $fa-var-hands-clapping,
	hands-holding: $fa-var-hands-holding,
	hands-holding-diamond: $fa-var-hands-holding-diamond,
	hand-receiving: $fa-var-hand-receiving,
	hands-holding-dollar: $fa-var-hands-holding-dollar,
	hands-usd: $fa-var-hands-usd,
	hands-holding-heart: $fa-var-hands-holding-heart,
	hands-heart: $fa-var-hands-heart,
	hands-horns: $fa-var-hands-horns,
	hands-praying: $fa-var-hands-praying,
	praying-hands: $fa-var-praying-hands,
	handshake: $fa-var-handshake,
	handshake-angle: $fa-var-handshake-angle,
	hands-helping: $fa-var-hands-helping,
	handshake-simple: $fa-var-handshake-simple,
	handshake-alt: $fa-var-handshake-alt,
	handshake-simple-slash: $fa-var-handshake-simple-slash,
	handshake-alt-slash: $fa-var-handshake-alt-slash,
	handshake-slash: $fa-var-handshake-slash,
	hanukiah: $fa-var-hanukiah,
	hard-drive: $fa-var-hard-drive,
	hdd: $fa-var-hdd,
	hashtag: $fa-var-hashtag,
	hat-chef: $fa-var-hat-chef,
	hat-cowboy: $fa-var-hat-cowboy,
	hat-cowboy-side: $fa-var-hat-cowboy-side,
	hat-santa: $fa-var-hat-santa,
	hat-winter: $fa-var-hat-winter,
	hat-witch: $fa-var-hat-witch,
	hat-wizard: $fa-var-hat-wizard,
	head-side: $fa-var-head-side,
	head-side-brain: $fa-var-head-side-brain,
	head-side-cough: $fa-var-head-side-cough,
	head-side-cough-slash: $fa-var-head-side-cough-slash,
	head-side-goggles: $fa-var-head-side-goggles,
	head-vr: $fa-var-head-vr,
	head-side-headphones: $fa-var-head-side-headphones,
	head-side-heart: $fa-var-head-side-heart,
	head-side-mask: $fa-var-head-side-mask,
	head-side-medical: $fa-var-head-side-medical,
	head-side-virus: $fa-var-head-side-virus,
	heading: $fa-var-heading,
	header: $fa-var-header,
	headphones: $fa-var-headphones,
	headphones-simple: $fa-var-headphones-simple,
	headphones-alt: $fa-var-headphones-alt,
	headset: $fa-var-headset,
	heart: $fa-var-heart,
	heart-crack: $fa-var-heart-crack,
	heart-broken: $fa-var-heart-broken,
	heart-half: $fa-var-heart-half,
	heart-half-stroke: $fa-var-heart-half-stroke,
	heart-half-alt: $fa-var-heart-half-alt,
	heart-pulse: $fa-var-heart-pulse,
	heartbeat: $fa-var-heartbeat,
	heat: $fa-var-heat,
	helicopter: $fa-var-helicopter,
	helmet-battle: $fa-var-helmet-battle,
	helmet-safety: $fa-var-helmet-safety,
	hat-hard: $fa-var-hat-hard,
	hard-hat: $fa-var-hard-hat,
	hexagon: $fa-var-hexagon,
	hexagon-divide: $fa-var-hexagon-divide,
	hexagon-minus: $fa-var-hexagon-minus,
	minus-hexagon: $fa-var-minus-hexagon,
	hexagon-plus: $fa-var-hexagon-plus,
	plus-hexagon: $fa-var-plus-hexagon,
	hexagon-xmark: $fa-var-hexagon-xmark,
	times-hexagon: $fa-var-times-hexagon,
	xmark-hexagon: $fa-var-xmark-hexagon,
	high-definition: $fa-var-high-definition,
	highlighter: $fa-var-highlighter,
	highlighter-line: $fa-var-highlighter-line,
	hippo: $fa-var-hippo,
	hockey-mask: $fa-var-hockey-mask,
	hockey-puck: $fa-var-hockey-puck,
	hockey-sticks: $fa-var-hockey-sticks,
	holly-berry: $fa-var-holly-berry,
	home: $fa-var-home,
	home-lg: $fa-var-home-lg,
	home-heart: $fa-var-home-heart,
	home-simple: $fa-var-home-simple,
	home-lg-alt: $fa-var-home-lg-alt,
	home-user: $fa-var-home-user,
	hood-cloak: $fa-var-hood-cloak,
	horizontal-rule: $fa-var-horizontal-rule,
	horse: $fa-var-horse,
	horse-head: $fa-var-horse-head,
	horse-saddle: $fa-var-horse-saddle,
	hospital: $fa-var-hospital,
	hospital-user: $fa-var-hospital-user,
	hospital-wide: $fa-var-hospital-wide,
	hospital-alt: $fa-var-hospital-alt,
	hospitals: $fa-var-hospitals,
	hot-tub-person: $fa-var-hot-tub-person,
	hot-tub: $fa-var-hot-tub,
	hotdog: $fa-var-hotdog,
	hotel: $fa-var-hotel,
	hourglass: $fa-var-hourglass,
	hourglass-2: $fa-var-hourglass-2,
	hourglass-half: $fa-var-hourglass-half,
	hourglass-empty: $fa-var-hourglass-empty,
	hourglass-end: $fa-var-hourglass-end,
	hourglass-3: $fa-var-hourglass-3,
	hourglass-start: $fa-var-hourglass-start,
	hourglass-1: $fa-var-hourglass-1,
	house: $fa-var-house,
	house-building: $fa-var-house-building,
	house-crack: $fa-var-house-crack,
	house-damage: $fa-var-house-damage,
	house-day: $fa-var-house-day,
	house-flood: $fa-var-house-flood,
	house-heart: $fa-var-house-heart,
	house-laptop: $fa-var-house-laptop,
	laptop-house: $fa-var-laptop-house,
	house-medical: $fa-var-house-medical,
	clinic-medical: $fa-var-clinic-medical,
	house-night: $fa-var-house-night,
	house-person-leave: $fa-var-house-person-leave,
	house-person-depart: $fa-var-house-person-depart,
	house-person-return: $fa-var-house-person-return,
	house-person-arrive: $fa-var-house-person-arrive,
	house-signal: $fa-var-house-signal,
	house-tree: $fa-var-house-tree,
	house-turret: $fa-var-house-turret,
	house-user: $fa-var-house-user,
	hryvnia-sign: $fa-var-hryvnia-sign,
	hryvnia: $fa-var-hryvnia,
	hurricane: $fa-var-hurricane,
	i: $fa-var-i,
	i-cursor: $fa-var-i-cursor,
	ice-cream: $fa-var-ice-cream,
	ice-skate: $fa-var-ice-skate,
	icicles: $fa-var-icicles,
	icons: $fa-var-icons,
	heart-music-camera-bolt: $fa-var-heart-music-camera-bolt,
	id-badge: $fa-var-id-badge,
	id-card: $fa-var-id-card,
	drivers-license: $fa-var-drivers-license,
	id-card-clip: $fa-var-id-card-clip,
	id-card-alt: $fa-var-id-card-alt,
	igloo: $fa-var-igloo,
	image: $fa-var-image,
	image-landscape: $fa-var-image-landscape,
	landscape: $fa-var-landscape,
	image-polaroid: $fa-var-image-polaroid,
	image-polaroid-user: $fa-var-image-polaroid-user,
	image-portrait: $fa-var-image-portrait,
	portrait: $fa-var-portrait,
	image-slash: $fa-var-image-slash,
	image-user: $fa-var-image-user,
	images: $fa-var-images,
	images-user: $fa-var-images-user,
	inbox: $fa-var-inbox,
	inbox-full: $fa-var-inbox-full,
	inbox-in: $fa-var-inbox-in,
	inbox-arrow-down: $fa-var-inbox-arrow-down,
	inbox-out: $fa-var-inbox-out,
	inbox-arrow-up: $fa-var-inbox-arrow-up,
	inboxes: $fa-var-inboxes,
	indent: $fa-var-indent,
	indian-rupee-sign: $fa-var-indian-rupee-sign,
	indian-rupee: $fa-var-indian-rupee,
	inr: $fa-var-inr,
	industry: $fa-var-industry,
	industry-windows: $fa-var-industry-windows,
	industry-alt: $fa-var-industry-alt,
	infinity: $fa-var-infinity,
	info: $fa-var-info,
	inhaler: $fa-var-inhaler,
	input-numeric: $fa-var-input-numeric,
	input-pipe: $fa-var-input-pipe,
	input-text: $fa-var-input-text,
	integral: $fa-var-integral,
	intersection: $fa-var-intersection,
	island-tropical: $fa-var-island-tropical,
	island-tree-palm: $fa-var-island-tree-palm,
	italic: $fa-var-italic,
	j: $fa-var-j,
	jack-o-lantern: $fa-var-jack-o-lantern,
	jedi: $fa-var-jedi,
	jet-fighter: $fa-var-jet-fighter,
	fighter-jet: $fa-var-fighter-jet,
	joint: $fa-var-joint,
	joystick: $fa-var-joystick,
	jug: $fa-var-jug,
	k: $fa-var-k,
	kaaba: $fa-var-kaaba,
	kazoo: $fa-var-kazoo,
	kerning: $fa-var-kerning,
	key: $fa-var-key,
	key-skeleton: $fa-var-key-skeleton,
	keyboard: $fa-var-keyboard,
	keyboard-down: $fa-var-keyboard-down,
	keyboard-left: $fa-var-keyboard-left,
	keynote: $fa-var-keynote,
	khanda: $fa-var-khanda,
	kidneys: $fa-var-kidneys,
	kip-sign: $fa-var-kip-sign,
	kit-medical: $fa-var-kit-medical,
	first-aid: $fa-var-first-aid,
	kite: $fa-var-kite,
	kiwi-bird: $fa-var-kiwi-bird,
	knife: $fa-var-knife,
	utensil-knife: $fa-var-utensil-knife,
	knife-kitchen: $fa-var-knife-kitchen,
	l: $fa-var-l,
	lambda: $fa-var-lambda,
	lamp: $fa-var-lamp,
	lamp-desk: $fa-var-lamp-desk,
	lamp-floor: $fa-var-lamp-floor,
	lamp-street: $fa-var-lamp-street,
	landmark: $fa-var-landmark,
	landmark-dome: $fa-var-landmark-dome,
	landmark-alt: $fa-var-landmark-alt,
	language: $fa-var-language,
	laptop: $fa-var-laptop,
	laptop-arrow-down: $fa-var-laptop-arrow-down,
	laptop-code: $fa-var-laptop-code,
	laptop-medical: $fa-var-laptop-medical,
	laptop-mobile: $fa-var-laptop-mobile,
	phone-laptop: $fa-var-phone-laptop,
	laptop-slash: $fa-var-laptop-slash,
	lari-sign: $fa-var-lari-sign,
	lasso: $fa-var-lasso,
	lasso-sparkles: $fa-var-lasso-sparkles,
	layers: $fa-var-layers,
	layer-group: $fa-var-layer-group,
	layers-minus: $fa-var-layers-minus,
	layer-minus: $fa-var-layer-minus,
	layers-plus: $fa-var-layers-plus,
	layer-plus: $fa-var-layer-plus,
	leaf: $fa-var-leaf,
	leaf-heart: $fa-var-leaf-heart,
	leaf-maple: $fa-var-leaf-maple,
	leaf-oak: $fa-var-leaf-oak,
	left: $fa-var-left,
	arrow-alt-left: $fa-var-arrow-alt-left,
	left-from-line: $fa-var-left-from-line,
	arrow-alt-from-right: $fa-var-arrow-alt-from-right,
	left-long: $fa-var-left-long,
	long-arrow-alt-left: $fa-var-long-arrow-alt-left,
	left-right: $fa-var-left-right,
	arrows-alt-h: $fa-var-arrows-alt-h,
	left-to-line: $fa-var-left-to-line,
	arrow-alt-to-left: $fa-var-arrow-alt-to-left,
	lemon: $fa-var-lemon,
	less-than: $fa-var-less-than,
	less-than-equal: $fa-var-less-than-equal,
	life-ring: $fa-var-life-ring,
	light-ceiling: $fa-var-light-ceiling,
	light-switch: $fa-var-light-switch,
	light-switch-off: $fa-var-light-switch-off,
	light-switch-on: $fa-var-light-switch-on,
	lightbulb: $fa-var-lightbulb,
	lightbulb-dollar: $fa-var-lightbulb-dollar,
	lightbulb-exclamation: $fa-var-lightbulb-exclamation,
	lightbulb-on: $fa-var-lightbulb-on,
	lightbulb-slash: $fa-var-lightbulb-slash,
	lights-holiday: $fa-var-lights-holiday,
	line-columns: $fa-var-line-columns,
	line-height: $fa-var-line-height,
	link: $fa-var-link,
	chain: $fa-var-chain,
	link-horizontal: $fa-var-link-horizontal,
	chain-horizontal: $fa-var-chain-horizontal,
	link-horizontal-slash: $fa-var-link-horizontal-slash,
	chain-horizontal-slash: $fa-var-chain-horizontal-slash,
	link-simple: $fa-var-link-simple,
	link-simple-slash: $fa-var-link-simple-slash,
	link-slash: $fa-var-link-slash,
	chain-slash: $fa-var-chain-slash,
	chain-broken: $fa-var-chain-broken,
	unlink: $fa-var-unlink,
	lips: $fa-var-lips,
	lira-sign: $fa-var-lira-sign,
	list: $fa-var-list,
	list-squares: $fa-var-list-squares,
	list-check: $fa-var-list-check,
	tasks: $fa-var-tasks,
	list-dropdown: $fa-var-list-dropdown,
	list-music: $fa-var-list-music,
	list-ol: $fa-var-list-ol,
	list-numeric: $fa-var-list-numeric,
	list-1-2: $fa-var-list-1-2,
	list-radio: $fa-var-list-radio,
	list-timeline: $fa-var-list-timeline,
	list-tree: $fa-var-list-tree,
	list-ul: $fa-var-list-ul,
	list-dots: $fa-var-list-dots,
	litecoin-sign: $fa-var-litecoin-sign,
	loader: $fa-var-loader,
	location: $fa-var-location,
	map-marker: $fa-var-map-marker,
	location-arrow: $fa-var-location-arrow,
	location-check: $fa-var-location-check,
	map-marker-check: $fa-var-map-marker-check,
	location-crosshairs: $fa-var-location-crosshairs,
	location-crosshairs-slash: $fa-var-location-crosshairs-slash,
	location-dot: $fa-var-location-dot,
	map-marker-alt: $fa-var-map-marker-alt,
	location-dot-slash: $fa-var-location-dot-slash,
	map-marker-alt-slash: $fa-var-map-marker-alt-slash,
	location-exclamation: $fa-var-location-exclamation,
	map-marker-exclamation: $fa-var-map-marker-exclamation,
	location-minus: $fa-var-location-minus,
	map-marker-minus: $fa-var-map-marker-minus,
	location-pen: $fa-var-location-pen,
	map-marker-edit: $fa-var-map-marker-edit,
	location-plus: $fa-var-location-plus,
	map-marker-plus: $fa-var-map-marker-plus,
	location-question: $fa-var-location-question,
	map-marker-question: $fa-var-map-marker-question,
	location-slash: $fa-var-location-slash,
	map-marker-slash: $fa-var-map-marker-slash,
	location-smile: $fa-var-location-smile,
	map-marker-smile: $fa-var-map-marker-smile,
	location-xmark: $fa-var-location-xmark,
	map-marker-times: $fa-var-map-marker-times,
	map-marker-xmark: $fa-var-map-marker-xmark,
	lock: $fa-var-lock,
	lock-keyhole: $fa-var-lock-keyhole,
	lock-alt: $fa-var-lock-alt,
	lock-keyhole-open: $fa-var-lock-keyhole-open,
	lock-open-alt: $fa-var-lock-open-alt,
	lock-open: $fa-var-lock-open,
	loveseat: $fa-var-loveseat,
	couch-small: $fa-var-couch-small,
	luchador-mask: $fa-var-luchador-mask,
	luchador: $fa-var-luchador,
	mask-luchador: $fa-var-mask-luchador,
	lungs: $fa-var-lungs,
	lungs-virus: $fa-var-lungs-virus,
	m: $fa-var-m,
	mace: $fa-var-mace,
	magnet: $fa-var-magnet,
	magnifying-glass: $fa-var-magnifying-glass,
	search: $fa-var-search,
	magnifying-glass-dollar: $fa-var-magnifying-glass-dollar,
	search-dollar: $fa-var-search-dollar,
	magnifying-glass-location: $fa-var-magnifying-glass-location,
	search-location: $fa-var-search-location,
	magnifying-glass-minus: $fa-var-magnifying-glass-minus,
	search-minus: $fa-var-search-minus,
	magnifying-glass-plus: $fa-var-magnifying-glass-plus,
	search-plus: $fa-var-search-plus,
	mailbox: $fa-var-mailbox,
	manat-sign: $fa-var-manat-sign,
	mandolin: $fa-var-mandolin,
	manhole: $fa-var-manhole,
	map: $fa-var-map,
	map-location: $fa-var-map-location,
	map-marked: $fa-var-map-marked,
	map-location-dot: $fa-var-map-location-dot,
	map-marked-alt: $fa-var-map-marked-alt,
	map-pin: $fa-var-map-pin,
	marker: $fa-var-marker,
	mars: $fa-var-mars,
	mars-double: $fa-var-mars-double,
	mars-stroke: $fa-var-mars-stroke,
	mars-stroke-right: $fa-var-mars-stroke-right,
	mars-stroke-h: $fa-var-mars-stroke-h,
	mars-stroke-up: $fa-var-mars-stroke-up,
	mars-stroke-v: $fa-var-mars-stroke-v,
	martini-glass: $fa-var-martini-glass,
	glass-martini-alt: $fa-var-glass-martini-alt,
	martini-glass-citrus: $fa-var-martini-glass-citrus,
	cocktail: $fa-var-cocktail,
	martini-glass-empty: $fa-var-martini-glass-empty,
	glass-martini: $fa-var-glass-martini,
	mask: $fa-var-mask,
	mask-face: $fa-var-mask-face,
	masks-theater: $fa-var-masks-theater,
	theater-masks: $fa-var-theater-masks,
	maximize: $fa-var-maximize,
	expand-arrows-alt: $fa-var-expand-arrows-alt,
	meat: $fa-var-meat,
	medal: $fa-var-medal,
	megaphone: $fa-var-megaphone,
	memo: $fa-var-memo,
	memo-circle-check: $fa-var-memo-circle-check,
	memory: $fa-var-memory,
	menorah: $fa-var-menorah,
	mercury: $fa-var-mercury,
	message: $fa-var-message,
	comment-alt: $fa-var-comment-alt,
	message-arrow-down: $fa-var-message-arrow-down,
	comment-alt-arrow-down: $fa-var-comment-alt-arrow-down,
	message-arrow-up: $fa-var-message-arrow-up,
	comment-alt-arrow-up: $fa-var-comment-alt-arrow-up,
	message-arrow-up-right: $fa-var-message-arrow-up-right,
	message-captions: $fa-var-message-captions,
	comment-alt-captions: $fa-var-comment-alt-captions,
	message-check: $fa-var-message-check,
	comment-alt-check: $fa-var-comment-alt-check,
	message-code: $fa-var-message-code,
	message-dollar: $fa-var-message-dollar,
	comment-alt-dollar: $fa-var-comment-alt-dollar,
	message-dots: $fa-var-message-dots,
	comment-alt-dots: $fa-var-comment-alt-dots,
	messaging: $fa-var-messaging,
	message-exclamation: $fa-var-message-exclamation,
	comment-alt-exclamation: $fa-var-comment-alt-exclamation,
	message-image: $fa-var-message-image,
	comment-alt-image: $fa-var-comment-alt-image,
	message-lines: $fa-var-message-lines,
	comment-alt-lines: $fa-var-comment-alt-lines,
	message-medical: $fa-var-message-medical,
	comment-alt-medical: $fa-var-comment-alt-medical,
	message-middle: $fa-var-message-middle,
	comment-middle-alt: $fa-var-comment-middle-alt,
	message-middle-top: $fa-var-message-middle-top,
	comment-middle-top-alt: $fa-var-comment-middle-top-alt,
	message-minus: $fa-var-message-minus,
	comment-alt-minus: $fa-var-comment-alt-minus,
	message-music: $fa-var-message-music,
	comment-alt-music: $fa-var-comment-alt-music,
	message-pen: $fa-var-message-pen,
	message-edit: $fa-var-message-edit,
	comment-alt-edit: $fa-var-comment-alt-edit,
	message-plus: $fa-var-message-plus,
	comment-alt-plus: $fa-var-comment-alt-plus,
	message-question: $fa-var-message-question,
	message-quote: $fa-var-message-quote,
	comment-alt-quote: $fa-var-comment-alt-quote,
	message-slash: $fa-var-message-slash,
	comment-alt-slash: $fa-var-comment-alt-slash,
	message-smile: $fa-var-message-smile,
	comment-alt-smile: $fa-var-comment-alt-smile,
	message-sms: $fa-var-message-sms,
	message-text: $fa-var-message-text,
	comment-alt-text: $fa-var-comment-alt-text,
	message-xmark: $fa-var-message-xmark,
	comment-alt-times: $fa-var-comment-alt-times,
	message-times: $fa-var-message-times,
	messages: $fa-var-messages,
	comments-alt: $fa-var-comments-alt,
	messages-dollar: $fa-var-messages-dollar,
	comments-alt-dollar: $fa-var-comments-alt-dollar,
	messages-question: $fa-var-messages-question,
	meteor: $fa-var-meteor,
	meter: $fa-var-meter,
	meter-bolt: $fa-var-meter-bolt,
	meter-droplet: $fa-var-meter-droplet,
	meter-fire: $fa-var-meter-fire,
	microchip: $fa-var-microchip,
	microchip-ai: $fa-var-microchip-ai,
	microphone: $fa-var-microphone,
	microphone-lines: $fa-var-microphone-lines,
	microphone-alt: $fa-var-microphone-alt,
	microphone-lines-slash: $fa-var-microphone-lines-slash,
	microphone-alt-slash: $fa-var-microphone-alt-slash,
	microphone-slash: $fa-var-microphone-slash,
	microphone-stand: $fa-var-microphone-stand,
	microscope: $fa-var-microscope,
	microwave: $fa-var-microwave,
	mill-sign: $fa-var-mill-sign,
	minimize: $fa-var-minimize,
	compress-arrows-alt: $fa-var-compress-arrows-alt,
	minus: $fa-var-minus,
	subtract: $fa-var-subtract,
	mistletoe: $fa-var-mistletoe,
	mitten: $fa-var-mitten,
	mobile: $fa-var-mobile,
	mobile-android: $fa-var-mobile-android,
	mobile-phone: $fa-var-mobile-phone,
	mobile-button: $fa-var-mobile-button,
	mobile-notch: $fa-var-mobile-notch,
	mobile-iphone: $fa-var-mobile-iphone,
	mobile-screen: $fa-var-mobile-screen,
	mobile-android-alt: $fa-var-mobile-android-alt,
	mobile-screen-button: $fa-var-mobile-screen-button,
	mobile-alt: $fa-var-mobile-alt,
	money-bill: $fa-var-money-bill,
	money-bill-1: $fa-var-money-bill-1,
	money-bill-alt: $fa-var-money-bill-alt,
	money-bill-1-wave: $fa-var-money-bill-1-wave,
	money-bill-wave-alt: $fa-var-money-bill-wave-alt,
	money-bill-simple: $fa-var-money-bill-simple,
	money-bill-simple-wave: $fa-var-money-bill-simple-wave,
	money-bill-wave: $fa-var-money-bill-wave,
	money-bills: $fa-var-money-bills,
	money-bills-simple: $fa-var-money-bills-simple,
	money-bills-alt: $fa-var-money-bills-alt,
	money-check: $fa-var-money-check,
	money-check-dollar: $fa-var-money-check-dollar,
	money-check-alt: $fa-var-money-check-alt,
	money-check-dollar-pen: $fa-var-money-check-dollar-pen,
	money-check-edit-alt: $fa-var-money-check-edit-alt,
	money-check-pen: $fa-var-money-check-pen,
	money-check-edit: $fa-var-money-check-edit,
	monitor-waveform: $fa-var-monitor-waveform,
	monitor-heart-rate: $fa-var-monitor-heart-rate,
	monkey: $fa-var-monkey,
	monument: $fa-var-monument,
	moon: $fa-var-moon,
	moon-cloud: $fa-var-moon-cloud,
	moon-over-sun: $fa-var-moon-over-sun,
	eclipse-alt: $fa-var-eclipse-alt,
	moon-stars: $fa-var-moon-stars,
	mortar-pestle: $fa-var-mortar-pestle,
	mosque: $fa-var-mosque,
	motorcycle: $fa-var-motorcycle,
	mountain: $fa-var-mountain,
	mountains: $fa-var-mountains,
	mp3-player: $fa-var-mp3-player,
	mug: $fa-var-mug,
	mug-hot: $fa-var-mug-hot,
	mug-marshmallows: $fa-var-mug-marshmallows,
	mug-saucer: $fa-var-mug-saucer,
	coffee: $fa-var-coffee,
	mug-tea: $fa-var-mug-tea,
	mug-tea-saucer: $fa-var-mug-tea-saucer,
	music: $fa-var-music,
	music-note: $fa-var-music-note,
	music-alt: $fa-var-music-alt,
	music-note-slash: $fa-var-music-note-slash,
	music-alt-slash: $fa-var-music-alt-slash,
	music-slash: $fa-var-music-slash,
	n: $fa-var-n,
	naira-sign: $fa-var-naira-sign,
	narwhal: $fa-var-narwhal,
	network-wired: $fa-var-network-wired,
	neuter: $fa-var-neuter,
	newspaper: $fa-var-newspaper,
	not-equal: $fa-var-not-equal,
	notdef: $fa-var-notdef,
	note: $fa-var-note,
	note-medical: $fa-var-note-medical,
	note-sticky: $fa-var-note-sticky,
	sticky-note: $fa-var-sticky-note,
	notes: $fa-var-notes,
	notes-medical: $fa-var-notes-medical,
	o: $fa-var-o,
	object-group: $fa-var-object-group,
	object-ungroup: $fa-var-object-ungroup,
	octagon: $fa-var-octagon,
	octagon-divide: $fa-var-octagon-divide,
	octagon-exclamation: $fa-var-octagon-exclamation,
	octagon-minus: $fa-var-octagon-minus,
	minus-octagon: $fa-var-minus-octagon,
	octagon-plus: $fa-var-octagon-plus,
	plus-octagon: $fa-var-plus-octagon,
	octagon-xmark: $fa-var-octagon-xmark,
	times-octagon: $fa-var-times-octagon,
	xmark-octagon: $fa-var-xmark-octagon,
	oil-can: $fa-var-oil-can,
	oil-can-drip: $fa-var-oil-can-drip,
	oil-temperature: $fa-var-oil-temperature,
	oil-temp: $fa-var-oil-temp,
	om: $fa-var-om,
	omega: $fa-var-omega,
	option: $fa-var-option,
	ornament: $fa-var-ornament,
	otter: $fa-var-otter,
	outdent: $fa-var-outdent,
	dedent: $fa-var-dedent,
	outlet: $fa-var-outlet,
	oven: $fa-var-oven,
	overline: $fa-var-overline,
	p: $fa-var-p,
	pager: $fa-var-pager,
	paint-brush: $fa-var-paint-brush,
	paint-brush-fine: $fa-var-paint-brush-fine,
	paint-brush-alt: $fa-var-paint-brush-alt,
	paint-roller: $fa-var-paint-roller,
	paintbrush-pencil: $fa-var-paintbrush-pencil,
	palette: $fa-var-palette,
	palette-boxes: $fa-var-palette-boxes,
	pallet: $fa-var-pallet,
	pallet-box: $fa-var-pallet-box,
	pallet-boxes: $fa-var-pallet-boxes,
	pallet-alt: $fa-var-pallet-alt,
	panorama: $fa-var-panorama,
	paper-plane: $fa-var-paper-plane,
	paper-plane-top: $fa-var-paper-plane-top,
	paper-plane-alt: $fa-var-paper-plane-alt,
	send: $fa-var-send,
	paperclip: $fa-var-paperclip,
	parachute-box: $fa-var-parachute-box,
	paragraph: $fa-var-paragraph,
	paragraph-left: $fa-var-paragraph-left,
	paragraph-rtl: $fa-var-paragraph-rtl,
	passport: $fa-var-passport,
	paste: $fa-var-paste,
	file-clipboard: $fa-var-file-clipboard,
	pause: $fa-var-pause,
	paw: $fa-var-paw,
	paw-claws: $fa-var-paw-claws,
	paw-simple: $fa-var-paw-simple,
	paw-alt: $fa-var-paw-alt,
	peace: $fa-var-peace,
	peach: $fa-var-peach,
	pear: $fa-var-pear,
	pedestal: $fa-var-pedestal,
	pegasus: $fa-var-pegasus,
	pen: $fa-var-pen,
	pen-circle: $fa-var-pen-circle,
	pen-clip: $fa-var-pen-clip,
	pen-alt: $fa-var-pen-alt,
	pen-clip-slash: $fa-var-pen-clip-slash,
	pen-alt-slash: $fa-var-pen-alt-slash,
	pen-fancy: $fa-var-pen-fancy,
	pen-fancy-slash: $fa-var-pen-fancy-slash,
	pen-field: $fa-var-pen-field,
	pen-line: $fa-var-pen-line,
	pen-nib: $fa-var-pen-nib,
	pen-paintbrush: $fa-var-pen-paintbrush,
	pencil-paintbrush: $fa-var-pencil-paintbrush,
	pen-ruler: $fa-var-pen-ruler,
	pencil-ruler: $fa-var-pencil-ruler,
	pen-slash: $fa-var-pen-slash,
	pen-swirl: $fa-var-pen-swirl,
	pen-to-square: $fa-var-pen-to-square,
	edit: $fa-var-edit,
	pencil: $fa-var-pencil,
	pencil-alt: $fa-var-pencil-alt,
	pencil-slash: $fa-var-pencil-slash,
	people-arrows-left-right: $fa-var-people-arrows-left-right,
	people-arrows: $fa-var-people-arrows,
	people-carry-box: $fa-var-people-carry-box,
	people-carry: $fa-var-people-carry,
	pepper-hot: $fa-var-pepper-hot,
	percent: $fa-var-percent,
	percentage: $fa-var-percentage,
	person: $fa-var-person,
	male: $fa-var-male,
	person-biking: $fa-var-person-biking,
	biking: $fa-var-biking,
	person-biking-mountain: $fa-var-person-biking-mountain,
	biking-mountain: $fa-var-biking-mountain,
	person-booth: $fa-var-person-booth,
	person-carry-box: $fa-var-person-carry-box,
	person-carry: $fa-var-person-carry,
	person-digging: $fa-var-person-digging,
	digging: $fa-var-digging,
	person-dolly: $fa-var-person-dolly,
	person-dolly-empty: $fa-var-person-dolly-empty,
	person-dots-from-line: $fa-var-person-dots-from-line,
	diagnoses: $fa-var-diagnoses,
	person-dress: $fa-var-person-dress,
	female: $fa-var-female,
	person-dress-simple: $fa-var-person-dress-simple,
	person-from-portal: $fa-var-person-from-portal,
	portal-exit: $fa-var-portal-exit,
	person-hiking: $fa-var-person-hiking,
	hiking: $fa-var-hiking,
	person-pinball: $fa-var-person-pinball,
	person-praying: $fa-var-person-praying,
	pray: $fa-var-pray,
	person-running: $fa-var-person-running,
	running: $fa-var-running,
	person-seat: $fa-var-person-seat,
	person-seat-reclined: $fa-var-person-seat-reclined,
	person-sign: $fa-var-person-sign,
	person-simple: $fa-var-person-simple,
	person-skating: $fa-var-person-skating,
	skating: $fa-var-skating,
	person-ski-jumping: $fa-var-person-ski-jumping,
	ski-jump: $fa-var-ski-jump,
	person-ski-lift: $fa-var-person-ski-lift,
	ski-lift: $fa-var-ski-lift,
	person-skiing: $fa-var-person-skiing,
	skiing: $fa-var-skiing,
	person-skiing-nordic: $fa-var-person-skiing-nordic,
	skiing-nordic: $fa-var-skiing-nordic,
	person-sledding: $fa-var-person-sledding,
	sledding: $fa-var-sledding,
	person-snowboarding: $fa-var-person-snowboarding,
	snowboarding: $fa-var-snowboarding,
	person-snowmobiling: $fa-var-person-snowmobiling,
	snowmobile: $fa-var-snowmobile,
	person-swimming: $fa-var-person-swimming,
	swimmer: $fa-var-swimmer,
	person-to-portal: $fa-var-person-to-portal,
	portal-enter: $fa-var-portal-enter,
	person-walking: $fa-var-person-walking,
	walking: $fa-var-walking,
	person-walking-with-cane: $fa-var-person-walking-with-cane,
	blind: $fa-var-blind,
	peseta-sign: $fa-var-peseta-sign,
	peso-sign: $fa-var-peso-sign,
	phone: $fa-var-phone,
	phone-arrow-down-left: $fa-var-phone-arrow-down-left,
	phone-incoming: $fa-var-phone-incoming,
	phone-arrow-down: $fa-var-phone-arrow-down,
	phone-arrow-up-right: $fa-var-phone-arrow-up-right,
	phone-outgoing: $fa-var-phone-outgoing,
	phone-arrow-up: $fa-var-phone-arrow-up,
	phone-flip: $fa-var-phone-flip,
	phone-alt: $fa-var-phone-alt,
	phone-hangup: $fa-var-phone-hangup,
	phone-missed: $fa-var-phone-missed,
	phone-office: $fa-var-phone-office,
	phone-plus: $fa-var-phone-plus,
	phone-rotary: $fa-var-phone-rotary,
	phone-slash: $fa-var-phone-slash,
	phone-volume: $fa-var-phone-volume,
	volume-control-phone: $fa-var-volume-control-phone,
	phone-xmark: $fa-var-phone-xmark,
	photo-film: $fa-var-photo-film,
	photo-video: $fa-var-photo-video,
	photo-film-music: $fa-var-photo-film-music,
	pi: $fa-var-pi,
	piano: $fa-var-piano,
	piano-keyboard: $fa-var-piano-keyboard,
	pie: $fa-var-pie,
	pig: $fa-var-pig,
	piggy-bank: $fa-var-piggy-bank,
	pills: $fa-var-pills,
	pinball: $fa-var-pinball,
	pizza: $fa-var-pizza,
	pizza-slice: $fa-var-pizza-slice,
	place-of-worship: $fa-var-place-of-worship,
	plane: $fa-var-plane,
	plane-arrival: $fa-var-plane-arrival,
	plane-departure: $fa-var-plane-departure,
	plane-engines: $fa-var-plane-engines,
	plane-alt: $fa-var-plane-alt,
	plane-prop: $fa-var-plane-prop,
	plane-slash: $fa-var-plane-slash,
	plane-tail: $fa-var-plane-tail,
	plane-up: $fa-var-plane-up,
	plane-up-slash: $fa-var-plane-up-slash,
	planet-moon: $fa-var-planet-moon,
	planet-ringed: $fa-var-planet-ringed,
	play: $fa-var-play,
	play-pause: $fa-var-play-pause,
	plug: $fa-var-plug,
	plus: $fa-var-plus,
	add: $fa-var-add,
	plus-minus: $fa-var-plus-minus,
	podcast: $fa-var-podcast,
	podium: $fa-var-podium,
	podium-star: $fa-var-podium-star,
	police-box: $fa-var-police-box,
	poll-people: $fa-var-poll-people,
	poo: $fa-var-poo,
	poo-bolt: $fa-var-poo-bolt,
	poo-storm: $fa-var-poo-storm,
	poop: $fa-var-poop,
	popcorn: $fa-var-popcorn,
	power-off: $fa-var-power-off,
	prescription: $fa-var-prescription,
	prescription-bottle: $fa-var-prescription-bottle,
	prescription-bottle-medical: $fa-var-prescription-bottle-medical,
	prescription-bottle-alt: $fa-var-prescription-bottle-alt,
	presentation-screen: $fa-var-presentation-screen,
	presentation: $fa-var-presentation,
	print: $fa-var-print,
	print-magnifying-glass: $fa-var-print-magnifying-glass,
	print-search: $fa-var-print-search,
	print-slash: $fa-var-print-slash,
	projector: $fa-var-projector,
	pump-medical: $fa-var-pump-medical,
	pump-soap: $fa-var-pump-soap,
	pumpkin: $fa-var-pumpkin,
	puzzle-piece: $fa-var-puzzle-piece,
	puzzle-piece-simple: $fa-var-puzzle-piece-simple,
	puzzle-piece-alt: $fa-var-puzzle-piece-alt,
	q: $fa-var-q,
	qrcode: $fa-var-qrcode,
	question: $fa-var-question,
	quidditch-broom-ball: $fa-var-quidditch-broom-ball,
	broom-ball: $fa-var-broom-ball,
	quidditch: $fa-var-quidditch,
	quote-left: $fa-var-quote-left,
	quote-left-round: $fa-var-quote-left-round,
	quote-left-alt: $fa-var-quote-left-alt,
	quote-right: $fa-var-quote-right,
	quote-right-round: $fa-var-quote-right-round,
	quote-right-alt: $fa-var-quote-right-alt,
	r: $fa-var-r,
	rabbit: $fa-var-rabbit,
	rabbit-running: $fa-var-rabbit-running,
	rabbit-fast: $fa-var-rabbit-fast,
	racquet: $fa-var-racquet,
	radar: $fa-var-radar,
	radiation: $fa-var-radiation,
	radio: $fa-var-radio,
	radio-tuner: $fa-var-radio-tuner,
	radio-alt: $fa-var-radio-alt,
	rainbow: $fa-var-rainbow,
	raindrops: $fa-var-raindrops,
	ram: $fa-var-ram,
	ramp-loading: $fa-var-ramp-loading,
	raygun: $fa-var-raygun,
	receipt: $fa-var-receipt,
	record-vinyl: $fa-var-record-vinyl,
	rectangle: $fa-var-rectangle,
	rectangle-landscape: $fa-var-rectangle-landscape,
	rectangle-ad: $fa-var-rectangle-ad,
	ad: $fa-var-ad,
	rectangle-barcode: $fa-var-rectangle-barcode,
	barcode-alt: $fa-var-barcode-alt,
	rectangle-list: $fa-var-rectangle-list,
	list-alt: $fa-var-list-alt,
	rectangle-terminal: $fa-var-rectangle-terminal,
	rectangle-vertical: $fa-var-rectangle-vertical,
	rectangle-portrait: $fa-var-rectangle-portrait,
	rectangle-vertical-history: $fa-var-rectangle-vertical-history,
	rectangle-wide: $fa-var-rectangle-wide,
	rectangle-xmark: $fa-var-rectangle-xmark,
	times-rectangle: $fa-var-times-rectangle,
	rectangle-times: $fa-var-rectangle-times,
	window-close: $fa-var-window-close,
	recycle: $fa-var-recycle,
	refrigerator: $fa-var-refrigerator,
	registered: $fa-var-registered,
	repeat: $fa-var-repeat,
	repeat-1: $fa-var-repeat-1,
	reply: $fa-var-reply,
	mail-reply: $fa-var-mail-reply,
	reply-all: $fa-var-reply-all,
	mail-reply-all: $fa-var-mail-reply-all,
	reply-clock: $fa-var-reply-clock,
	reply-time: $fa-var-reply-time,
	republican: $fa-var-republican,
	restroom: $fa-var-restroom,
	restroom-simple: $fa-var-restroom-simple,
	retweet: $fa-var-retweet,
	rhombus: $fa-var-rhombus,
	ribbon: $fa-var-ribbon,
	right: $fa-var-right,
	arrow-alt-right: $fa-var-arrow-alt-right,
	right-from-bracket: $fa-var-right-from-bracket,
	sign-out-alt: $fa-var-sign-out-alt,
	right-from-line: $fa-var-right-from-line,
	arrow-alt-from-left: $fa-var-arrow-alt-from-left,
	right-left: $fa-var-right-left,
	exchange-alt: $fa-var-exchange-alt,
	right-long: $fa-var-right-long,
	long-arrow-alt-right: $fa-var-long-arrow-alt-right,
	right-to-bracket: $fa-var-right-to-bracket,
	sign-in-alt: $fa-var-sign-in-alt,
	right-to-line: $fa-var-right-to-line,
	arrow-alt-to-right: $fa-var-arrow-alt-to-right,
	ring: $fa-var-ring,
	rings-wedding: $fa-var-rings-wedding,
	road: $fa-var-road,
	robot: $fa-var-robot,
	rocket: $fa-var-rocket,
	rocket-launch: $fa-var-rocket-launch,
	rotate: $fa-var-rotate,
	sync-alt: $fa-var-sync-alt,
	rotate-exclamation: $fa-var-rotate-exclamation,
	rotate-forward: $fa-var-rotate-forward,
	redo-alt: $fa-var-redo-alt,
	rotate-left: $fa-var-rotate-left,
	undo-alt: $fa-var-undo-alt,
	route: $fa-var-route,
	route-highway: $fa-var-route-highway,
	route-interstate: $fa-var-route-interstate,
	router: $fa-var-router,
	rss: $fa-var-rss,
	feed: $fa-var-feed,
	ruble-sign: $fa-var-ruble-sign,
	rouble: $fa-var-rouble,
	rub: $fa-var-rub,
	ruble: $fa-var-ruble,
	ruler: $fa-var-ruler,
	ruler-combined: $fa-var-ruler-combined,
	ruler-horizontal: $fa-var-ruler-horizontal,
	ruler-triangle: $fa-var-ruler-triangle,
	ruler-vertical: $fa-var-ruler-vertical,
	rupee-sign: $fa-var-rupee-sign,
	rupee: $fa-var-rupee,
	rupiah-sign: $fa-var-rupiah-sign,
	rv: $fa-var-rv,
	s: $fa-var-s,
	sack: $fa-var-sack,
	sack-dollar: $fa-var-sack-dollar,
	salad: $fa-var-salad,
	bowl-salad: $fa-var-bowl-salad,
	sandwich: $fa-var-sandwich,
	satellite: $fa-var-satellite,
	satellite-dish: $fa-var-satellite-dish,
	sausage: $fa-var-sausage,
	saxophone: $fa-var-saxophone,
	saxophone-fire: $fa-var-saxophone-fire,
	sax-hot: $fa-var-sax-hot,
	scale-balanced: $fa-var-scale-balanced,
	balance-scale: $fa-var-balance-scale,
	scale-unbalanced: $fa-var-scale-unbalanced,
	balance-scale-left: $fa-var-balance-scale-left,
	scale-unbalanced-flip: $fa-var-scale-unbalanced-flip,
	balance-scale-right: $fa-var-balance-scale-right,
	scalpel: $fa-var-scalpel,
	scalpel-line-dashed: $fa-var-scalpel-line-dashed,
	scalpel-path: $fa-var-scalpel-path,
	scanner: $fa-var-scanner,
	scanner-image: $fa-var-scanner-image,
	scanner-gun: $fa-var-scanner-gun,
	scanner-keyboard: $fa-var-scanner-keyboard,
	scanner-touchscreen: $fa-var-scanner-touchscreen,
	scarecrow: $fa-var-scarecrow,
	scarf: $fa-var-scarf,
	school: $fa-var-school,
	scissors: $fa-var-scissors,
	cut: $fa-var-cut,
	screen-users: $fa-var-screen-users,
	users-class: $fa-var-users-class,
	screencast: $fa-var-screencast,
	screwdriver: $fa-var-screwdriver,
	screwdriver-wrench: $fa-var-screwdriver-wrench,
	tools: $fa-var-tools,
	scribble: $fa-var-scribble,
	scroll: $fa-var-scroll,
	scroll-old: $fa-var-scroll-old,
	scroll-torah: $fa-var-scroll-torah,
	torah: $fa-var-torah,
	scrubber: $fa-var-scrubber,
	scythe: $fa-var-scythe,
	sd-card: $fa-var-sd-card,
	sd-cards: $fa-var-sd-cards,
	seal: $fa-var-seal,
	seal-exclamation: $fa-var-seal-exclamation,
	seal-question: $fa-var-seal-question,
	seat-airline: $fa-var-seat-airline,
	section: $fa-var-section,
	seedling: $fa-var-seedling,
	sprout: $fa-var-sprout,
	send-back: $fa-var-send-back,
	send-backward: $fa-var-send-backward,
	sensor: $fa-var-sensor,
	sensor-cloud: $fa-var-sensor-cloud,
	sensor-smoke: $fa-var-sensor-smoke,
	sensor-fire: $fa-var-sensor-fire,
	sensor-on: $fa-var-sensor-on,
	sensor-triangle-exclamation: $fa-var-sensor-triangle-exclamation,
	sensor-alert: $fa-var-sensor-alert,
	server: $fa-var-server,
	shapes: $fa-var-shapes,
	triangle-circle-square: $fa-var-triangle-circle-square,
	share: $fa-var-share,
	arrow-turn-right: $fa-var-arrow-turn-right,
	mail-forward: $fa-var-mail-forward,
	share-all: $fa-var-share-all,
	arrows-turn-right: $fa-var-arrows-turn-right,
	share-from-square: $fa-var-share-from-square,
	share-square: $fa-var-share-square,
	share-nodes: $fa-var-share-nodes,
	share-alt: $fa-var-share-alt,
	sheep: $fa-var-sheep,
	shekel-sign: $fa-var-shekel-sign,
	sheqel-sign: $fa-var-sheqel-sign,
	shekel: $fa-var-shekel,
	sheqel: $fa-var-sheqel,
	ils: $fa-var-ils,
	shelves: $fa-var-shelves,
	inventory: $fa-var-inventory,
	shelves-empty: $fa-var-shelves-empty,
	shield: $fa-var-shield,
	shield-blank: $fa-var-shield-blank,
	shield-alt: $fa-var-shield-alt,
	shield-check: $fa-var-shield-check,
	shield-cross: $fa-var-shield-cross,
	shield-exclamation: $fa-var-shield-exclamation,
	shield-keyhole: $fa-var-shield-keyhole,
	shield-minus: $fa-var-shield-minus,
	shield-plus: $fa-var-shield-plus,
	shield-slash: $fa-var-shield-slash,
	shield-virus: $fa-var-shield-virus,
	shield-xmark: $fa-var-shield-xmark,
	shield-times: $fa-var-shield-times,
	ship: $fa-var-ship,
	shish-kebab: $fa-var-shish-kebab,
	shoe-prints: $fa-var-shoe-prints,
	shop: $fa-var-shop,
	store-alt: $fa-var-store-alt,
	shop-slash: $fa-var-shop-slash,
	store-alt-slash: $fa-var-store-alt-slash,
	shovel: $fa-var-shovel,
	shovel-snow: $fa-var-shovel-snow,
	shower: $fa-var-shower,
	shower-down: $fa-var-shower-down,
	shower-alt: $fa-var-shower-alt,
	shredder: $fa-var-shredder,
	shuffle: $fa-var-shuffle,
	random: $fa-var-random,
	shuttle-space: $fa-var-shuttle-space,
	space-shuttle: $fa-var-space-shuttle,
	shuttlecock: $fa-var-shuttlecock,
	sickle: $fa-var-sickle,
	sidebar: $fa-var-sidebar,
	sidebar-flip: $fa-var-sidebar-flip,
	sigma: $fa-var-sigma,
	sign-hanging: $fa-var-sign-hanging,
	sign: $fa-var-sign,
	signal: $fa-var-signal,
	signal-perfect: $fa-var-signal-perfect,
	signal-5: $fa-var-signal-5,
	signal-bars: $fa-var-signal-bars,
	signal-bars-strong: $fa-var-signal-bars-strong,
	signal-alt: $fa-var-signal-alt,
	signal-alt-4: $fa-var-signal-alt-4,
	signal-bars-fair: $fa-var-signal-bars-fair,
	signal-alt-2: $fa-var-signal-alt-2,
	signal-bars-good: $fa-var-signal-bars-good,
	signal-alt-3: $fa-var-signal-alt-3,
	signal-bars-slash: $fa-var-signal-bars-slash,
	signal-alt-slash: $fa-var-signal-alt-slash,
	signal-bars-weak: $fa-var-signal-bars-weak,
	signal-alt-1: $fa-var-signal-alt-1,
	signal-fair: $fa-var-signal-fair,
	signal-2: $fa-var-signal-2,
	signal-good: $fa-var-signal-good,
	signal-3: $fa-var-signal-3,
	signal-slash: $fa-var-signal-slash,
	signal-stream: $fa-var-signal-stream,
	signal-stream-slash: $fa-var-signal-stream-slash,
	signal-strong: $fa-var-signal-strong,
	signal-4: $fa-var-signal-4,
	signal-weak: $fa-var-signal-weak,
	signal-1: $fa-var-signal-1,
	signature: $fa-var-signature,
	signs-post: $fa-var-signs-post,
	map-signs: $fa-var-map-signs,
	sim-card: $fa-var-sim-card,
	sim-cards: $fa-var-sim-cards,
	sink: $fa-var-sink,
	siren: $fa-var-siren,
	siren-on: $fa-var-siren-on,
	sitemap: $fa-var-sitemap,
	skeleton: $fa-var-skeleton,
	skull: $fa-var-skull,
	skull-cow: $fa-var-skull-cow,
	skull-crossbones: $fa-var-skull-crossbones,
	slash: $fa-var-slash,
	sleigh: $fa-var-sleigh,
	slider: $fa-var-slider,
	sliders: $fa-var-sliders,
	sliders-h: $fa-var-sliders-h,
	sliders-up: $fa-var-sliders-up,
	sliders-v: $fa-var-sliders-v,
	smog: $fa-var-smog,
	smoke: $fa-var-smoke,
	smoking: $fa-var-smoking,
	snake: $fa-var-snake,
	snooze: $fa-var-snooze,
	zzz: $fa-var-zzz,
	snow-blowing: $fa-var-snow-blowing,
	snowflake: $fa-var-snowflake,
	snowflakes: $fa-var-snowflakes,
	snowman: $fa-var-snowman,
	snowman-head: $fa-var-snowman-head,
	frosty-head: $fa-var-frosty-head,
	snowplow: $fa-var-snowplow,
	soap: $fa-var-soap,
	socks: $fa-var-socks,
	solar-panel: $fa-var-solar-panel,
	solar-system: $fa-var-solar-system,
	sort: $fa-var-sort,
	unsorted: $fa-var-unsorted,
	sort-down: $fa-var-sort-down,
	sort-desc: $fa-var-sort-desc,
	sort-up: $fa-var-sort-up,
	sort-asc: $fa-var-sort-asc,
	spa: $fa-var-spa,
	space-station-moon: $fa-var-space-station-moon,
	space-station-moon-construction: $fa-var-space-station-moon-construction,
	space-station-moon-alt: $fa-var-space-station-moon-alt,
	spade: $fa-var-spade,
	spaghetti-monster-flying: $fa-var-spaghetti-monster-flying,
	pastafarianism: $fa-var-pastafarianism,
	sparkles: $fa-var-sparkles,
	speaker: $fa-var-speaker,
	speakers: $fa-var-speakers,
	spell-check: $fa-var-spell-check,
	spider: $fa-var-spider,
	spider-black-widow: $fa-var-spider-black-widow,
	spider-web: $fa-var-spider-web,
	spinner: $fa-var-spinner,
	spinner-third: $fa-var-spinner-third,
	split: $fa-var-split,
	splotch: $fa-var-splotch,
	spoon: $fa-var-spoon,
	utensil-spoon: $fa-var-utensil-spoon,
	spray-can: $fa-var-spray-can,
	sprinkler: $fa-var-sprinkler,
	square: $fa-var-square,
	square-0: $fa-var-square-0,
	square-1: $fa-var-square-1,
	square-2: $fa-var-square-2,
	square-3: $fa-var-square-3,
	square-4: $fa-var-square-4,
	square-5: $fa-var-square-5,
	square-6: $fa-var-square-6,
	square-7: $fa-var-square-7,
	square-8: $fa-var-square-8,
	square-9: $fa-var-square-9,
	square-a: $fa-var-square-a,
	square-ampersand: $fa-var-square-ampersand,
	square-arrow-down: $fa-var-square-arrow-down,
	arrow-square-down: $fa-var-arrow-square-down,
	square-arrow-down-left: $fa-var-square-arrow-down-left,
	square-arrow-down-right: $fa-var-square-arrow-down-right,
	square-arrow-left: $fa-var-square-arrow-left,
	arrow-square-left: $fa-var-arrow-square-left,
	square-arrow-right: $fa-var-square-arrow-right,
	arrow-square-right: $fa-var-arrow-square-right,
	square-arrow-up: $fa-var-square-arrow-up,
	arrow-square-up: $fa-var-arrow-square-up,
	square-arrow-up-left: $fa-var-square-arrow-up-left,
	square-arrow-up-right: $fa-var-square-arrow-up-right,
	external-link-square: $fa-var-external-link-square,
	square-b: $fa-var-square-b,
	square-bolt: $fa-var-square-bolt,
	square-c: $fa-var-square-c,
	square-caret-down: $fa-var-square-caret-down,
	caret-square-down: $fa-var-caret-square-down,
	square-caret-left: $fa-var-square-caret-left,
	caret-square-left: $fa-var-caret-square-left,
	square-caret-right: $fa-var-square-caret-right,
	caret-square-right: $fa-var-caret-square-right,
	square-caret-up: $fa-var-square-caret-up,
	caret-square-up: $fa-var-caret-square-up,
	square-check: $fa-var-square-check,
	check-square: $fa-var-check-square,
	square-chevron-down: $fa-var-square-chevron-down,
	chevron-square-down: $fa-var-chevron-square-down,
	square-chevron-left: $fa-var-square-chevron-left,
	chevron-square-left: $fa-var-chevron-square-left,
	square-chevron-right: $fa-var-square-chevron-right,
	chevron-square-right: $fa-var-chevron-square-right,
	square-chevron-up: $fa-var-square-chevron-up,
	chevron-square-up: $fa-var-chevron-square-up,
	square-code: $fa-var-square-code,
	square-d: $fa-var-square-d,
	square-dashed: $fa-var-square-dashed,
	square-divide: $fa-var-square-divide,
	square-dollar: $fa-var-square-dollar,
	usd-square: $fa-var-usd-square,
	dollar-square: $fa-var-dollar-square,
	square-down: $fa-var-square-down,
	arrow-alt-square-down: $fa-var-arrow-alt-square-down,
	square-down-left: $fa-var-square-down-left,
	square-down-right: $fa-var-square-down-right,
	square-e: $fa-var-square-e,
	square-envelope: $fa-var-square-envelope,
	envelope-square: $fa-var-envelope-square,
	square-exclamation: $fa-var-square-exclamation,
	exclamation-square: $fa-var-exclamation-square,
	square-f: $fa-var-square-f,
	square-fragile: $fa-var-square-fragile,
	box-fragile: $fa-var-box-fragile,
	square-wine-glass-crack: $fa-var-square-wine-glass-crack,
	square-full: $fa-var-square-full,
	square-g: $fa-var-square-g,
	square-h: $fa-var-square-h,
	h-square: $fa-var-h-square,
	square-heart: $fa-var-square-heart,
	heart-square: $fa-var-heart-square,
	square-i: $fa-var-square-i,
	square-info: $fa-var-square-info,
	info-square: $fa-var-info-square,
	square-j: $fa-var-square-j,
	square-k: $fa-var-square-k,
	square-l: $fa-var-square-l,
	square-left: $fa-var-square-left,
	arrow-alt-square-left: $fa-var-arrow-alt-square-left,
	square-m: $fa-var-square-m,
	square-minus: $fa-var-square-minus,
	minus-square: $fa-var-minus-square,
	square-n: $fa-var-square-n,
	square-o: $fa-var-square-o,
	square-p: $fa-var-square-p,
	square-parking: $fa-var-square-parking,
	parking: $fa-var-parking,
	square-parking-slash: $fa-var-square-parking-slash,
	parking-slash: $fa-var-parking-slash,
	square-pen: $fa-var-square-pen,
	pen-square: $fa-var-pen-square,
	pencil-square: $fa-var-pencil-square,
	square-phone: $fa-var-square-phone,
	phone-square: $fa-var-phone-square,
	square-phone-flip: $fa-var-square-phone-flip,
	phone-square-alt: $fa-var-phone-square-alt,
	square-phone-hangup: $fa-var-square-phone-hangup,
	phone-square-down: $fa-var-phone-square-down,
	square-plus: $fa-var-square-plus,
	plus-square: $fa-var-plus-square,
	square-poll-horizontal: $fa-var-square-poll-horizontal,
	poll-h: $fa-var-poll-h,
	square-poll-vertical: $fa-var-square-poll-vertical,
	poll: $fa-var-poll,
	square-q: $fa-var-square-q,
	square-question: $fa-var-square-question,
	question-square: $fa-var-question-square,
	square-r: $fa-var-square-r,
	square-right: $fa-var-square-right,
	arrow-alt-square-right: $fa-var-arrow-alt-square-right,
	square-root: $fa-var-square-root,
	square-root-variable: $fa-var-square-root-variable,
	square-root-alt: $fa-var-square-root-alt,
	square-rss: $fa-var-square-rss,
	rss-square: $fa-var-rss-square,
	square-s: $fa-var-square-s,
	square-share-nodes: $fa-var-square-share-nodes,
	share-alt-square: $fa-var-share-alt-square,
	square-sliders: $fa-var-square-sliders,
	sliders-h-square: $fa-var-sliders-h-square,
	square-sliders-vertical: $fa-var-square-sliders-vertical,
	sliders-v-square: $fa-var-sliders-v-square,
	square-small: $fa-var-square-small,
	square-star: $fa-var-square-star,
	square-t: $fa-var-square-t,
	square-this-way-up: $fa-var-square-this-way-up,
	box-up: $fa-var-box-up,
	square-u: $fa-var-square-u,
	square-up: $fa-var-square-up,
	arrow-alt-square-up: $fa-var-arrow-alt-square-up,
	square-up-left: $fa-var-square-up-left,
	square-up-right: $fa-var-square-up-right,
	external-link-square-alt: $fa-var-external-link-square-alt,
	square-user: $fa-var-square-user,
	square-v: $fa-var-square-v,
	square-w: $fa-var-square-w,
	square-x: $fa-var-square-x,
	square-xmark: $fa-var-square-xmark,
	times-square: $fa-var-times-square,
	xmark-square: $fa-var-xmark-square,
	square-y: $fa-var-square-y,
	square-z: $fa-var-square-z,
	squirrel: $fa-var-squirrel,
	staff: $fa-var-staff,
	stairs: $fa-var-stairs,
	stamp: $fa-var-stamp,
	standard-definition: $fa-var-standard-definition,
	star: $fa-var-star,
	star-and-crescent: $fa-var-star-and-crescent,
	star-christmas: $fa-var-star-christmas,
	star-exclamation: $fa-var-star-exclamation,
	star-half: $fa-var-star-half,
	star-half-stroke: $fa-var-star-half-stroke,
	star-half-alt: $fa-var-star-half-alt,
	star-of-david: $fa-var-star-of-david,
	star-of-life: $fa-var-star-of-life,
	star-sharp: $fa-var-star-sharp,
	star-sharp-half: $fa-var-star-sharp-half,
	star-sharp-half-stroke: $fa-var-star-sharp-half-stroke,
	star-sharp-half-alt: $fa-var-star-sharp-half-alt,
	star-shooting: $fa-var-star-shooting,
	starfighter: $fa-var-starfighter,
	starfighter-twin-ion-engine: $fa-var-starfighter-twin-ion-engine,
	starfighter-alt: $fa-var-starfighter-alt,
	starfighter-twin-ion-engine-advanced: $fa-var-starfighter-twin-ion-engine-advanced,
	starfighter-alt-advanced: $fa-var-starfighter-alt-advanced,
	stars: $fa-var-stars,
	starship: $fa-var-starship,
	starship-freighter: $fa-var-starship-freighter,
	steak: $fa-var-steak,
	steering-wheel: $fa-var-steering-wheel,
	sterling-sign: $fa-var-sterling-sign,
	pound-sign: $fa-var-pound-sign,
	gbp: $fa-var-gbp,
	stethoscope: $fa-var-stethoscope,
	stocking: $fa-var-stocking,
	stomach: $fa-var-stomach,
	stop: $fa-var-stop,
	stopwatch: $fa-var-stopwatch,
	stopwatch-20: $fa-var-stopwatch-20,
	store: $fa-var-store,
	store-slash: $fa-var-store-slash,
	street-view: $fa-var-street-view,
	stretcher: $fa-var-stretcher,
	strikethrough: $fa-var-strikethrough,
	stroopwafel: $fa-var-stroopwafel,
	subscript: $fa-var-subscript,
	suitcase: $fa-var-suitcase,
	suitcase-medical: $fa-var-suitcase-medical,
	medkit: $fa-var-medkit,
	suitcase-rolling: $fa-var-suitcase-rolling,
	sun: $fa-var-sun,
	sun-bright: $fa-var-sun-bright,
	sun-alt: $fa-var-sun-alt,
	sun-cloud: $fa-var-sun-cloud,
	sun-dust: $fa-var-sun-dust,
	sun-haze: $fa-var-sun-haze,
	sunglasses: $fa-var-sunglasses,
	sunrise: $fa-var-sunrise,
	sunset: $fa-var-sunset,
	superscript: $fa-var-superscript,
	swatchbook: $fa-var-swatchbook,
	sword: $fa-var-sword,
	sword-laser: $fa-var-sword-laser,
	sword-laser-alt: $fa-var-sword-laser-alt,
	swords: $fa-var-swords,
	swords-laser: $fa-var-swords-laser,
	symbols: $fa-var-symbols,
	icons-alt: $fa-var-icons-alt,
	synagogue: $fa-var-synagogue,
	syringe: $fa-var-syringe,
	t: $fa-var-t,
	table: $fa-var-table,
	table-cells: $fa-var-table-cells,
	th: $fa-var-th,
	table-cells-large: $fa-var-table-cells-large,
	th-large: $fa-var-th-large,
	table-columns: $fa-var-table-columns,
	columns: $fa-var-columns,
	table-layout: $fa-var-table-layout,
	table-list: $fa-var-table-list,
	th-list: $fa-var-th-list,
	table-pivot: $fa-var-table-pivot,
	table-rows: $fa-var-table-rows,
	rows: $fa-var-rows,
	table-tennis-paddle-ball: $fa-var-table-tennis-paddle-ball,
	ping-pong-paddle-ball: $fa-var-ping-pong-paddle-ball,
	table-tennis: $fa-var-table-tennis,
	table-tree: $fa-var-table-tree,
	tablet: $fa-var-tablet,
	tablet-android: $fa-var-tablet-android,
	tablet-button: $fa-var-tablet-button,
	tablet-rugged: $fa-var-tablet-rugged,
	tablet-screen: $fa-var-tablet-screen,
	tablet-android-alt: $fa-var-tablet-android-alt,
	tablet-screen-button: $fa-var-tablet-screen-button,
	tablet-alt: $fa-var-tablet-alt,
	tablets: $fa-var-tablets,
	tachograph-digital: $fa-var-tachograph-digital,
	digital-tachograph: $fa-var-digital-tachograph,
	taco: $fa-var-taco,
	tag: $fa-var-tag,
	tags: $fa-var-tags,
	tally: $fa-var-tally,
	tally-5: $fa-var-tally-5,
	tally-1: $fa-var-tally-1,
	tally-2: $fa-var-tally-2,
	tally-3: $fa-var-tally-3,
	tally-4: $fa-var-tally-4,
	tape: $fa-var-tape,
	taxi: $fa-var-taxi,
	cab: $fa-var-cab,
	taxi-bus: $fa-var-taxi-bus,
	teeth: $fa-var-teeth,
	teeth-open: $fa-var-teeth-open,
	telescope: $fa-var-telescope,
	temperature-arrow-down: $fa-var-temperature-arrow-down,
	temperature-down: $fa-var-temperature-down,
	temperature-arrow-up: $fa-var-temperature-arrow-up,
	temperature-up: $fa-var-temperature-up,
	temperature-empty: $fa-var-temperature-empty,
	thermometer-empty: $fa-var-thermometer-empty,
	thermometer-0: $fa-var-thermometer-0,
	temperature-0: $fa-var-temperature-0,
	temperature-full: $fa-var-temperature-full,
	thermometer-full: $fa-var-thermometer-full,
	thermometer-4: $fa-var-thermometer-4,
	temperature-4: $fa-var-temperature-4,
	temperature-half: $fa-var-temperature-half,
	thermometer-half: $fa-var-thermometer-half,
	thermometer-2: $fa-var-thermometer-2,
	temperature-2: $fa-var-temperature-2,
	temperature-high: $fa-var-temperature-high,
	temperature-list: $fa-var-temperature-list,
	temperature-low: $fa-var-temperature-low,
	temperature-quarter: $fa-var-temperature-quarter,
	thermometer-quarter: $fa-var-thermometer-quarter,
	thermometer-1: $fa-var-thermometer-1,
	temperature-1: $fa-var-temperature-1,
	temperature-snow: $fa-var-temperature-snow,
	temperature-frigid: $fa-var-temperature-frigid,
	temperature-sun: $fa-var-temperature-sun,
	temperature-hot: $fa-var-temperature-hot,
	temperature-three-quarters: $fa-var-temperature-three-quarters,
	thermometer-three-quarters: $fa-var-thermometer-three-quarters,
	thermometer-3: $fa-var-thermometer-3,
	temperature-3: $fa-var-temperature-3,
	tenge-sign: $fa-var-tenge-sign,
	tenge: $fa-var-tenge,
	tennis-ball: $fa-var-tennis-ball,
	terminal: $fa-var-terminal,
	text: $fa-var-text,
	text-height: $fa-var-text-height,
	text-size: $fa-var-text-size,
	text-slash: $fa-var-text-slash,
	remove-format: $fa-var-remove-format,
	text-width: $fa-var-text-width,
	thermometer: $fa-var-thermometer,
	theta: $fa-var-theta,
	thumbs-down: $fa-var-thumbs-down,
	thumbs-up: $fa-var-thumbs-up,
	thumbtack: $fa-var-thumbtack,
	thumb-tack: $fa-var-thumb-tack,
	ticket: $fa-var-ticket,
	ticket-airline: $fa-var-ticket-airline,
	ticket-simple: $fa-var-ticket-simple,
	ticket-alt: $fa-var-ticket-alt,
	tickets-airline: $fa-var-tickets-airline,
	tilde: $fa-var-tilde,
	timeline: $fa-var-timeline,
	timeline-arrow: $fa-var-timeline-arrow,
	timer: $fa-var-timer,
	tire: $fa-var-tire,
	tire-flat: $fa-var-tire-flat,
	tire-pressure-warning: $fa-var-tire-pressure-warning,
	tire-rugged: $fa-var-tire-rugged,
	toggle-off: $fa-var-toggle-off,
	toggle-on: $fa-var-toggle-on,
	toilet: $fa-var-toilet,
	toilet-paper: $fa-var-toilet-paper,
	toilet-paper-blank: $fa-var-toilet-paper-blank,
	toilet-paper-alt: $fa-var-toilet-paper-alt,
	toilet-paper-blank-under: $fa-var-toilet-paper-blank-under,
	toilet-paper-reverse-alt: $fa-var-toilet-paper-reverse-alt,
	toilet-paper-slash: $fa-var-toilet-paper-slash,
	toilet-paper-under: $fa-var-toilet-paper-under,
	toilet-paper-reverse: $fa-var-toilet-paper-reverse,
	toilet-paper-under-slash: $fa-var-toilet-paper-under-slash,
	toilet-paper-reverse-slash: $fa-var-toilet-paper-reverse-slash,
	tombstone: $fa-var-tombstone,
	tombstone-blank: $fa-var-tombstone-blank,
	tombstone-alt: $fa-var-tombstone-alt,
	toolbox: $fa-var-toolbox,
	tooth: $fa-var-tooth,
	toothbrush: $fa-var-toothbrush,
	torii-gate: $fa-var-torii-gate,
	tornado: $fa-var-tornado,
	tower-broadcast: $fa-var-tower-broadcast,
	broadcast-tower: $fa-var-broadcast-tower,
	tower-control: $fa-var-tower-control,
	tractor: $fa-var-tractor,
	trademark: $fa-var-trademark,
	traffic-cone: $fa-var-traffic-cone,
	traffic-light: $fa-var-traffic-light,
	traffic-light-go: $fa-var-traffic-light-go,
	traffic-light-slow: $fa-var-traffic-light-slow,
	traffic-light-stop: $fa-var-traffic-light-stop,
	trailer: $fa-var-trailer,
	train: $fa-var-train,
	train-subway: $fa-var-train-subway,
	subway: $fa-var-subway,
	train-subway-tunnel: $fa-var-train-subway-tunnel,
	subway-tunnel: $fa-var-subway-tunnel,
	train-tram: $fa-var-train-tram,
	tram: $fa-var-tram,
	transformer-bolt: $fa-var-transformer-bolt,
	transgender: $fa-var-transgender,
	transgender-alt: $fa-var-transgender-alt,
	transporter: $fa-var-transporter,
	transporter-1: $fa-var-transporter-1,
	transporter-2: $fa-var-transporter-2,
	transporter-3: $fa-var-transporter-3,
	transporter-4: $fa-var-transporter-4,
	transporter-5: $fa-var-transporter-5,
	transporter-6: $fa-var-transporter-6,
	transporter-7: $fa-var-transporter-7,
	transporter-empty: $fa-var-transporter-empty,
	trash: $fa-var-trash,
	trash-arrow-up: $fa-var-trash-arrow-up,
	trash-restore: $fa-var-trash-restore,
	trash-can: $fa-var-trash-can,
	trash-alt: $fa-var-trash-alt,
	trash-can-arrow-up: $fa-var-trash-can-arrow-up,
	trash-restore-alt: $fa-var-trash-restore-alt,
	trash-can-clock: $fa-var-trash-can-clock,
	trash-can-list: $fa-var-trash-can-list,
	trash-can-slash: $fa-var-trash-can-slash,
	trash-alt-slash: $fa-var-trash-alt-slash,
	trash-can-undo: $fa-var-trash-can-undo,
	trash-can-arrow-turn-left: $fa-var-trash-can-arrow-turn-left,
	trash-undo-alt: $fa-var-trash-undo-alt,
	trash-clock: $fa-var-trash-clock,
	trash-list: $fa-var-trash-list,
	trash-slash: $fa-var-trash-slash,
	trash-undo: $fa-var-trash-undo,
	trash-arrow-turn-left: $fa-var-trash-arrow-turn-left,
	treasure-chest: $fa-var-treasure-chest,
	tree: $fa-var-tree,
	tree-christmas: $fa-var-tree-christmas,
	tree-deciduous: $fa-var-tree-deciduous,
	tree-alt: $fa-var-tree-alt,
	tree-decorated: $fa-var-tree-decorated,
	tree-large: $fa-var-tree-large,
	tree-palm: $fa-var-tree-palm,
	trees: $fa-var-trees,
	triangle: $fa-var-triangle,
	triangle-exclamation: $fa-var-triangle-exclamation,
	exclamation-triangle: $fa-var-exclamation-triangle,
	warning: $fa-var-warning,
	triangle-instrument: $fa-var-triangle-instrument,
	triangle-music: $fa-var-triangle-music,
	triangle-person-digging: $fa-var-triangle-person-digging,
	construction: $fa-var-construction,
	trophy: $fa-var-trophy,
	trophy-star: $fa-var-trophy-star,
	trophy-alt: $fa-var-trophy-alt,
	truck: $fa-var-truck,
	truck-clock: $fa-var-truck-clock,
	shipping-timed: $fa-var-shipping-timed,
	truck-container: $fa-var-truck-container,
	truck-container-empty: $fa-var-truck-container-empty,
	truck-fast: $fa-var-truck-fast,
	shipping-fast: $fa-var-shipping-fast,
	truck-flatbed: $fa-var-truck-flatbed,
	truck-front: $fa-var-truck-front,
	truck-medical: $fa-var-truck-medical,
	ambulance: $fa-var-ambulance,
	truck-monster: $fa-var-truck-monster,
	truck-moving: $fa-var-truck-moving,
	truck-pickup: $fa-var-truck-pickup,
	truck-plow: $fa-var-truck-plow,
	truck-ramp: $fa-var-truck-ramp,
	truck-ramp-box: $fa-var-truck-ramp-box,
	truck-loading: $fa-var-truck-loading,
	truck-ramp-couch: $fa-var-truck-ramp-couch,
	truck-couch: $fa-var-truck-couch,
	truck-tow: $fa-var-truck-tow,
	trumpet: $fa-var-trumpet,
	tshirt: $fa-var-tshirt,
	tty: $fa-var-tty,
	teletype: $fa-var-teletype,
	tty-answer: $fa-var-tty-answer,
	teletype-answer: $fa-var-teletype-answer,
	tugrik-sign: $fa-var-tugrik-sign,
	turkey: $fa-var-turkey,
	turkish-lira-sign: $fa-var-turkish-lira-sign,
	try: $fa-var-try,
	turkish-lira: $fa-var-turkish-lira,
	turn-down: $fa-var-turn-down,
	level-down-alt: $fa-var-level-down-alt,
	turn-up: $fa-var-turn-up,
	level-up-alt: $fa-var-level-up-alt,
	turntable: $fa-var-turntable,
	turtle: $fa-var-turtle,
	tv: $fa-var-tv,
	tv-alt: $fa-var-tv-alt,
	television: $fa-var-television,
	tv-music: $fa-var-tv-music,
	tv-retro: $fa-var-tv-retro,
	typewriter: $fa-var-typewriter,
	u: $fa-var-u,
	ufo: $fa-var-ufo,
	ufo-beam: $fa-var-ufo-beam,
	umbrella: $fa-var-umbrella,
	umbrella-beach: $fa-var-umbrella-beach,
	umbrella-simple: $fa-var-umbrella-simple,
	umbrella-alt: $fa-var-umbrella-alt,
	underline: $fa-var-underline,
	unicorn: $fa-var-unicorn,
	union: $fa-var-union,
	universal-access: $fa-var-universal-access,
	unlock: $fa-var-unlock,
	unlock-keyhole: $fa-var-unlock-keyhole,
	unlock-alt: $fa-var-unlock-alt,
	up: $fa-var-up,
	arrow-alt-up: $fa-var-arrow-alt-up,
	up-down: $fa-var-up-down,
	arrows-alt-v: $fa-var-arrows-alt-v,
	up-down-left-right: $fa-var-up-down-left-right,
	arrows-alt: $fa-var-arrows-alt,
	up-from-line: $fa-var-up-from-line,
	arrow-alt-from-bottom: $fa-var-arrow-alt-from-bottom,
	up-left: $fa-var-up-left,
	up-long: $fa-var-up-long,
	long-arrow-alt-up: $fa-var-long-arrow-alt-up,
	up-right: $fa-var-up-right,
	up-right-and-down-left-from-center: $fa-var-up-right-and-down-left-from-center,
	expand-alt: $fa-var-expand-alt,
	up-right-from-square: $fa-var-up-right-from-square,
	external-link-alt: $fa-var-external-link-alt,
	up-to-line: $fa-var-up-to-line,
	arrow-alt-to-top: $fa-var-arrow-alt-to-top,
	upload: $fa-var-upload,
	usb-drive: $fa-var-usb-drive,
	user: $fa-var-user,
	user-alien: $fa-var-user-alien,
	user-astronaut: $fa-var-user-astronaut,
	user-bounty-hunter: $fa-var-user-bounty-hunter,
	user-check: $fa-var-user-check,
	user-clock: $fa-var-user-clock,
	user-cowboy: $fa-var-user-cowboy,
	user-crown: $fa-var-user-crown,
	user-doctor: $fa-var-user-doctor,
	user-md: $fa-var-user-md,
	user-doctor-message: $fa-var-user-doctor-message,
	user-md-chat: $fa-var-user-md-chat,
	user-gear: $fa-var-user-gear,
	user-cog: $fa-var-user-cog,
	user-graduate: $fa-var-user-graduate,
	user-group: $fa-var-user-group,
	user-friends: $fa-var-user-friends,
	user-group-crown: $fa-var-user-group-crown,
	users-crown: $fa-var-users-crown,
	user-headset: $fa-var-user-headset,
	user-helmet-safety: $fa-var-user-helmet-safety,
	user-construction: $fa-var-user-construction,
	user-hard-hat: $fa-var-user-hard-hat,
	user-injured: $fa-var-user-injured,
	user-large: $fa-var-user-large,
	user-alt: $fa-var-user-alt,
	user-large-slash: $fa-var-user-large-slash,
	user-alt-slash: $fa-var-user-alt-slash,
	user-lock: $fa-var-user-lock,
	user-minus: $fa-var-user-minus,
	user-music: $fa-var-user-music,
	user-ninja: $fa-var-user-ninja,
	user-nurse: $fa-var-user-nurse,
	user-pen: $fa-var-user-pen,
	user-edit: $fa-var-user-edit,
	user-pilot: $fa-var-user-pilot,
	user-pilot-tie: $fa-var-user-pilot-tie,
	user-plus: $fa-var-user-plus,
	user-robot: $fa-var-user-robot,
	user-secret: $fa-var-user-secret,
	user-shakespeare: $fa-var-user-shakespeare,
	user-shield: $fa-var-user-shield,
	user-slash: $fa-var-user-slash,
	user-tag: $fa-var-user-tag,
	user-tie: $fa-var-user-tie,
	user-unlock: $fa-var-user-unlock,
	user-visor: $fa-var-user-visor,
	user-xmark: $fa-var-user-xmark,
	user-times: $fa-var-user-times,
	users: $fa-var-users,
	group: $fa-var-group,
	users-gear: $fa-var-users-gear,
	users-cog: $fa-var-users-cog,
	users-medical: $fa-var-users-medical,
	users-slash: $fa-var-users-slash,
	utensils: $fa-var-utensils,
	cutlery: $fa-var-cutlery,
	utility-pole: $fa-var-utility-pole,
	utility-pole-double: $fa-var-utility-pole-double,
	v: $fa-var-v,
	vacuum: $fa-var-vacuum,
	vacuum-robot: $fa-var-vacuum-robot,
	value-absolute: $fa-var-value-absolute,
	van-shuttle: $fa-var-van-shuttle,
	shuttle-van: $fa-var-shuttle-van,
	vault: $fa-var-vault,
	vector-circle: $fa-var-vector-circle,
	vector-polygon: $fa-var-vector-polygon,
	vector-square: $fa-var-vector-square,
	venus: $fa-var-venus,
	venus-double: $fa-var-venus-double,
	venus-mars: $fa-var-venus-mars,
	vest: $fa-var-vest,
	vest-patches: $fa-var-vest-patches,
	vial: $fa-var-vial,
	vials: $fa-var-vials,
	video: $fa-var-video,
	video-camera: $fa-var-video-camera,
	video-arrow-down-left: $fa-var-video-arrow-down-left,
	video-arrow-up-right: $fa-var-video-arrow-up-right,
	video-plus: $fa-var-video-plus,
	video-slash: $fa-var-video-slash,
	vihara: $fa-var-vihara,
	violin: $fa-var-violin,
	virus: $fa-var-virus,
	virus-slash: $fa-var-virus-slash,
	viruses: $fa-var-viruses,
	voicemail: $fa-var-voicemail,
	volcano: $fa-var-volcano,
	volleyball-ball: $fa-var-volleyball-ball,
	volume: $fa-var-volume,
	volume-medium: $fa-var-volume-medium,
	volume-high: $fa-var-volume-high,
	volume-up: $fa-var-volume-up,
	volume-low: $fa-var-volume-low,
	volume-down: $fa-var-volume-down,
	volume-off: $fa-var-volume-off,
	volume-slash: $fa-var-volume-slash,
	volume-xmark: $fa-var-volume-xmark,
	volume-times: $fa-var-volume-times,
	volume-mute: $fa-var-volume-mute,
	vr-cardboard: $fa-var-vr-cardboard,
	w: $fa-var-w,
	wagon-covered: $fa-var-wagon-covered,
	walker: $fa-var-walker,
	walkie-talkie: $fa-var-walkie-talkie,
	wallet: $fa-var-wallet,
	wand: $fa-var-wand,
	wand-magic: $fa-var-wand-magic,
	magic: $fa-var-magic,
	wand-magic-sparkles: $fa-var-wand-magic-sparkles,
	magic-wand-sparkles: $fa-var-magic-wand-sparkles,
	wand-sparkles: $fa-var-wand-sparkles,
	warehouse: $fa-var-warehouse,
	warehouse-full: $fa-var-warehouse-full,
	warehouse-alt: $fa-var-warehouse-alt,
	washing-machine: $fa-var-washing-machine,
	washer: $fa-var-washer,
	watch: $fa-var-watch,
	watch-apple: $fa-var-watch-apple,
	watch-calculator: $fa-var-watch-calculator,
	watch-fitness: $fa-var-watch-fitness,
	watch-smart: $fa-var-watch-smart,
	water: $fa-var-water,
	water-arrow-down: $fa-var-water-arrow-down,
	water-lower: $fa-var-water-lower,
	water-arrow-up: $fa-var-water-arrow-up,
	water-rise: $fa-var-water-rise,
	water-ladder: $fa-var-water-ladder,
	swimming-pool: $fa-var-swimming-pool,
	ladder-water: $fa-var-ladder-water,
	wave-pulse: $fa-var-wave-pulse,
	heart-rate: $fa-var-heart-rate,
	wave-sine: $fa-var-wave-sine,
	wave-square: $fa-var-wave-square,
	wave-triangle: $fa-var-wave-triangle,
	waveform: $fa-var-waveform,
	waveform-lines: $fa-var-waveform-lines,
	weight-hanging: $fa-var-weight-hanging,
	weight-scale: $fa-var-weight-scale,
	weight: $fa-var-weight,
	whale: $fa-var-whale,
	wheat: $fa-var-wheat,
	wheat-awn: $fa-var-wheat-awn,
	wheat-alt: $fa-var-wheat-alt,
	wheelchair: $fa-var-wheelchair,
	wheelchair-move: $fa-var-wheelchair-move,
	wheelchair-alt: $fa-var-wheelchair-alt,
	whiskey-glass: $fa-var-whiskey-glass,
	glass-whiskey: $fa-var-glass-whiskey,
	whiskey-glass-ice: $fa-var-whiskey-glass-ice,
	glass-whiskey-rocks: $fa-var-glass-whiskey-rocks,
	whistle: $fa-var-whistle,
	wifi: $fa-var-wifi,
	wifi-strong: $fa-var-wifi-strong,
	wifi-3: $fa-var-wifi-3,
	wifi-exclamation: $fa-var-wifi-exclamation,
	wifi-fair: $fa-var-wifi-fair,
	wifi-2: $fa-var-wifi-2,
	wifi-slash: $fa-var-wifi-slash,
	wifi-weak: $fa-var-wifi-weak,
	wifi-1: $fa-var-wifi-1,
	wind: $fa-var-wind,
	wind-turbine: $fa-var-wind-turbine,
	wind-warning: $fa-var-wind-warning,
	wind-circle-exclamation: $fa-var-wind-circle-exclamation,
	window: $fa-var-window,
	window-flip: $fa-var-window-flip,
	window-alt: $fa-var-window-alt,
	window-frame: $fa-var-window-frame,
	window-frame-open: $fa-var-window-frame-open,
	window-maximize: $fa-var-window-maximize,
	window-minimize: $fa-var-window-minimize,
	window-restore: $fa-var-window-restore,
	windsock: $fa-var-windsock,
	wine-bottle: $fa-var-wine-bottle,
	wine-glass: $fa-var-wine-glass,
	wine-glass-crack: $fa-var-wine-glass-crack,
	fragile: $fa-var-fragile,
	wine-glass-empty: $fa-var-wine-glass-empty,
	wine-glass-alt: $fa-var-wine-glass-alt,
	won-sign: $fa-var-won-sign,
	won: $fa-var-won,
	krw: $fa-var-krw,
	wreath: $fa-var-wreath,
	wrench: $fa-var-wrench,
	wrench-simple: $fa-var-wrench-simple,
	x: $fa-var-x,
	x-ray: $fa-var-x-ray,
	xmark: $fa-var-xmark,
	multiply: $fa-var-multiply,
	times: $fa-var-times,
	close: $fa-var-close,
	remove: $fa-var-remove,
	xmark-to-slot: $fa-var-xmark-to-slot,
	times-to-slot: $fa-var-times-to-slot,
	vote-nay: $fa-var-vote-nay,
	y: $fa-var-y,
	yen-sign: $fa-var-yen-sign,
	yen: $fa-var-yen,
	cny: $fa-var-cny,
	jpy: $fa-var-jpy,
	rmb: $fa-var-rmb,
	yin-yang: $fa-var-yin-yang,
	z: $fa-var-z,
);

$fa-brand-icons: (
	500px: $fa-var-500px,
	accessible-icon: $fa-var-accessible-icon,
	accusoft: $fa-var-accusoft,
	acquisitions-incorporated: $fa-var-acquisitions-incorporated,
	adn: $fa-var-adn,
	adversal: $fa-var-adversal,
	affiliatetheme: $fa-var-affiliatetheme,
	airbnb: $fa-var-airbnb,
	algolia: $fa-var-algolia,
	alipay: $fa-var-alipay,
	amazon: $fa-var-amazon,
	amazon-pay: $fa-var-amazon-pay,
	amilia: $fa-var-amilia,
	android: $fa-var-android,
	angellist: $fa-var-angellist,
	angrycreative: $fa-var-angrycreative,
	angular: $fa-var-angular,
	app-store: $fa-var-app-store,
	app-store-ios: $fa-var-app-store-ios,
	apper: $fa-var-apper,
	apple: $fa-var-apple,
	apple-pay: $fa-var-apple-pay,
	artstation: $fa-var-artstation,
	asymmetrik: $fa-var-asymmetrik,
	atlassian: $fa-var-atlassian,
	audible: $fa-var-audible,
	autoprefixer: $fa-var-autoprefixer,
	avianex: $fa-var-avianex,
	aviato: $fa-var-aviato,
	aws: $fa-var-aws,
	bandcamp: $fa-var-bandcamp,
	battle-net: $fa-var-battle-net,
	behance: $fa-var-behance,
	behance-square: $fa-var-behance-square,
	bimobject: $fa-var-bimobject,
	bitbucket: $fa-var-bitbucket,
	bitcoin: $fa-var-bitcoin,
	bity: $fa-var-bity,
	black-tie: $fa-var-black-tie,
	blackberry: $fa-var-blackberry,
	blogger: $fa-var-blogger,
	blogger-b: $fa-var-blogger-b,
	bluetooth: $fa-var-bluetooth,
	bluetooth-b: $fa-var-bluetooth-b,
	bootstrap: $fa-var-bootstrap,
	btc: $fa-var-btc,
	buffer: $fa-var-buffer,
	buromobelexperte: $fa-var-buromobelexperte,
	buy-n-large: $fa-var-buy-n-large,
	buysellads: $fa-var-buysellads,
	canadian-maple-leaf: $fa-var-canadian-maple-leaf,
	cc-amazon-pay: $fa-var-cc-amazon-pay,
	cc-amex: $fa-var-cc-amex,
	cc-apple-pay: $fa-var-cc-apple-pay,
	cc-diners-club: $fa-var-cc-diners-club,
	cc-discover: $fa-var-cc-discover,
	cc-jcb: $fa-var-cc-jcb,
	cc-mastercard: $fa-var-cc-mastercard,
	cc-paypal: $fa-var-cc-paypal,
	cc-stripe: $fa-var-cc-stripe,
	cc-visa: $fa-var-cc-visa,
	centercode: $fa-var-centercode,
	centos: $fa-var-centos,
	chrome: $fa-var-chrome,
	chromecast: $fa-var-chromecast,
	cloudflare: $fa-var-cloudflare,
	cloudscale: $fa-var-cloudscale,
	cloudsmith: $fa-var-cloudsmith,
	cloudversify: $fa-var-cloudversify,
	codepen: $fa-var-codepen,
	codiepie: $fa-var-codiepie,
	confluence: $fa-var-confluence,
	connectdevelop: $fa-var-connectdevelop,
	contao: $fa-var-contao,
	cotton-bureau: $fa-var-cotton-bureau,
	cpanel: $fa-var-cpanel,
	creative-commons: $fa-var-creative-commons,
	creative-commons-by: $fa-var-creative-commons-by,
	creative-commons-nc: $fa-var-creative-commons-nc,
	creative-commons-nc-eu: $fa-var-creative-commons-nc-eu,
	creative-commons-nc-jp: $fa-var-creative-commons-nc-jp,
	creative-commons-nd: $fa-var-creative-commons-nd,
	creative-commons-pd: $fa-var-creative-commons-pd,
	creative-commons-pd-alt: $fa-var-creative-commons-pd-alt,
	creative-commons-remix: $fa-var-creative-commons-remix,
	creative-commons-sa: $fa-var-creative-commons-sa,
	creative-commons-sampling: $fa-var-creative-commons-sampling,
	creative-commons-sampling-plus: $fa-var-creative-commons-sampling-plus,
	creative-commons-share: $fa-var-creative-commons-share,
	creative-commons-zero: $fa-var-creative-commons-zero,
	critical-role: $fa-var-critical-role,
	css3: $fa-var-css3,
	css3-alt: $fa-var-css3-alt,
	cuttlefish: $fa-var-cuttlefish,
	d-and-d: $fa-var-d-and-d,
	d-and-d-beyond: $fa-var-d-and-d-beyond,
	dailymotion: $fa-var-dailymotion,
	dashcube: $fa-var-dashcube,
	deezer: $fa-var-deezer,
	delicious: $fa-var-delicious,
	deploydog: $fa-var-deploydog,
	deskpro: $fa-var-deskpro,
	dev: $fa-var-dev,
	deviantart: $fa-var-deviantart,
	dhl: $fa-var-dhl,
	diaspora: $fa-var-diaspora,
	digg: $fa-var-digg,
	digital-ocean: $fa-var-digital-ocean,
	discord: $fa-var-discord,
	discourse: $fa-var-discourse,
	dochub: $fa-var-dochub,
	docker: $fa-var-docker,
	draft2digital: $fa-var-draft2digital,
	dribbble: $fa-var-dribbble,
	dribbble-square: $fa-var-dribbble-square,
	dropbox: $fa-var-dropbox,
	drupal: $fa-var-drupal,
	dyalog: $fa-var-dyalog,
	earlybirds: $fa-var-earlybirds,
	ebay: $fa-var-ebay,
	edge: $fa-var-edge,
	edge-legacy: $fa-var-edge-legacy,
	elementor: $fa-var-elementor,
	ello: $fa-var-ello,
	ember: $fa-var-ember,
	empire: $fa-var-empire,
	envira: $fa-var-envira,
	erlang: $fa-var-erlang,
	ethereum: $fa-var-ethereum,
	etsy: $fa-var-etsy,
	evernote: $fa-var-evernote,
	expeditedssl: $fa-var-expeditedssl,
	facebook: $fa-var-facebook,
	facebook-f: $fa-var-facebook-f,
	facebook-messenger: $fa-var-facebook-messenger,
	facebook-square: $fa-var-facebook-square,
	fantasy-flight-games: $fa-var-fantasy-flight-games,
	fedex: $fa-var-fedex,
	fedora: $fa-var-fedora,
	figma: $fa-var-figma,
	firefox: $fa-var-firefox,
	firefox-browser: $fa-var-firefox-browser,
	first-order: $fa-var-first-order,
	first-order-alt: $fa-var-first-order-alt,
	firstdraft: $fa-var-firstdraft,
	flickr: $fa-var-flickr,
	flipboard: $fa-var-flipboard,
	fly: $fa-var-fly,
	font-awesome: $fa-var-font-awesome,
	font-awesome-logo-full: $fa-var-font-awesome-logo-full,
	font-awesome-flag: $fa-var-font-awesome-flag,
	fonticons: $fa-var-fonticons,
	fonticons-fi: $fa-var-fonticons-fi,
	fort-awesome: $fa-var-fort-awesome,
	fort-awesome-alt: $fa-var-fort-awesome-alt,
	forumbee: $fa-var-forumbee,
	foursquare: $fa-var-foursquare,
	free-code-camp: $fa-var-free-code-camp,
	freebsd: $fa-var-freebsd,
	fulcrum: $fa-var-fulcrum,
	galactic-republic: $fa-var-galactic-republic,
	galactic-senate: $fa-var-galactic-senate,
	get-pocket: $fa-var-get-pocket,
	gg: $fa-var-gg,
	gg-circle: $fa-var-gg-circle,
	git: $fa-var-git,
	git-alt: $fa-var-git-alt,
	git-square: $fa-var-git-square,
	github: $fa-var-github,
	github-alt: $fa-var-github-alt,
	github-square: $fa-var-github-square,
	gitkraken: $fa-var-gitkraken,
	gitlab: $fa-var-gitlab,
	gitter: $fa-var-gitter,
	glide: $fa-var-glide,
	glide-g: $fa-var-glide-g,
	gofore: $fa-var-gofore,
	goodreads: $fa-var-goodreads,
	goodreads-g: $fa-var-goodreads-g,
	google: $fa-var-google,
	google-drive: $fa-var-google-drive,
	google-pay: $fa-var-google-pay,
	google-play: $fa-var-google-play,
	google-plus: $fa-var-google-plus,
	google-plus-g: $fa-var-google-plus-g,
	google-plus-square: $fa-var-google-plus-square,
	google-wallet: $fa-var-google-wallet,
	gratipay: $fa-var-gratipay,
	grav: $fa-var-grav,
	gripfire: $fa-var-gripfire,
	grunt: $fa-var-grunt,
	guilded: $fa-var-guilded,
	gulp: $fa-var-gulp,
	hacker-news: $fa-var-hacker-news,
	hacker-news-square: $fa-var-hacker-news-square,
	hackerrank: $fa-var-hackerrank,
	hips: $fa-var-hips,
	hire-a-helper: $fa-var-hire-a-helper,
	hive: $fa-var-hive,
	hooli: $fa-var-hooli,
	hornbill: $fa-var-hornbill,
	hotjar: $fa-var-hotjar,
	houzz: $fa-var-houzz,
	html5: $fa-var-html5,
	hubspot: $fa-var-hubspot,
	ideal: $fa-var-ideal,
	imdb: $fa-var-imdb,
	innosoft: $fa-var-innosoft,
	instagram: $fa-var-instagram,
	instagram-square: $fa-var-instagram-square,
	instalod: $fa-var-instalod,
	intercom: $fa-var-intercom,
	internet-explorer: $fa-var-internet-explorer,
	invision: $fa-var-invision,
	ioxhost: $fa-var-ioxhost,
	itch-io: $fa-var-itch-io,
	itunes: $fa-var-itunes,
	itunes-note: $fa-var-itunes-note,
	java: $fa-var-java,
	jedi-order: $fa-var-jedi-order,
	jenkins: $fa-var-jenkins,
	jira: $fa-var-jira,
	joget: $fa-var-joget,
	joomla: $fa-var-joomla,
	js: $fa-var-js,
	js-square: $fa-var-js-square,
	jsfiddle: $fa-var-jsfiddle,
	kaggle: $fa-var-kaggle,
	keybase: $fa-var-keybase,
	keycdn: $fa-var-keycdn,
	kickstarter: $fa-var-kickstarter,
	kickstarter-k: $fa-var-kickstarter-k,
	korvue: $fa-var-korvue,
	laravel: $fa-var-laravel,
	lastfm: $fa-var-lastfm,
	lastfm-square: $fa-var-lastfm-square,
	leanpub: $fa-var-leanpub,
	less: $fa-var-less,
	line: $fa-var-line,
	linkedin: $fa-var-linkedin,
	linkedin-in: $fa-var-linkedin-in,
	linode: $fa-var-linode,
	linux: $fa-var-linux,
	lyft: $fa-var-lyft,
	magento: $fa-var-magento,
	mailchimp: $fa-var-mailchimp,
	mandalorian: $fa-var-mandalorian,
	markdown: $fa-var-markdown,
	mastodon: $fa-var-mastodon,
	maxcdn: $fa-var-maxcdn,
	mdb: $fa-var-mdb,
	medapps: $fa-var-medapps,
	medium: $fa-var-medium,
	medium-m: $fa-var-medium-m,
	medrt: $fa-var-medrt,
	meetup: $fa-var-meetup,
	megaport: $fa-var-megaport,
	mendeley: $fa-var-mendeley,
	microblog: $fa-var-microblog,
	microsoft: $fa-var-microsoft,
	mix: $fa-var-mix,
	mixcloud: $fa-var-mixcloud,
	mixer: $fa-var-mixer,
	mizuni: $fa-var-mizuni,
	modx: $fa-var-modx,
	monero: $fa-var-monero,
	napster: $fa-var-napster,
	neos: $fa-var-neos,
	nimblr: $fa-var-nimblr,
	node: $fa-var-node,
	node-js: $fa-var-node-js,
	npm: $fa-var-npm,
	ns8: $fa-var-ns8,
	nutritionix: $fa-var-nutritionix,
	octopus-deploy: $fa-var-octopus-deploy,
	odnoklassniki: $fa-var-odnoklassniki,
	odnoklassniki-square: $fa-var-odnoklassniki-square,
	old-republic: $fa-var-old-republic,
	opencart: $fa-var-opencart,
	openid: $fa-var-openid,
	opera: $fa-var-opera,
	optin-monster: $fa-var-optin-monster,
	orcid: $fa-var-orcid,
	osi: $fa-var-osi,
	page4: $fa-var-page4,
	pagelines: $fa-var-pagelines,
	palfed: $fa-var-palfed,
	patreon: $fa-var-patreon,
	paypal: $fa-var-paypal,
	penny-arcade: $fa-var-penny-arcade,
	perbyte: $fa-var-perbyte,
	periscope: $fa-var-periscope,
	phabricator: $fa-var-phabricator,
	phoenix-framework: $fa-var-phoenix-framework,
	phoenix-squadron: $fa-var-phoenix-squadron,
	php: $fa-var-php,
	pied-piper: $fa-var-pied-piper,
	pied-piper-alt: $fa-var-pied-piper-alt,
	pied-piper-hat: $fa-var-pied-piper-hat,
	pied-piper-pp: $fa-var-pied-piper-pp,
	pied-piper-square: $fa-var-pied-piper-square,
	pinterest: $fa-var-pinterest,
	pinterest-p: $fa-var-pinterest-p,
	pinterest-square: $fa-var-pinterest-square,
	playstation: $fa-var-playstation,
	product-hunt: $fa-var-product-hunt,
	pushed: $fa-var-pushed,
	python: $fa-var-python,
	qq: $fa-var-qq,
	quinscape: $fa-var-quinscape,
	quora: $fa-var-quora,
	r-project: $fa-var-r-project,
	raspberry-pi: $fa-var-raspberry-pi,
	ravelry: $fa-var-ravelry,
	react: $fa-var-react,
	reacteurope: $fa-var-reacteurope,
	readme: $fa-var-readme,
	rebel: $fa-var-rebel,
	red-river: $fa-var-red-river,
	reddit: $fa-var-reddit,
	reddit-alien: $fa-var-reddit-alien,
	reddit-square: $fa-var-reddit-square,
	redhat: $fa-var-redhat,
	renren: $fa-var-renren,
	replyd: $fa-var-replyd,
	researchgate: $fa-var-researchgate,
	resolving: $fa-var-resolving,
	rev: $fa-var-rev,
	rocketchat: $fa-var-rocketchat,
	rockrms: $fa-var-rockrms,
	rust: $fa-var-rust,
	safari: $fa-var-safari,
	salesforce: $fa-var-salesforce,
	sass: $fa-var-sass,
	schlix: $fa-var-schlix,
	scribd: $fa-var-scribd,
	searchengin: $fa-var-searchengin,
	sellcast: $fa-var-sellcast,
	sellsy: $fa-var-sellsy,
	servicestack: $fa-var-servicestack,
	shirtsinbulk: $fa-var-shirtsinbulk,
	shopify: $fa-var-shopify,
	shopware: $fa-var-shopware,
	simplybuilt: $fa-var-simplybuilt,
	sistrix: $fa-var-sistrix,
	sith: $fa-var-sith,
	sketch: $fa-var-sketch,
	skyatlas: $fa-var-skyatlas,
	skype: $fa-var-skype,
	slack: $fa-var-slack,
	slack-hash: $fa-var-slack-hash,
	slideshare: $fa-var-slideshare,
	snapchat: $fa-var-snapchat,
	snapchat-ghost: $fa-var-snapchat-ghost,
	snapchat-square: $fa-var-snapchat-square,
	soundcloud: $fa-var-soundcloud,
	sourcetree: $fa-var-sourcetree,
	speakap: $fa-var-speakap,
	speaker-deck: $fa-var-speaker-deck,
	spotify: $fa-var-spotify,
	square-font-awesome: $fa-var-square-font-awesome,
	square-font-awesome-stroke: $fa-var-square-font-awesome-stroke,
	font-awesome-alt: $fa-var-font-awesome-alt,
	squarespace: $fa-var-squarespace,
	stack-exchange: $fa-var-stack-exchange,
	stack-overflow: $fa-var-stack-overflow,
	stackpath: $fa-var-stackpath,
	staylinked: $fa-var-staylinked,
	steam: $fa-var-steam,
	steam-square: $fa-var-steam-square,
	steam-symbol: $fa-var-steam-symbol,
	sticker-mule: $fa-var-sticker-mule,
	strava: $fa-var-strava,
	stripe: $fa-var-stripe,
	stripe-s: $fa-var-stripe-s,
	studiovinari: $fa-var-studiovinari,
	stumbleupon: $fa-var-stumbleupon,
	stumbleupon-circle: $fa-var-stumbleupon-circle,
	superpowers: $fa-var-superpowers,
	supple: $fa-var-supple,
	suse: $fa-var-suse,
	swift: $fa-var-swift,
	symfony: $fa-var-symfony,
	teamspeak: $fa-var-teamspeak,
	telegram: $fa-var-telegram,
	telegram-plane: $fa-var-telegram-plane,
	tencent-weibo: $fa-var-tencent-weibo,
	the-red-yeti: $fa-var-the-red-yeti,
	themeco: $fa-var-themeco,
	themeisle: $fa-var-themeisle,
	think-peaks: $fa-var-think-peaks,
	tiktok: $fa-var-tiktok,
	trade-federation: $fa-var-trade-federation,
	trello: $fa-var-trello,
	tripadvisor: $fa-var-tripadvisor,
	tumblr: $fa-var-tumblr,
	tumblr-square: $fa-var-tumblr-square,
	twitch: $fa-var-twitch,
	twitter: $fa-var-twitter,
	twitter-square: $fa-var-twitter-square,
	typo3: $fa-var-typo3,
	uber: $fa-var-uber,
	ubuntu: $fa-var-ubuntu,
	uikit: $fa-var-uikit,
	umbraco: $fa-var-umbraco,
	uncharted: $fa-var-uncharted,
	uniregistry: $fa-var-uniregistry,
	unity: $fa-var-unity,
	unsplash: $fa-var-unsplash,
	untappd: $fa-var-untappd,
	ups: $fa-var-ups,
	usb: $fa-var-usb,
	usps: $fa-var-usps,
	ussunnah: $fa-var-ussunnah,
	vaadin: $fa-var-vaadin,
	viacoin: $fa-var-viacoin,
	viadeo: $fa-var-viadeo,
	viadeo-square: $fa-var-viadeo-square,
	viber: $fa-var-viber,
	vimeo: $fa-var-vimeo,
	vimeo-square: $fa-var-vimeo-square,
	vimeo-v: $fa-var-vimeo-v,
	vine: $fa-var-vine,
	vk: $fa-var-vk,
	vnv: $fa-var-vnv,
	vuejs: $fa-var-vuejs,
	watchman-monitoring: $fa-var-watchman-monitoring,
	waze: $fa-var-waze,
	weebly: $fa-var-weebly,
	weibo: $fa-var-weibo,
	weixin: $fa-var-weixin,
	whatsapp: $fa-var-whatsapp,
	whatsapp-square: $fa-var-whatsapp-square,
	whmcs: $fa-var-whmcs,
	wikipedia-w: $fa-var-wikipedia-w,
	windows: $fa-var-windows,
	wix: $fa-var-wix,
	wizards-of-the-coast: $fa-var-wizards-of-the-coast,
	wodu: $fa-var-wodu,
	wolf-pack-battalion: $fa-var-wolf-pack-battalion,
	wordpress: $fa-var-wordpress,
	wordpress-simple: $fa-var-wordpress-simple,
	wpbeginner: $fa-var-wpbeginner,
	wpexplorer: $fa-var-wpexplorer,
	wpforms: $fa-var-wpforms,
	wpressr: $fa-var-wpressr,
	xbox: $fa-var-xbox,
	xing: $fa-var-xing,
	xing-square: $fa-var-xing-square,
	y-combinator: $fa-var-y-combinator,
	yahoo: $fa-var-yahoo,
	yammer: $fa-var-yammer,
	yandex: $fa-var-yandex,
	yandex-international: $fa-var-yandex-international,
	yarn: $fa-var-yarn,
	yelp: $fa-var-yelp,
	yoast: $fa-var-yoast,
	youtube: $fa-var-youtube,
	youtube-square: $fa-var-youtube-square,
	zhihu: $fa-var-zhihu,
);


/*!
 * Font Awesome Pro 6.0.0-alpha2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@import 'variables';

@font-face {
	font-family: 'Font Awesome 6 Pro';
	font-weight: 900;
	font-style: normal;
	src: url('#{$fa-font-path}/fa-solid-900.woff2') format('woff2'), url('#{$fa-font-path}/fa-solid-900.woff') format('woff'), url('#{$fa-font-path}/fa-solid-900.ttf') format('truetype');
	font-display: $fa-font-display;
}

.fas,
.fa-solid {
	font-family: 'Font Awesome 6 Pro';
	font-weight: 900;
}

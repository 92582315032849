.eyecatcher {
	.eyecatcher-container {
		padding: 0;
		width: auto;
		max-width: none;
		max-height: fit-content;

		.owl-carousel {
			.item {
				width: 100%;
				height: 280px;
				background-size: cover;
			}

			&.large {
				.item {
					height: 40vh;
				}
			}

			.owl-nav {
				position: absolute;
				width: 100%;
				display: flex;
				justify-content: space-between;
				padding: 0 3rem 0 3rem;
				top: 45%;

				.owl-prev i,
				.owl-next i {
					color: $white;
					font-size: 32px;
				}
			}

			.owl-dots {
				transform: translateY(-100%);
				z-index: 900;
				display: flex;
				gap: 0.25rem;
				justify-content: center;
				align-items: center;
				padding: 1rem;

				.owl-dot {
					background: none;
					width: 1rem;
					aspect-ratio: 1/1;
					border-radius: 50%;
					border: 1px solid $white;

					&.active {
						background-color: $white;
					}
				}
			}
		}
	}
}

@media(max-width: 767px) {
	// .eyecatcher-owl-carousel {
	// 	margin-top: 130px;
	// }
	.eyecatcher .eyecatcher-container .owl-carousel.large .item {
		height: 50vh;
	}
}

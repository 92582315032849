// .card {

// 	padding-bottom: 80px;
// 	width: 100%;
// 	height: 100%;
// 	font-size: 1.125rem;
// 	text-align: left;
// 	color: $gray-900;
// 	border: none;
// 	border-radius: 0;
// 	box-shadow: 0 6px 12px 0 rgba($black, 0.16);

// 	a {
// 		&:hover {
// 			text-decoration: none;
// 		}
// 	}

// 	.card-img {
// 		border-radius: 0;
// 	}

// 	.card-body {
// 		padding: 32px 30px 13px;
// 		line-height: 1.45;

// 		.card-title {
// 			margin-bottom: 17px;
// 			font-size: 1.625rem;
// 			font-weight: 400;
// 			color: $orange;
// 		}

// 		.card-btn {
// 			padding: 5px 10px;
// 			position: absolute;
// 			bottom: 35px;
// 			left: 50%;
// 			display: inline-flex;
// 			align-items: center;
// 			justify-content: center;
// 			min-width: 178px;
// 			min-height: 38px;
// 			color: $white;
// 			border-radius: 20px;
// 			background-color: $primary;
// 			transform: translateX(-50%);

// 			&:hover {
// 				background-color: $dark-blue;
// 			}
// 		}
// 	}
// }

.card {
	border: 0;
	box-shadow: none;
	background: none !important;
	border-radius: 28px;
	box-shadow: 0px 0px 15px rgba(71, 71, 71, 0.25);
	margin: 0.5rem;

	.card-image,
	.card-image img {
		border-radius: 28px;
	}

	.card-image {
		.card-image-caption {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: auto;

			.card-image-label {
				padding: 5px 15px;
				border-top-color: $orange;
				background: $orange;
				font-size: 1rem;
				font-weight: 700;
				line-height: 1.5;
				width: max-content;
				position: static;
				margin-top: -15px;
				margin-left: 15px;
				color: $white;

				&::after {
					content: "";
					position: absolute;
					top: 18px;
					left: 15px;
					width: 0;
					height: 0;
					border-top-color: inherit;
					border-top-style: solid;
					border-top-width: 20px;
					border-left: solid 0 transparent;
					border-right: solid 20px transparent;
				}
			}
		}
	}

	.card-caption {
		text-align: center;

		a.card-title-link {
			&:hover {
				text-decoration: none !important;
			}
		}

		.card-title {
			font-size: 38px !important;
			font-family: $font-family-secondary;
			font-weight: 500;
			color: $orange;
			line-height: 36px;
		}

		.card-subtitle {
			font-size: 26px;
			font-weight: 400;
			margin-bottom: 25px;
		}

		.card-prices {
			.card-price-value {
				color: $secondary;
				font-weight: 700;
				font-size: 1.5rem;

				&.forsale {
					font-weight: 400;
					text-decoration: line-through;
					color: $primary;
					opacity: 0.6;
					font-size: 1rem;
				}
			}

			.card-price-message {
				color: $secondary;
			}
		}
	}
	.card-buttons {
		margin-top: 2rem;
		display: flex;
		justify-content: center;
		.card-btn {
			@extend .btn, .btn-primary;
		}
	}
}

.mini_filter.mini-filter {

    .col-lg-auto {
        @include media-breakpoint-up(lg) {
            flex: 0 0 calc( 100% / 5 );
            max-width: calc( 100% / 5 );
        }
    }


    .submit_button {
        .btn {
            border-radius: 0.25rem !important;
            i {
                margin-left: 10px;
            }
            &:hover {
                color: $white;
            }
        }
    }
}
.header {
	margin-bottom: 0;
	background-color: $primary !important;
	box-shadow: none !important;
	// position: absolute;
	// top: 0;
	z-index: 1000;
	width: 100%;
	transition: 0.45s ease-in;

	.navbar {
		padding: 0 1rem;
		@include media-breakpoint-up(lg) {
			padding: 18px 1rem 12px;
		}
	}

	// @include media-breakpoint-down(lg) {
	// 	background: $primary;
	// }

	.logo {
		z-index: 100;
		margin: 20px auto -15px 0;

		a {
			display: block;
		}

		img {
			border-radius: 0;
			max-width: 224px;
			@include media-breakpoint-down(lg) {
				max-width: 190px;
			}
		}
	}

	.menu_toggle {
		position: relative;
		z-index: 15;
		display: block;
		cursor: pointer;
		@include media-breakpoint-up(lg) {
			display: none;
		}

		span {
			position: absolute;
			top: 50%;
			right: 0;
			display: block;
			width: 21px;
			height: 3px;
			text-indent: -9999px;
			border-radius: 3px;
			background: none;
			transition: all 0.3s;

			&:before,
			&:after {
				position: absolute;
				display: block;
				width: 100%;
				height: 3px;
				border-radius: 3px;
				background: $primary;
				transition: all 0.3s;
				content: "";
			}

			&:before {
				top: 0;
				width: 100%;
				transform: rotate(135deg);
			}

			&:after {
				bottom: 0;
				width: 100%;
				transform: rotate(-135deg);
			}
		}

		&.collapsed {
			span {
				background: $primary;

				&:before {
					top: -8px;
					left: 0;
					transform: rotate(0deg);
				}

				&:after {
					right: 0;
					bottom: -8px;
					transform: rotate(0deg);
				}
			}
		}
	}

	.menu {
		padding: 24px 0 24px 30px !important;
		position: absolute;
		top: 65px;
		left: -15px;
		width: calc(100% + 30px);
		transition: all 0.3s ease-in-out;
		box-shadow: 0 6px 6px 0 rgba($black, 0.16);
		background: $primary !important;
		@include media-breakpoint-up(xl) {
			padding: 0;
			position: relative;
			top: unset;
			left: unset;
			display: flex;
			flex-basis: unset;
			flex-wrap: wrap;
			justify-content: flex-end;
			width: auto;
			background-color: transparent !important;
			box-shadow: none;
		}
	}

	.navbar-nav {
		padding: 19px 1rem auto 1rem;
		@include media-breakpoint-up(xl) {
			padding-top: 0;
			display: flex;
			flex-direction: unset;
			flex-wrap: wrap;
		}
		margin: {
			left: 0;
		}

		li {
			width: 100%;
			margin-bottom: 0;
			font-size: 1.125rem;
			font-weight: 700;
			list-style: none;
			@include media-breakpoint-up(xl) {
				&:not(:last-child) {
					margin-right: 16px;
				}
				width: auto;
			}

			a {
				&:hover {
					color: $white;
				}
			}

			&.active {
				a {
					color: $orange;
				}
			}
		}
	}

	.site_switcher {
		margin-left: 1rem;
		.language {
			display: flex;
			flex-direction: row;
			padding-top: 0;

			.nav-item {
				width: 24px;
				height: 24px;
				margin: 0;

				+ li {
					margin-left: 5px;
				}

				overflow: hidden;
				border-radius: 100%;
				border: 1px solid $white;

				.nav-link {
					padding: 0;

					img {
						width: 22px;
						height: 22px;
					}

					&:hover {
						opacity: 0.65;
					}
				}
			}
		}
	}


	.container-fluid {
		max-width: none;
	}
}

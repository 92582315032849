// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;

	&:before,
	&:after {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
}

html,
body {
	background-color: $body-bg;
}

.clear {
	@include clearfix;
}

a {
	transition: all 0.3s ease-in-out;
}

img {
	max-width: 100%;
}

.container-fluid {
	max-width: 1366px;
}

p {
	&:empty {
		display: none;
	}
}

.container-two-columns {
	.container-holder {
		display: flex;
		flex-wrap: wrap;

		.column {
			margin-bottom: 30px;
			width: 100%;
			@include media-breakpoint-up(md) {
				margin: 0;
				width: 50%;
				padding: {
					right: 10px;
					left: 10px;
				}
			}
		}
	}
}

ul {
	list-style: disc;
	margin: {
		left: 38px;
	}

	li {
		position: relative;
		line-height: 1.2;
		list-style: disc;
		margin: {
			bottom: 19px;
		}
	}
}

.check-list {
	margin: 0;
	list-style: none;

	li {
		position: relative;
		line-height: 1.2;
		list-style: none;
		padding: {
			left: 20px;
		}
		margin: {
			bottom: 19px;
		}

		&:before {
			position: absolute;
			top: 2px;
			left: 0;
			font-family: $font-family-icon;
			font-size: 1rem;
			font-weight: 700;
			color: $orange;
			content: "\f00c";
		}
	}
}

.add-arrow {
	&--before {
		&::before {
			margin-right: 10px;

			font-family: "Font Awesome 5 Pro";
			font-weight: $font-weight-bold;
			content: "\f061";
		}

		&-r {
			&::before {
				content: "\f060";
			}
		}
	}

	&--after {
		&::after {
			margin-left: 10px;

			font-family: "Font Awesome 5 Pro";
			font-weight: $font-weight-bold;
			content: "\f061";
		}

		&-r {
			&::after {
				content: "\f060";
			}
		}
	}

	&--none {
		&::before,
		&::after {
			@extend .d-none;
		}
	}
}

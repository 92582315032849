.contact {
	.main {
		.content {
			.container-default {
				.container-holder {
					max-width: 800px;
					margin: auto;
				}
			}
			.container-two-columns {
				margin-top: 40px;
				.container-holder {
					font-size: $font-size-base;
					.column {
						@include media-breakpoint-up(md) {
							padding: {
								right: 30px;
								left: 30px;
							}
						}
					}
				}
			}
		}
	}
}
.content {
	// padding: {
	// 	top: 61px;
	// 	bottom: 200px;
	// }

	&.intro {
		padding-top: 57px;
		padding-bottom: 0;
		position: relative;
		width: 100%;
		background-color: $primary;

		&:before {
			position: absolute;
			top: 69%;
			bottom: 0;
			left: 0;
			display: block;
			width: 100%;
			background: $light-yellow;
			content: "";
		}

		&:after {
			position: absolute;
			top: calc(69% - 194px);
			display: block;
			width: 100%;
			height: 194px;
			content: "";
			@include background-image(shape, svg, center center, repeat-x);
		}

		.container-holder {
			margin: 0;
			position: relative;
			z-index: 1;
			font-size: 1.25rem;
			font-weight: 400;
			line-height: 1.3;
			text-align: center;
			color: $white;
		}

		h1 {
			margin-bottom: 22px;
			font-weight: 400;
			color: $white;
		}

		p {
			margin-right: auto;
			margin-left: auto;
			max-width: 800px;
		}

		.contentblock {
			margin-top: 109px;
			display: flex;
			flex-wrap: wrap;
			@include media-breakpoint-up(md) {
				justify-content: space-between;
			}
			@include media-breakpoint-up(lg) {
				justify-content: unset;
			}
			margin: {
				right: -8px;
				left: -8px;
			}
		}

		.item {
			margin-bottom: 34px;
			width: 100%;
			@include media-breakpoint-up(md) {
				width: 50%;
			}
			@include media-breakpoint-up(lg) {
				width: 33.33%;
			}
			padding: {
				right: 8px;
				left: 8px;
			}
		}
	}

	&.landingspagina-section {
		position: relative;
		z-index: 1;

		.container-default {
			.container-holder {
				font-size: 1.125rem;
				line-height: 1.4;
				@include media-breakpoint-up(md) {
					font-size: 1.25rem;
				}
				margin: {
					bottom: 45px;
				}

				h1 {
					font-weight: 400;
					margin: {
						bottom: 2px;
					}
				}
				h1,
				p {
					max-width: 720px;
					margin: auto;
				}
				p {
					color: $black;
				}
			}
		}

		.container-two-columns {
			.container-holder {
				margin-bottom: 0;

				h2 {
					font-weight: 400;
					color: $orange;
					margin: {
						bottom: 10px;
					}
				}

				p {
					font-size: $font-size-base;
					margin: {
						bottom: 22px;
					}

					+ h3 {
						@include media-breakpoint-up(md) {
							margin-top: 67px;
						}
					}
				}
				ul {
					li {
						font-size: $font-size-base;
						&::marker {
							font-size: $font-size-base * 1.75;
						}
					}
				}
			}
		}

		.contact_button {
			padding: 5px 10px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			min-width: 178px;
			min-height: 38px;
			font-size: 1.125rem;
			font-weight: 400;
			color: $white;
			border-radius: 20px;
			background-color: $primary;

			&:hover {
				text-decoration: none;
				background-color: $dark-blue;
			}
		}
	}

	.container-holder {
		font-size: 1.125rem;
		line-height: 1.4;
		margin: {
			bottom: 51px;
		}

		h1 {
			font-weight: 400;
			text-align: center;
			margin: {
				bottom: 2px;
			}
		}
	}

	.container-two-columns {
		.container-holder {
			h1 {
				@extend h2;
				font-weight: 400;
				text-align: left;
				color: $orange;
			}

			.column {
				&.two {
					a {
						text-decoration: underline;
					}

					p {
						&:empty {
							+ h3 {
								margin-top: 50px;
							}
						}
					}
				}
			}

			a[href^="mailto:"],
			a[href^="tel:"] {
				font-weight: 700;
			}
		}
	}

	.form {
		border: 1px solid $gray-200;
		@include media-breakpoint-up(md) {
			padding: {
				top: 39px;
				right: 30px;
				bottom: 70px;
				left: 30px;
			}
		}
		padding: {
			top: 30px;
			right: 20px;
			bottom: 30px;
			left: 20px;
		}

		label {
			margin-bottom: 3px;
			font-weight: 700;
		}

		input,
		select,
		textarea {
			border-color: $gray-200;
		}

		textarea {
			height: 96px;
		}

		.form-check {
			padding: {
				left: 0;
			}
			margin: {
				top: 28px;
				bottom: 23px;
			}

			input {
				margin-bottom: 0;
				padding: 0;
				display: none;
				width: initial;
				height: initial;
				cursor: pointer;

				&:checked {
					+ {
						label {
							&:after {
								position: absolute;
								top: 6px;
								left: 6px;
								display: block;
								width: 6px;
								height: 14px;
								border: solid $gray-600;
								border-width: 0 2px 2px 0;
								transform: rotate(45deg);
								content: "";
							}
						}
					}
				}
			}

			label {
				position: relative;
				cursor: pointer;
				font-weight: 400;

				&:before {
					margin-right: 15px;
					position: relative;
					display: inline-block;
					width: 18px;
					height: 18px;
					vertical-align: middle;
					border: 1px solid $gray-600;
					border-radius: 3px;
					background-color: transparent;
					appearance: none;
					content: "";
					box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05);
					cursor: pointer;
				}
			}
		}

		small {
			margin-top: 24px;
			display: block;
			font-size: 0.875rem;
			line-height: 1.4;

			a {
				text-decoration: underline;
			}
		}
	}

	table {
		margin-bottom: 50px;
		border: none;

		tr {
			td {
				border: none;
			}
		}
	}

	h3 {
		font-weight: 700;
	}

	.vocabulary_term_list {
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;

		li {
			margin-right: 20px;
			list-style: none;

			a {
				font-size: 1.563rem;
				color: $primary;

				&:hover {
					color: $dark-blue;
				}
			}
		}
	}

	&.filter {
		.container-two-columns {
			.container-holder {
				.column {
					&.one {
						@include make-col(3);

						@include media-breakpoint-down(sm) {
							flex: none;
							margin: 0;
							padding: 0;
						}
					}
					&.two {
						@include make-col(12);

						@include media-breakpoint-up(md) {
							@include make-col(9);
						}

						a {
							text-decoration: none;
						}
					}
				}
			}
		}

		.sidebar-filter {
			font-size: 14px;
	
			.form-group {
				margin-bottom: 1.5rem;
			}
	
			@include media-breakpoint-down(sm) {
				display: none;
			}
	
			.heading {
				display: none;
				font-size: 20px;
			}
	
			.close-btn {
				display: none;
				position: absolute;
				top: 20px;
				right: 20px;
				font-size: 24px;
				line-height: 1;
			}
	
			&.open {
				display: block;
				position: fixed;
				z-index: 1000;
				top: 0;
				left: 0;
				bottom: 0;
				width: 100%;
				max-width: 360px;
				height: 100%;
				padding: 20px 20px 75px 20px;
				overflow-x: hidden;
				background-color: $white;
	
				.heading,
				.close-btn {
					display: block;
				}
			}
		}
	
		.page_block {
			&.grid {
				justify-content: flex-start;
				@include make-row();
	
				.item {
					@include make-col-ready();
					margin-bottom: 2rem;

					@include media-breakpoint-up(md) {
						@include make-col(6);
					}

					.card {
						@extend .h-100;
					}

				}
			}
		}
	
		.fixed-filter-button {
			@include media-breakpoint-up(md) {
				display: none;
			}
	
			position: fixed;
			z-index: 998;
			left: 0;
			right: 0;
			bottom: 30px;
			text-align: center;
	
			.btn {
				min-width: 230px;
				margin: 0 auto;
				padding: 0.7rem 1rem;
				border-radius: 5px;
				background-color: $primary;
				border-color: $white;
				box-shadow: 0px 3px 6px $box-shadow;
				justify-content: center;
	
				i {
					margin-right: 10px;
				}
			}
		}
	}
}

.service_overview .content {
	position: relative;
	z-index: 1;

	.container-default {
		.container-holder {
			font-size: 1.125rem;
			line-height: 1.4;
			@include media-breakpoint-up(md) {
				font-size: 1.25rem;
			}
			margin: {
				bottom: 45px;
			}

			h1 {
				font-weight: 400;
				margin: {
					bottom: 2px;
				}
			}
			h1,
			p {
				max-width: 720px;
				margin: auto;
			}
			p {
				color: $black;
			}
		}
	}
}

.service_category {
	.lead-section {
		.container-holder {
			justify-content: flex-start;
		}
	}
}

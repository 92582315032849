.footer {
	width: 100%;

	.footer-top {
		position: relative;
		padding: 3vh 0.75rem 3vh 0.75rem;

		.footer_text {
			h2 {
				font-weight: 600;
				@include media-breakpoint-up(md) {
					margin-bottom: 2px;
					font-size: $h1-font-size;
				}
			}

			p {
				max-width: 480px;
				margin: {
					right: auto;
					bottom: 15px;
					left: auto;
				}
			}
		}

		.footer_buttons {
			margin-left: 0;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			align-items: center;

			li {
				margin-bottom: 8px;
				list-style: none;

				a {
					display: inline-flex;
					align-items: center;
					justify-content: center;
					min-height: 38px;
					font-size: 1.125rem;
					font-weight: 400;
					color: $white;
					border-radius: 20px;
					background-color: $primary;
					padding: {
						top: 5px;
						right: 25px;
						bottom: 5px;
						left: 25px;
					}

					i {
						margin-right: 10px;
						font-weight: 600;
					}

					&:hover {
						text-decoration: none;
						background-color: $dark-blue;
					}
				}
			}
		}
	}

	.footer-socket {
		position: relative;

		&::before {
			content: "\f3c5";
			font-family: $font-family-icon;
			position: absolute;
			top: 0;
			left: calc(50%);

			font-size: 36px;
			font-weight: 900;
			display: flex;
			justify-content: center;
			align-items: center;
			transform: translate(-50%, -50%);
			color: #1d6e9a;
			background: #8bb5cd;
			border-radius: 50%;
			width: 3.5rem;
			aspect-ratio: 1/1;
		}

		padding: 3vh 0;
		background: $primary;
		color: $white;

		.navbar {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.footer_text {
			line-height: 1.2;

			p {
				margin: 0;
			}

			h2 {
				margin-bottom: 0;
				font-weight: 600;
			}
		}

		.footer_link {
			font-size: 14px;

			ul {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: center;
				margin: {
					bottom: 0;
					left: 0;
				}

				li {
					list-style: none;
					margin: {
						right: 12px;
						left: 12px;
					}

					a {
						color: $gray-900;
					}
				}
			}
		}

		.social-media {
			list-style: none;
			padding: 0;
			margin: 2rem 0 0;
			display: flex;
			flex-wrap: nowrap !important;
			gap: 1rem;

			li {
				list-style: none;

				a {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 24px;
					height: 24px;
					text-decoration: none !important;
					font-size: 20px;
					color: $white;
	
					&:hover {
						color: $orange;
	
						span {
							opacity: 1;
							visibility: visible;
							bottom: 100%;
						}
					}
	
					span {
						position: absolute;
						background-color: $black;
						color: $white;
						font-size: 0.75rem;
						left: 50%;
						bottom: 10%;
						padding: 4px 10px;
						border-radius: 4px;
						margin-bottom: 0.5rem;
						transform: translateX(-50%);
						opacity: 0;
						visibility: hidden;
						transition: 0.3s;
						pointer-events: none;
	
						&::after {
							content: "";
							position: absolute;
							width: 10px;
							height: 10px;
							background-color: $black;
							top: 100%;
							left: 50%;
							transform: translateX(-50%) translateY(-5px)
								rotateZ(45deg);
						}
					}
				}
			}

		}
	}
}

ul.footer-link {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	margin: 0;
	padding: 3vh 0;

	li {
		list-style: none;

		a {
			@extend .btn, .btn-outline-secondary;

			i {
				&::before {
					margin-right: 0.5rem;
					font-weight: 900;
				}
			}
		}
	}
}

.landing_page,
.contact,
.assortiment_overview,
.service_category,
.service_overview,
.assortiment_detail,
.one_column_page,
.two_column_page,
.three_column_page {
	.footer {
		.footer-top {
			background: #e5dcc8;
			margin-top: 250px;

			&:before {
				position: absolute;
				top: -194px;
				left: 0;
				display: block;
				width: 100%;
				height: 194px;
				content: "";
				background-image: url("/images/footerbomen.svg");
				background-repeat: repeat-x;
				background-position: center center;
			}

			.footer-link {
				li {
					a {
						background: #fff !important;
						color: #1d6e9a !important;
						border: 1px solid #fff !important;

						&:hover {
							color: #fff !important;
							background: #1d6e9a !important;
							border: 1px solid #1d6e9a !important;
						}
					}
				}
			}
		}
	}
}

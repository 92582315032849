.assortiment_detail {
	.lead-section {
        .container-holder {
            .column {
                flex: 1;
            }
        }
		.info {
            max-width: none;
			display: flex;
			justify-content: initial;
			align-content: space-between;
			margin-top: 30px;
            text-align: start !important;
			@include media-breakpoint-up(md) {
				flex-flow: column wrap;
				max-height: 95px;
			}
			@include media-breakpoint-down(sm) {
				flex-flow: row wrap;
				margin-bottom: 50px;
			}

			> * {
				max-width: fit-content;
				margin-bottom: 0;

				@include media-breakpoint-down(sm) {
					flex: 0 0 100%;
					max-width: 100%;
				}
			}

            h1 {
                font-size: calc(1.35rem + 1.2vw);
            }

            h5 {
                font-size: $h5-font-size;
            }
		}

		.price-wrapper {
			display: flex;
			flex-flow: row wrap;

			.price {
				display: flex;
				flex-flow: row wrap;

				> span {
					flex: 0 0 100%;
					max-width: 100%;

					&.prefix {
						margin-right: 5px;
						font-weight: 700;
					}

					&.suffix {
						order: -1;
						line-height: 28px;
					}

					&.amount {
						margin-right: 5px;
						font-size: 1.5rem;
						color: #176898;
						font-weight: 600;
					}
				}
			}

			.former-price {
				@include media-breakpoint-down(xs) {
					display: flex;
					flex-direction: column;
				}
				order: -1;
				text-decoration: line-through;
				opacity: 0.6;

				.prefix {
					margin-right: 5px;
				}

				.amount {
					margin-right: 5px;
				}
			}

			.discount-price {
				flex: 0 0 100%;
				max-width: 100%;
				@include media-breakpoint-down(xs) {
					display: flex;
					flex-direction: column;
				}

				.prefix {
					margin-right: 5px;
					font-weight: 700;
				}

				.amount {
					font-size: 1.5rem;
					color: $secondary;
					font-weight: 600;
					margin-right: 5px;
				}
			}
		}

		.gallery {
			.large-item {
				img {
					border-radius: 6px;
					box-shadow: 8px 8px 0 #b9d1e0;
				}
			}
		}
	}

	.gallery-section {
		margin-bottom: 60px;
	}

	.contact-block {
		a {
			text-decoration: none;
			font-weight: 600;
			color: $white;

			&:hover {
				color: $orange;
			}

			&:before {
				font-family: "Font Awesome 5 Pro";
				font-weight: 100;
				margin-right: 5px;
			}

			&[href*="mailto:"] {
				&:before {
					content: "\f0e0";
				}
			}

			&[href*="tel:"] {
				&:before {
					content: "\f879";
				}
			}

			&.btn {
				transition: 0.3s;

				&:hover {
					color: #ffffff;
					background: #1f86c4;
					border-color: #176898;
				}
			}
		}
	}

	.content-section {
		margin-bottom: 60px !important;
        color: $white;

		.container {
			position: relative;
			z-index: 2;

			@media(min-width: 992px) and (max-width: 1199px) {
				max-width: 960px;
			}
		}
	}

	.section-icons {
		margin-top: 65px;
	}

	.contact-block-mobile {
		margin-top: 35px;
		@media(min-width: 992px) {
			display: none;
		}

        p {
            color: $primary;
        }

        .btn-secondary {
            border: 1px solid $primary !important;
        }
	}

	.contact-block.desktop {
		@media(max-width: 991px) {
			display: none;
		}
	}
}

.contact-block {
	border: 1px solid $white;
	border-radius: 6px;
	box-shadow: 6px 6px 12px rgba($black, 0.29);
	padding: 40px 60px 70px;

	h2 {
		margin-bottom: 15px;
	}

	p {
		font-family: "Dancing Script";
		font-size: 1.75rem;
		color: $white;
		line-height: 28px;
		margin-bottom: 25px;

		a {
			display: inline-block;
			font-size: 1rem;
			font-family: "Noto Sans", sans-serif;

			&:first-of-type {
				margin-top: 33px;
			}
		}
	}

	a.btn.btn-secondary {
		box-shadow: 0px 3px 6px rgba($black, 0.29);
		border: 1px solid $white;
		border-radius: 4px;
		color: $primary;
		background: $white;
	}
}

.gallery-horizontal {
	display: flex;
	flex-flow: row wrap;
	border-radius: 7px;
	justify-content: space-between;
	position: relative;

	&:after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background: #b9d1e0;
		transform: translate(15px, 15px);
		background-size: 100%;
		background-repeat: no-repeat;
		border-radius: 7px;
	}

	.gallery-label {
		position: absolute;
		font-family: "Noto Sans", sans-serif;
		color: #fff;
		font-weight: 600;
		padding: 6px 18px;
		transform: translateY(-50%);
		z-index: 2;
		margin-left: 30px;
		border-top-color: $orange;
		background: $orange;

		&::after {
			content: "";
			position: absolute;
			top: 35px;
			left: 0;
			width: 0;
			height: 0;
			border-top-color: inherit;
			border-top-style: solid;
			border-top-width: 20px;
			border-left: solid 0 transparent;
			border-right: solid 20px transparent;
		}
	}

	.gallery-item {
		img {
			border-radius: 7px;
		}
	}

	.gallery-item,
	.gallery-thumbs {
		a {
			display: Block;

			img {
				max-width: 100%;
			}
		}
	}

	.gallery-thumbs,
	.large-item {
		flex: 0 0 calc(50%);
		max-width: calc(50%);
		z-index: 1;
		@include media-breakpoint-down(sm) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.large-item {
		border-radius: 7px 7px 0 7px;
		@include media-breakpoint-down(sm) {
			margin-bottom: 15px;
		}
	}

	.gallery-thumbs {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-gap: 10px;
		padding-left: 10px;
		position: relative;
		@include media-breakpoint-down(sm) {
			padding-left: 0;
		}

		.gallery-item {
			a {
				display: block;

				.gallery-caption {
					display: none;
				}
			}

			&:nth-of-type(3),
			&:nth-of-type(4) {
				align-items: flex-end;
			}
		}

		.gallery-hidden {
			display: none;
		}

		.gallery-popup-button {
			font-size: 0.875rem;
			position: absolute;
			right: 15px;
			bottom: 15px;
			background: #fff;
			color: #176898;
			border: 1px solid #176898;
			font-weight: 600;
			padding: 11px 21px;
			cursor: pointer;
			pointer-events: none;
		}
	}
}

.downloads {
	ul {
		list-style: none;
		display: inline-block;
		padding-left: 0;
		margin-bottom: 0;

		li {
			.file {
				a {
					display: flex;
					border: 1px solid #176898;
					font-weight: 600;
					border-radius: 4px;
					padding: 8px 39px;
					text-decoration: none;
					transition: 0.3s;

					i {
						margin-right: 5px;
					}

					&:hover {
						color: #ffffff;
						background: #1f86c4;
						border-color: #176898;
					}
				}
			}
		}
	}
}

.assortiment-extra-content {
	margin-bottom: 6vh;

	.container-three-columns {
		.container-holder {
			background: #ededf4;
			margin: 0;
			padding: 45px 15px;
		}
	}

	// ul {
	// 	@extend .custom-list;
	// }
}

.assortiment-characteristics {

	// .container-two-columns {
	// 	background: #ededf4;

	// 	.container-holder {
	// 		padding: 62px 34px;
	// 	}

	// 	.column {
	// 		flex: 0 0 33.333%;
	// 		max-width: 33.333%;

	// 		&:nth-of-type(1n + 2) {
	// 			flex: 0 0 66.333%;
	// 			max-width: 66.333%;
	// 			@include media-breakpoint-down(sm) {
	// 				flex: 0 0 100%;
	// 				max-width: 100%;
	// 			}
	// 		}

	// 		h2 {
	// 			color: #176898;
	// 			margin-bottom: 35px;
	// 		}

	// 		.characteristics {
	// 			ul {
	// 				padding-left: 0;

	// 				li {
	// 					display: flex;
	// 					flex-flow: row wrap;
	// 					justify-content: space-between;
    //                     margin-bottom: 0;

	// 					> div {
	// 						flex: 0 0 calc(50% - 23px);
	// 						max-width: calc(50% - 23px);
	// 						line-height: 36px;

	// 						&.label {
	// 							color: #2a2c24;
	// 						}

	// 						&.characteristic_terms {
	// 							color: #176898;
	// 							font-weight: 600;
	// 						}
	// 					}

	// 					&:not(:last-of-type) {
	// 						> div {
	// 							border-bottom: 1px solid grey;
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	.container-one-column {
		background: #ededf4;

		.container-holder {
			padding: 34px;

			.column {
				flex: 1;
			}
		}

	}

	h2 {
		color: #176898;
		margin-bottom: 20px;
	}

	.characteristics {
		ul {
			list-style: none;
			padding-left: 0;
			margin: 0;

			li {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				margin-bottom: 0;

				> div {
					flex: 0 0 calc(50% - 23px);
					max-width: calc(50% - 23px);
					line-height: 36px;

					&.label {
						color: #2a2c24;
					}

					&.characteristic_terms {
						color: #176898;
						font-weight: 600;
					}
				}

				&:not(:last-of-type) {
					> div {
						border-bottom: 1px solid grey;
					}
				}
			}
		}
	}
}

.section-icons {
	margin-top: 60px;
	padding-bottom: 80px;

	.container {
		.container-holder {
			text-align: center;

			h1 {
				color: $body-color;
				font-size: $h2-font-size;
			}

			h2 {
				color: $primary;
				font-weight: 400;
				margin-bottom: $grid-gutter-width / 2;
				text-transform: none;
			}

			.home_block_icons {
				.item {
					@include media-breakpoint-down(sm) {
						@include make-col(6);
					}

					.card {
						border-radius: 6px;
						border: none;
						text-decoration: none;
						cursor: pointer;

						&:hover {
							box-shadow: 0 3px 6px rgba($black, 0.15);
						}

						&-image,
						&-body {
							background: transparent;
						}

						&-image {
							font-size: 0;
							padding: 20px 0 0 0;
						}

						&-body {
							margin-top: auto;
							flex: 0 0 auto;
							padding-top: 15px;

							.card-caption {
								.card-title {
									font-size: $font-size-base;
									font-weight: $font-weight-base;
									text-align: center;
								}
							}
						}
					}
				}
			}
		}
	}
}

.related-products-section.offer {
	padding-bottom: 130px;

	h2 {
		margin-bottom: 70px;
	}

	> .container {
		z-index: 2;
		position: relative;
	}

	.assortiment_related_properties {
		.item {
			flex: 0 0 33.333%;
			max-width: 33%;
			@include media-breakpoint-down(sm) {
				@include make-col(12);
			}
		}
	}
}

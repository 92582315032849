.gallery {
  @include make-row();
  margin: $gallery-margin;

  @include media-breakpoint-up(sm) {
    margin: $gallery-margin_sm;
  }

  .gallery-item {
    @include make-col-ready();
    @include make-col(6);
    margin: $gallery-item-margin;
    padding: $gallery-item-padding;

    transition: $transition-base;

    &:hover {
      transform: scale(1.025);
    }

    @include media-breakpoint-up(sm) {
      margin: $gallery-item-margin_sm;
      padding: $gallery-item-padding_sm;
    }
    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }

    .gallery-link {
      position: relative;
      @extend .d-block;
    }

    .gallery-img {
      @extend .img-fluid;
      @extend .w-100;
      border-radius: 16px;
    }

    .gallery-caption {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      border-radius: 16px;

      @extend .align-items-center;
      @extend .justify-content-center;

      background: $gallery-caption-background;
      font-size: $gallery-caption-font_size;
      color: $gallery-caption-color;

      &::before {
        content: '+';
      }

      @include media-breakpoint-up(sm) {
        font-size: $gallery-caption-font_size_sm;
      }
    }

    &.large-item {
      @include make-col(12);

      .gallery-img {
        border-radius: 28px;
      }
  
    }

    &.first-item {
      .gallery-caption {
        @extend .d-none;
      }
    }

    &.before-last-item {
      .gallery-caption {
        @extend .d-flex;
        @extend .d-lg-none;
      }
    }

    &.last-item {
      @extend .d-none;
      @extend .d-lg-flex;

      .gallery-caption {
        @extend .d-none;
        @extend .d-lg-flex;
      }
    }
  }

  .gallery-hidden {
    @extend .d-none;
  }
}
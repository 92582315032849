// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.btn {
	padding: 5px 10px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 178px;
	min-height: 38px;
	font-size: 1.125rem;
	font-weight: 400;
	color: $white;
	border-radius: 20px;
	background-color: $primary;

	&:hover {
		text-decoration: none;
		background-color: $dark-blue;
	}

	&.btn-secondary {
		color: $primary;
		border: 1px solid $primary;
		background-color: $white;

		&:hover {
			color: $secondary;
			border-color: $dark-blue;
		}
	}
}

.btn-back {
	@extend .mb-3;
	@extend .add-arrow--before;
	@extend .add-arrow--before-r;
	transition: $transition-base;
	text-decoration: none !important;
}

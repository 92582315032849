/*!
 * Font Awesome Pro 6.0.0-alpha2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@import 'variables';

@font-face {
	font-family: 'Font Awesome 6 Brands';
	font-weight: 400;
	font-style: normal;
	src: url('#{$fa-font-path}/fa-brands-400.woff2') format('woff2'), url('#{$fa-font-path}/fa-brands-400.woff') format('woff'), url('#{$fa-font-path}/fa-brands-400.ttf') format('truetype');
	font-display: $fa-font-display;
}

.fab,
.fa-brands {
	font-family: 'Font Awesome 6 Brands';
	font-weight: 400;
}

@each $name, $icon in $fa-brand-icons {
	.#{$fa-css-prefix}-#{$name}:before {
		content: fa-content($icon);
	}
}

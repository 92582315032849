// bordered + pulled icons
// -------------------------

.#{$fa-css-prefix}-border {
	padding: var(--#{$fa-css-prefix}-border-padding, #{$fa-border-padding});
	border-color: var(--#{$fa-css-prefix}-border-color, #{$fa-border-color});
	border-style: var(--#{$fa-css-prefix}-border-style, #{$fa-border-style});
	border-width: var(--#{$fa-css-prefix}-border-width, #{$fa-border-width});
	border-radius: var(--#{$fa-css-prefix}-border-radius, #{$fa-border-radius});
}

.#{$fa-css-prefix}-pull-left {
	margin-right: var(--#{$fa-css-prefix}-pull-margin, #{$fa-pull-margin});
	float: left;
}

.#{$fa-css-prefix}-pull-right {
	margin-left: var(--#{$fa-css-prefix}-pull-margin, #{$fa-pull-margin});
	float: right;
}
